import React, { Component } from 'react'
import Aux from './../Wrapper/Auxiliary'
import { conformToMask } from 'react-text-mask'
import { Link } from 'react-router-dom'
// import queryString from 'query-string'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'
import * as actionCreators from '../../../redux/actions'
import { APICONFIG } from '../../../redux/config/apiConfig'
import { noop } from 'lodash'
import PropTypes from 'prop-types'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
import moment from 'moment'
import { systemError } from '../Constant'
import { makeAfterPayment } from '../../../redux/actions/passesAction'
import LoadingComponent from '../../../common/UI/loadingComponent'
import eventCallRequest, { getLoggedUserData } from '../../../utils/HelperEvent'
import CarouselItems from './CarouselItems'
import { getConfigDetails } from '../../../services/config'
import { getPartnerDetails, loggedInUserData } from '../../../utils/helper'
import { errorMessage } from '../../../constants/errorMessage'

class confirmationComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: '',
            ticket: '',
            booking_date: '',
            endTime: '',
            phone: '',
            phonePre: '+1',
            email: '',
            pass_code: '',
            ticketech_code: '',
            isPass: false,
            paymentRes: {},
            valid_till: '',
            bookingId: '',
            passDetails: {},
            reservationDetails: {},
            data: {},
            errorMessage: '',
            systemError: false,
            cardDetails:{},
            facility_id:'',
        }
        this.notificationSystem = React.createRef()
        this.dateDisplayFormat = 'MM/DD/YYYY'
        this.dateFormat = 'YYYY-MM-DD 00:00:00'
        this.existingDateFormat = 'll'
        this.timeDisplayFormat = 'h:mm A'
        this.phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }

    carouselOptions = {
        nav: false,
        rewind: false,
        autoplay: false,
        margin: 30,
        loop: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            }
        }
    }



    componentDidMount = () => {
        const { dispatch } = this.props
        let queries = localStorage.getItem('queries') ? JSON.parse(localStorage.getItem('queries')) : ''
        const cardLastFour = localStorage.getItem('card_pan')
        const card_date_expiry_month = localStorage.getItem('card_date_expiry_month')
        const card_date_expiry_year = localStorage.getItem('card_date_expiry_year')
        const card_card_security_cvx_2 = localStorage.getItem('card_card_security_cvx_2')
        const cardDetails = {
            card: cardLastFour,
            expiry_month: card_date_expiry_month,
            expiry_year: card_date_expiry_year,
            cvv: card_card_security_cvx_2
        }

        this.setState({cardDetails : cardDetails})
        this.props.reqGetPartnerDetails(getConfigDetails()?.CLIENT_SECRET).then(() => {
        const { partnerDetails, } = this.props
        this.setState({facility_id: partnerDetails.facilities[0].id })
        })
        const userData = loggedInUserData()?.access_token ? JSON.parse(localStorage.getItem('userData'))?.user : JSON.parse(localStorage.getItem('userData'))
        let isDirectBooking = localStorage.getItem('directBooking') ? localStorage.getItem('directBooking') : false
        isDirectBooking = isDirectBooking == 'true' ? true : false
        const directBookingData = JSON.parse(localStorage.getItem('directBookingData'))
        if (!userData || userData === '') {
            window.location.replace(`${getPartnerDetails()?.partner_slug}/booking`)
        }

        const entry_date = localStorage.getItem('entry_date')
        const paymentDetails = JSON.parse(localStorage.getItem('paymentSession'))
        const passDetails = localStorage.getItem('passDetails') ? JSON.parse(localStorage.getItem('passDetails')) : ''
        const paaswithBooking = localStorage.getItem('isBooking')
        const requestPayload = {

            'length': isDirectBooking ? directBookingData?.length : passDetails?.length ? passDetails?.length : APICONFIG.defaultBookingLength,
            'facility_id': userData?.garage_id ? userData?.garage_id : this.state.facility_id ? this.state.facility_id : 1,
            'member_id': userData?.member_id,
            'pass_id': isDirectBooking ? directBookingData?.pass_id : passDetails?.is_book_parking ? passDetails?.pass_rate_id : '',
            'pass_rate_id': passDetails?.is_book_parking ? '' : passDetails?.pass_rate_id,
            'is_pass_purchase': isDirectBooking ? 1 : (passDetails !== '' ? (passDetails?.is_book_parking ? 0 : 1) : 0),
            'is_booking_also': parseInt(paaswithBooking),
            'is_book_parking': isDirectBooking ? 1 : parseInt(paaswithBooking) === 0 ? passDetails?.is_book_parking ? 1 : 0 : 1,
            'description': `Reservation for Parkengage - A beginning at ${entry_date ? moment(entry_date, this.dateDisplayFormat).format(this.dateFormat) : moment(new Date(), this.dateDisplayFormat).format(this.dateFormat)}`,
            'total': isDirectBooking ? directBookingData?.price : parseInt(paymentDetails?.amount).toFixed(2),
            'session': paymentDetails?.ipgSession,
            'email': userData?.email,
            'phone': userData?.phone_number,
            'user_id': getLoggedUserData()?.access_token ? getLoggedUserData()?.user?.id : '',
            'name': userData?.user_name ? userData?.user_name : getLoggedUserData()?.user?.name,
            'companyName': '',
            'is_opt_out': '',
            'use_bonus': 0,
            'coupon_threshold_price': 0,
            'warning_on_reservation': 0,
            'warning_on_reservation_msg_email': '',
            'is_card_req': 0,
            'arrival': entry_date ? moment(entry_date, this.dateDisplayFormat).format(this.dateFormat) : moment(new Date(), this.dateDisplayFormat).format(this.dateFormat),
            'MerchantRef': queries.MerchantRef ? queries.MerchantRef : paymentDetails.reference ? paymentDetails.reference : '',
            'TxID': queries.TxID,
            'AuthorisationCode': queries.AuthorisationCode,
            'CardType': queries.CardType,
            'CardNumberLast4': queries.CardNumberLast4 ? queries.CardNumberLast4 : cardDetails.card,
            'expiryMonth': cardDetails.expiry_month,
            'expiryYear': cardDetails.expiry_year,
            'cvv': cardDetails.cvv,
            'TokenNo': queries.TokenNo,
            'DSIndicator': queries.DSIndicator,
            'SCATransRef': queries.SCATransRef,
            'payment_status': 'success',

        }
        let logData = userData
        logData = { ...logData, directBookingData }
        logData = { ...logData, paymentDetails }
        logData = { ...logData, requestPayload }
        logData = { ...logData, cardDetails }

        let setUserLog = eventCallRequest('After Payment Success, Booking API Call', JSON.stringify(logData))
        this.props.eventRequest(setUserLog).then(() => { })
        dispatch(makeAfterPayment(requestPayload)).then((paymentRes) => {
            localStorage.removeItem('directBooking')
            this.setState({ paymentRes: paymentRes, data: paymentRes?.data, reservationDetails: paymentRes?.data?.reservation, passDetails: paymentRes?.data?.pass, bookingId: paymentRes?.data?.reservation?.anet_transaction_id })
            const reservationData = paymentRes?.data?.reservation
            if (!paymentRes?.errorMessage) {

                let logData = userData
                logData = { ...logData, directBookingData }
                logData = { ...logData, paymentDetails }
                logData = { ...logData, requestPayload }
                logData = { ...logData, paymentRes }
                logData = { ...logData, cardDetails }

                let setUserLog = eventCallRequest('After Payment Success, Booking API Call Success', JSON.stringify(logData))
                this.props.eventRequest(setUserLog).then(() => { })


                if (Object.values(reservationData).length > 0) {
                    let confirmationData = {
                        end_date: reservationData.formatted_start_date,
                        phone: reservationData.user.phone,
                        email: reservationData.user.email,
                        is_pass: 0,
                        ticketech_code: reservationData.ticketech_code,
                    }
                    if (confirmationData) {
                        let phoneNum = confirmationData.phone.slice(-10)
                        let phonePre = confirmationData.phone.substring(0, (confirmationData.phone.length - 10))
                        this.setState({
                            endDate: confirmationData.end_date,
                            ticketech_code: confirmationData.ticketech_code,
                            pass_code: confirmationData.pass_code,
                            phone: phoneNum,
                            phonePre: phonePre,
                            email: confirmationData.email,
                            isPass: (confirmationData.is_pass === 1) ? true : false
                        })
                        // localStorage.removeItem('userData')
                        // localStorage.clear()
                    } else {
                        // window.location.replace("/");
                    }
                } else {
                    const passData = paymentRes?.data?.pass

                    let confirmationData = {
                        end_date: passData.end_date,
                        phone: passData.user.phone,
                        email: passData.user.email,
                        is_pass: 1,
                        pass_code: passData.pass_code
                    }
                    if (confirmationData) {
                        let phoneNum = confirmationData.phone.slice(-10)
                        let phonePre = confirmationData.phone.substring(0, (confirmationData.phone.length - 10))
                        this.setState({
                            endDate: confirmationData.end_date,
                            ticketech_code: confirmationData.ticketech_code,
                            pass_code: confirmationData.pass_code,
                            phone: phoneNum,
                            phonePre: phonePre,
                            email: confirmationData.email,
                            isPass: (confirmationData.is_pass === 1) ? true : false
                        })
                        // localStorage.removeItem('userData')
                        // localStorage.clear()
                    } else {
                        // window.location.replace("/");
                    }

                }
            } else {
                let logData = userData
                logData = { ...logData, directBookingData }
                logData = { ...logData, paymentDetails }
                logData = { ...logData, requestPayload }
                logData = { ...logData, paymentRes }
                logData = { ...logData, cardDetails }

                let setUserLog = eventCallRequest('After Payment Success, Booking API Call Failed', JSON.stringify(logData))
                this.props.eventRequest(setUserLog).then(() => { })
                this.setState({ errorMessage: paymentRes?.errorMessage ? paymentRes?.errorMessage : errorMessage?.somethingWentWrong, systemError: true })

                // localStorage.clear()

            }
        }).catch((err) => {
            let logData = userData
            logData = { ...logData, directBookingData }
            logData = { ...logData, paymentDetails }
            logData = { ...logData, requestPayload }
            logData = { ...logData, err }
            logData = { ...logData, cardDetails }

            let setUserLog = eventCallRequest('After Payment Success, Booking API Call Failed', JSON.stringify(logData))
            this.props.eventRequest(setUserLog).then(() => { })
            this.setState({ errorMessage: systemError, systemError: true })
            // localStorage.clear()

        })



    }

    render() {
        const { isFetching } = this.props
        return (
            <Aux>
                <NotificationSystem ref={this.notificationSystem} />
                {isFetching && <LoadingComponent />}
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-6 col-12">
                            <div className="col my-4 text-center">
                                <Link to="/" className='text-decoration-none'>
                                    <h5 className="fw-bold text-primary">Parking at Main Street Parking</h5>
                                </Link>
                            </div>
                            <CarouselItems />
                            {/* <BottomLogo
                                className="my-2 text-center bg-opacity-10 d-none d-sm-block"
                            /> */}
                        </div>
                        {!isFetching && <div className="col-sm-12 col-md-6 col-12 bg-white p-0 border-start ">
                            <div className="ContentWrapper p-sm-5 p-2 mt-sm-5 mt-2 thankyou">
                                <div className="card shadow">
                                    <div className="card-header bg-primary ">
                                        <h2 className='card-title text-white fw-bolder text-center'>
                                            {this.state.data ? 'Thank You!!' : this.state.systemError ? 'System Error!!' : 'Warning!!'}

                                        </h2>
                                    </div>
                                    <div className='card-body text-center'>
                                        {(this.state.passDetails && ((Object.values(this.state.passDetails).length > 0 || this.state.passDetails.length > 0))) ?
                                            <div className="border py-2 mb-3">
                                                <div className='row'>
                                                    <div className='col-sm '>
                                                        <small className="mb-0"> Your pass is valid till</small>
                                                        <h4 className="fw-bold m-0">{moment(this.state.passDetails.end_date).format(this.dateDisplayFormat)}</h4>
                                                    </div>
                                                    <div className="col-auto border-start d-none d-sm-block">

                                                    </div>
                                                    <div className="col-12 my-2 border-top d-block d-sm-none">

                                                    </div>
                                                    <div className="col-sm">
                                                        <small className="mb-0">Pass Id# </small>
                                                        <h4 className="fw-bold m-0">{this.state.passDetails.pass_code}</h4>
                                                    </div>
                                                </div>

                                            </div>

                                            : null}
                                        {(this.state.reservationDetails && (Object.values(this.state.reservationDetails).length > 0 || this.state.reservationDetails.length > 0)) ?
                                            <div className="border py-2 mb-3">
                                                <div className='row'>
                                                    <div className='col-sm'>
                                                        <small className="mb-0">Your parking has been scheduled for</small>
                                                        <h4 className="fw-bold m-0">{moment(this.state.reservationDetails.formatted_start_date, this.existingDateFormat).format(this.dateDisplayFormat)}</h4>
                                                    </div>
                                                    <div className="col-auto border-start d-none d-sm-block">

                                                    </div>
                                                    <div className="col-12 my-2 border-top d-block d-sm-none">

                                                    </div>
                                                    <div className="col-sm">
                                                        <small className="mb-0">Booking Id# </small> <h4 className="fw-bold m-0">{this.state.reservationDetails.ticketech_code}</h4>

                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className='card-footer text-center'>
                                        {(this.state.passDetails) ?
                                            <div>
                                                <p className='mb-0'>Your pass details has been <br />sent to the confirmed mobile number and email</p>
                                                <p>
                                                    <span className="font-20 text-primary font-weight-bold">&nbsp;{this.state.phonePre}&nbsp;{conformToMask(this.state.passDetails.phone, this.phoneNumberMask).conformedValue}</span> , <span className="font-20 text-primary font-weight-bold">{this.state.passDetails.email}</span></p></div>

                                            : <div><p className='mb-0'> {this.state.data ? 'Thank you for parking with us. Your e-ticket details are sent to  mobile number and email' : this.state.errorMessage}
                                            </p>
                                                {this.state.reservationDetails ? <p>
                                                    <span className="font-20 text-primary font-weight-bold">&nbsp;{this.state.phonePre}&nbsp;{conformToMask(this.state.reservationDetails.user.phone_number, this.phoneNumberMask).conformedValue}</span> , <span className="font-20 text-primary font-weight-bold">{this.state.reservationDetails.user.email}</span></p> : ''}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/* <BottomLogo
                            className="my-2 text-center bg-opacity-10 d-block d-sm-none"
                        /> */}
                    </div>
                </div>
            </Aux>
        )
    }
}

confirmationComponent.propTypes = {
    dispatch: PropTypes.func,
    reqGetPartnerDetails : PropTypes.func,
    eventRequest : PropTypes.func,
    checkMemberId : PropTypes.func,
    checkMemberExistance : PropTypes.func,
    memberCheck : PropTypes.object,
    errorMessage: PropTypes.string,
    isFetching  : PropTypes.bool,
    partnerDetails : PropTypes.object,
    hasError  : PropTypes.bool,
    history  : PropTypes.object
}

confirmationComponent.defaultProps = {
    dispatch: noop,
    reqGetPartnerDetails  : noop,
    eventRequest : noop,
    checkMemberId : noop,
    checkMemberExistance : noop,
    memberCheck : EMPTY_OBJECT,
    errorMessage : '',
    isFetching  : false,
    partnerDetails  : EMPTY_OBJECT,
    hasError  : false,
    history  : EMPTY_OBJECT,
}


const mapStateToProps = ({ PassesReducer, PartnerReducer }) => {
    const { passes, isFetching, paymentRes, getGaragePriceRes, hasError, errorMessage, userPasses } = PassesReducer
    const { memberCheck, partnerDetails, eventRes } = PartnerReducer
    return {
        passes,
        userPasses,
        isFetching,
        paymentRes,
        getGaragePriceRes,
        hasError,
        errorMessage,
        memberCheck,
        eventRes,
        partnerDetails
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        reqGetPartnerDetails: (data) => dispatch(actionCreators.getPartnerDetails(data)),
        eventRequest: (data) => dispatch(actionCreators.logEvent(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(confirmationComponent)
