import CONSTANTS from '../constants'
import { postRequest, getRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

// Calls the API to get user token
export const userLogin = (creds) => (dispatch) => {
  dispatch({ type: CONSTANTS.LOGIN_REQUEST })
  return postRequest(APICONFIG.getUserLoginUrl, creds).then((res) => {
    dispatch({ type: CONSTANTS.LOGIN_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.LOGIN_FAILURE })
    return err
  })
}
export const userSingUp = (creds) => (dispatch) => {
  dispatch({ type: CONSTANTS.SINGUP_REQUEST })
  return postRequest(APICONFIG.singupUrl, creds).then((res) => {
    dispatch({ type: CONSTANTS.SINGUP_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.SINGUP_FAILURE })
    return err
  })
}


export const logoutRequest = () => {
  return (dispatch) => {
    dispatch({ type: CONSTANTS.LOGOUT_REQUEST })
  }
}
// Calls the API to get User-Profile Initials
export const getUserProfile = () => (dispatch) => {
  dispatch({ type: CONSTANTS.GET_USER_PROFILE_REQUEST })
  return getRequest(APICONFIG.getUserProfileUrl).then((res) => {
    dispatch({ type: CONSTANTS.GET_USER_PROFILE_SUCCESS, data: res.data })
    return res.data
  }).catch(() => {
    dispatch({ type: CONSTANTS.GET_USER_PROFILE_FAILURE })
  })
}

// Calls the API to get User-Profile Initials
export const getUserData = () => (dispatch) => {
  dispatch({ type: CONSTANTS.GET_USER_DATA_REQUEST })
  return getRequest(APICONFIG.getUserProfileData).then((res) => {
    dispatch({ type: CONSTANTS.GET_USER_DATA_SUCCESS, data: res.data })
    return res.data
  }).catch((err) => {
    dispatch({ type: CONSTANTS.GET_USER_DATA_FAILURE })
    return err
  })
}

// updated user-Profile
export const updateUserProfile = (data) => (dispatch) => {
  dispatch({ type: CONSTANTS.UPDATE_USER_PROFILE_REQUEST })
  return postRequest(APICONFIG.updateUserProfileUrl, data).then((res) => {
    dispatch({ type: CONSTANTS.UPDATE_USER_PROFILE_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.UPDATE_USER_PROFILE_FAILURE })
    return err
  })
}

export const sendEmailResetPassword = (payload) => (dispatch) => {
  dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_REQUEST })
  return postRequest(APICONFIG.sendEmailResetPassword, payload).then((res) => {
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_SUCCESS, data: res })
    return res
  }).catch((err) => {
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_FAILURE })
    return err
  })
}
