import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
// import { Table } from 'react-bootstrap'
import { restrictSpecialChar } from '../../utils/helper'
import { Col, Row } from 'react-bootstrap'

function VehicleModal(props) {
    const { isModalOpen, setModal, modalName, vehicleData, setVehicleData, handleSubmitVehicleData, submitButtonDisable } = props
    const [error, setError] = useState(false)

    // console.log('vehicleData', vehicleData, setVehicleData())
    const handleModalFieldsData = (e, i) => {
        const { name, value } = e.target
        vehicleData[i][name] = value
        setVehicleData([...vehicleData])
    }

    // const addVehicleMake = () => {
    //     const payloadData = [...vehicleData]
    //     payloadData?.push({ 'license_plate': '', 'make_model': '' })
    //     setVehicleData([...payloadData])
    // }

    const addVehicleSubmit = () => {
        const payloadData = [...vehicleData]
        // checking the every object item is empty or not 
        const testToSeeAllErrorsAreEmpty = payloadData?.every((item) => item?.license_plate !== '')
        //console.log('testToSeeAllErrorsAreEmpty?? payloadData',payloadData)
        let license_plate_valid= payloadData?.filter((item) => (item?.license_plate.length>= 3) ?item:null) 
        //console.log('testToSeeAllErrorsAreEmpty?? filter',license_plate_valid, testToSeeAllErrorsAreEmpty)
       // console.log('testToSeeAllErrorsAreEmpty??',payloadData,payloadData?.filter((item) => (item?.license_plate.length>= 3)), testToSeeAllErrorsAreEmpty)
        
        if (license_plate_valid?.length>0 && testToSeeAllErrorsAreEmpty === true) {
           
            handleSubmitVehicleData()
            setError('')
        } else if (testToSeeAllErrorsAreEmpty === false) {
            setError('Please Fill license plate!')
        }else if (license_plate_valid?.length<1) {
            setError('Please Fill license plate at least 3 digit!')
        }
    }

    const addVehicleMake = () => {
        const payloadData = [...vehicleData]
        // checking the every object item is empty or not 
        const testToSeeAllErrorsAreEmpty = payloadData?.every((item) => item?.license_plate !== '' && item?.make !== '' && item?.model !== '' && item?.color !== '')
        //console.log('testToSeeAllErrorsAreEmpty', testToSeeAllErrorsAreEmpty)
        if (payloadData?.length <= 2 && testToSeeAllErrorsAreEmpty === true) {
            payloadData?.push({ 'license_plate': '', 'make': '', 'model': '', 'color': '' })
            setVehicleData([...payloadData])
            setError('')
        } else if (testToSeeAllErrorsAreEmpty === false) {
            setError('Please Fill license plate, Before Add Another Vehicle !')
        }
    }



    const removeVehicleMake = (e, i) => {
        const payloadData = [...vehicleData]
        payloadData?.splice(i, 1)
        setVehicleData([...payloadData])
    }

    return (
        <>
            {/* <Button onClick={() => setSmShow(true)} className="me-2">
                Small modal
            </Button> */}
            {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}
            <Modal
                size="lg"
                show={isModalOpen}
                onHide={() => setModal(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        {modalName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {vehicleData?.map((vehicleListItem, vehicleListIndex) => {
                        return (
                            <Row className='align-items-end' key={vehicleListIndex}  xs={2} md={5} lg={5}>
                                <Col className='mt-1'>
                                <label className='form-label' >Licence Plate <span className="text-danger">*</span></label>
                               
                                    <input
                                        type="text"
                                        name='license_plate'
                                        required={true}
                                        className="form-control"
                                        placeholder="Licence Plate"
                                        maxLength={10}
                                        minLength={3}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.license_plate}
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        aria-describedby="emailHelp" />
                                        
                                </Col>
                                
                                <Col className='mt-1'> 
                                <label className='form-label' >Color </label>

                                    <input
                                        type="text"
                                        name='color'
                                        className="form-control"
                                        placeholder="Color"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.color}
                                        aria-describedby="emailHelp" />
                                </Col>
                                <Col className='mt-1'>
                                <label className='form-label' >Make </label>

                                    <input
                                        type="text"
                                        name='make'
                                        className="form-control"
                                        placeholder="Make"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.make}
                                        aria-describedby="emailHelp" />
                                </Col>
                                <Col className='mt-1'>
                                <label className='form-label' >Model</label>

                                    <input
                                        type="text"
                                        name='model'
                                        className="form-control"
                                        placeholder="Model"
                                        onChange={(e) => handleModalFieldsData(e, vehicleListIndex)}
                                        maxLength={15}
                                        minLength={4}
                                        onKeyDown={restrictSpecialChar}
                                        value={vehicleListItem?.model}
                                        aria-describedby="emailHelp" />
                                </Col>
                                <Col className='mt-1' xs={12}>
                                    {vehicleData?.length - 1 === vehicleListIndex ?
                                        <button type="button" className="btn btn-primary w-100" disabled={vehicleData?.length > 2} onClick={addVehicleMake}><i className="fa fa-plus"></i> </button> :
                                        vehicleListIndex !== -1 ? <button type="button" className="btn btn-danger w-100" onClick={(e) => removeVehicleMake(e, vehicleListIndex)}><i className="fa fa-minus"></i></button> :
                                            null
                                    }
                                </Col>
                            </Row>
                        )
                    })}
                    <p className='text-danger'>{error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" data-bs-dismiss="modal" className="btn btn-primary" disabled={submitButtonDisable} onClick={addVehicleSubmit} >Submit</Button>
                    <small className='small text-start'></small>

                </Modal.Footer>
            </Modal>
        </>
    )
}

VehicleModal.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    modalName: PropTypes.string,
    vehicleData: PropTypes.EMPTY_ARRAY,
    setVehicleData: PropTypes.func,
    handleSubmitVehicleData: PropTypes.func,
    isModalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    submitButtonDisable: PropTypes.bool
}

VehicleModal.defaulProps = {
    dispatch: noop,
    loader: PropTypes.bool,
    modalName: PropTypes.string,
    vehicleData: PropTypes.EMPTY_ARRAY,
    setVehicleData: PropTypes.func,
    handleSubmitVehicleData: PropTypes.func,
    isModalOpen: PropTypes.bool,
    setModal: PropTypes.func,
    submitButtonDisable: false
}

function mapStateToProps({ state }) {
    return { state }
}
export default connect(mapStateToProps)(VehicleModal)
