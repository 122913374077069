import React from 'react'
import RoutesComponent from '../../routes/Routes'

function App() {

	return (
		<div className="App" style={{ marginTop: '70px' }}>
			<RoutesComponent />
		</div>
	)
}

export default App
