import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import Login from '../../components/global/login'
import { matchPassword, validateEmail, validatePassword, validatePhone } from '../../utils/helper'
const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-9999')


function SignUp(props) {




    const { setPage, isLoggedIn, formData, setFormData, formError } = props
    // console.log('formData permit',props)
    const [isLoginModal, setLoginModal] = useState(false)
    const [zipcode, setZipcode] = useState()
    const [caller, setCaller] = useState()
    // show and hide passowrd 
    const [cpasswordShown, setCPasswordShown] = useState(false)
    const [mpasswordShown, setMPasswordShown] = useState(false)

    // for disbaled functionallty 
	const [loginData, setLoginData] = useState(EMPTY_OBJECT)
	

    const handleInputFieldsData = (e) => {
        const { name, value } = e.target
        if (name === 'zipcode') {
            let str = value
            let pattern = /\d/
            str.match(pattern) ? setZipcode('') : setZipcode('The zipcode contain at least a number.')
            setFormData({ ...formData, 'zipcode': value })
        }
        setFormData({ ...formData, [name]: value })
    }

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, ['phone']: value })
    }


    useEffect(() => {
        setPage('signup')

        if (isLoggedIn) {
            const signupFormData = JSON.parse(localStorage.getItem('userData'))
            setLoginData(signupFormData?.user)
            if (Object.values(signupFormData?.user)?.length > 0) {
                const loggedInUserData = signupFormData?.user
                formData['firstname'] = loggedInUserData?.first_name ? loggedInUserData?.first_name : formData?.first_name
                formData['lastname'] = loggedInUserData?.last_name ? loggedInUserData?.last_name : formData?.last_name
                formData['email'] = loggedInUserData?.email ? loggedInUserData?.email : formData?.email
                formData['phone'] = loggedInUserData?.phone_number ? loggedInUserData?.phone_number : formData?.phone
                formData['address1'] = loggedInUserData?.address ? loggedInUserData?.address : formData?.address1
                formData['address2'] = loggedInUserData?.address2 ? loggedInUserData?.address2 : formData?.address2
                formData['city'] = loggedInUserData?.city ? loggedInUserData?.city : formData?.city
                formData['state'] = loggedInUserData?.state ? loggedInUserData?.state : formData?.state
                formData['zipcode'] = loggedInUserData?.pincode ? loggedInUserData?.pincode : formData?.pincode
                setFormData({ ...formData })
            }
        } else {
            setFormData({ ...formData })
        }

    }, [EMPTY_ARRAY, isLoggedIn])

    const handleModalOpen = () => {
        setLoginModal(true)
        setCaller('signup')
    }

    // show and hide password 
    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') {
            setMPasswordShown(mpasswordShown ? false : true)
        }
        if (key === 'cpassword') {
            setCPasswordShown(cpasswordShown ? false : true)
        }
    }


    return (


        <div >
            <h4 className="text-center my-4 mb-4">Account Information</h4>
            {!isLoggedIn && <div className="bg-white card p-3 mb-4">
                <p className="m-0">
                    <button type='button' className="btn btn-outline-primary" onClick={handleModalOpen} data-bs-target="#loginModal">LOG
                        IN</button> <strong> OR CREATE ACCOUNT BELOW</strong><br /><small
                            className="text-danger">*You must log in or create a new account to
                        proceed.</small>
                </p>
            </div>}

            <div className="mt-2">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">First Name <span className='text-danger'> *</span></label>
                            <input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                id="firstname" name="firstname" placeholder="First Name"
                                maxLength={20}
                                minLength={1}
                                disabled={isLoggedIn ? true : false}
                                value={formData?.firstname ? formData?.firstname : ''}
                                onChange={handleInputFieldsData}
                                required="" type="text" />
                            <span className="form-text text-danger h-40">{formError == 'signup' && !formData?.firstname ? 'This field is required' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            {/* <span className='text-danger'> *</span> */}
                            <label className="form-label">Last Name</label>
                            <input
                                maxLength={20}
                                disabled={(!loginData?.last_name) ? false : true}
                                minLength={1}
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="lastname" value={formData?.lastname ? formData?.lastname : ''} placeholder="Last Name" required="" type="text" onChange={handleInputFieldsData} />
                            {/* <span className="form-text text-danger h-40">{formError == 'signup' && !formData?.lastname ? 'This field is required' : ''}</span> */}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Email <span className='text-danger'> *</span></label><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                maxLength={40}
                                disabled={isLoggedIn ? true : false}
                                minLength={1}
                                name="email" placeholder="Email" required="" type="email"
                                value={formData?.email ? formData?.email : ''}
                                onChange={handleInputFieldsData}
                                pattern="^[^@]+@[^@]+.[a-zA-Z]{2,6}" />
                            <span className="form-text text-danger h-40">{formError == 'signup' ? !formData?.email ? 'This field is required' : !validateEmail(formData?.email) ? 'Please enter a valid email address' : '' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Phone <span className='text-danger'> *</span></label>
                            <MaskedInput
                                maskGenerator={phoneMaskGenerator}
                                value={formData?.phone ? formData?.phone : ''}
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                required
                                // pattern="[0-9 \\-\(\)\]{14}"
                                maxLength={14}
                                minLength={14}
                                title="Please enter a valid phone number"
                                onInvalid={e => e.target.setCustomValidity('Please enter phone number')}
                                onInput={e => e.target.setCustomValidity('')}
                                placeholder="Phone Number"
                                onChange={handlePhoneChange}
                            />
                            <span className="form-text text-danger h-40">{formError == 'signup' ? !formData?.phone ? 'This field is required' : !validatePhone(formData?.phone) ? 'Please enter a valid phone number' : '' : ''}</span>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Address Line 1 <span className='text-danger'> *</span></label><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="address1" placeholder="Address Line 1" required=""
                                value={formData?.address1 ? formData?.address1 : ''}
                                onChange={handleInputFieldsData}
                                type="text" />
                            <span className="form-text text-danger h-40">{formError == 'signup' && !formData?.address1 ? 'This field is required' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">Address Line 2 </label><input
                                className="form-control ng-untouched ng-pristine ng-valid"
                                name="address2" placeholder="Address Line 2 (optional)"
                                value={formData?.address2 ? formData?.address2 : ''}
                                onChange={handleInputFieldsData}
                                type="text" /></div>
                    </div>
                    <div className="col-sm-6">
                        <div className="mb-3">
                            <label className="form-label">City <span className='text-danger'> *</span></label><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="city" value={formData?.city ? formData?.city : ''} placeholder="City" required="" type="text" onChange={handleInputFieldsData} />
                            <span className="form-text text-danger h-40">{formError == 'signup' && !formData?.city ? 'This field is required' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-6">
                        <div className="mb-3">
                            <label className="form-label">State <span className='text-danger'> *</span></label><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="state" placeholder="State" required=""
                                value={formData?.state ? formData?.state : ''}
                                onChange={handleInputFieldsData}
                                type="text" />
                            <span className="form-text text-danger h-40">{formError == 'signup' && !formData?.state ? 'This field is required' : ''}</span>
                        </div>
                    </div>
                    <div className="col-sm-3 col-xs-6">
                        <div className="mb-3">
                            <label className="form-label">Zipcode <span className='text-danger'> *</span></label><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                maxLength="6" minLength="5" name="zipcode"
                                autoComplete="new-password"
                                value={formData?.zipcode ? formData?.zipcode : ''}
                                placeholder="Zip Code" required="" type="text" onChange={handleInputFieldsData} />
                            <span className="form-text text-danger h-40">{formError == 'signup' && !formData?.zipcode ? 'This field is required' : ''}{zipcode}</span>
                        </div>
                    </div>
                </div>


                {!isLoggedIn && <div className=" mt-2 ">
                    <p className="fw-bold mt-3 mb-2"  >
                        <span >Create Account Password <i className='text-danger' > * </i>
                            <span className="popoverSection position-relative"  >
                                <div className='popoverContent position-absolute shadow-sm bg-white p-2 border'>Password must contain atleast 8 characters, including 1 uppercase, 1 lowercase, 1 number and 1 special character.</div>
                                <i
                                    className="fa fa-question-circle "
                                >
                                </i>
                            </span>

                        </span>
                    </p>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="mb-3"><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                name="password" placeholder="Create Password" required=""
                                value={formData?.password}
                                autoComplete="new-password"
                                onChange={handleInputFieldsData}
                                reverse="true" type={(!mpasswordShown) ? 'password' : 'text'} validateequal="cpassword" />
                                <i className={(!mpasswordShown) ? 'fas fa-eye-slash miconClass' : 'fas fa-eye miconClass'} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                                <span className="form-text text-danger h-40">{formError == 'signup' ? !formData?.password ? 'This field is required' : !validatePassword(formData?.password) ? 'Please enter a valid password ' : !matchPassword(formData?.password, formData?.cpassword) ? 'Passwords does not match' : '' : ''}</span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mb-3"><input
                                className="form-control ng-untouched ng-pristine ng-invalid"
                                value={formData?.cpassword}
                                autoComplete="new-password"
                                name="cpassword" placeholder="Confirm Password" required=""
                                onChange={handleInputFieldsData}
                                type={(!cpasswordShown) ? 'password' : 'text'} validateequal="password" />
                                <i className={(!cpasswordShown) ? 'fas fa-eye-slash ciconClass' : 'fas fa-eye ciconClass'} onClick={(e) => togglePasswordVisiblity(e, 'cpassword')}></i>
                                <span className="form-text text-danger h-40">{formError == 'signup' ? !formData?.cpassword ? 'This field is required' : !validatePassword(formData?.cpassword) ? 'Please enter a valid password ' : !matchPassword(formData?.password, formData?.cpassword) ? 'Passwords does not match' : '' : ''}</span>
                            </div>
                        </div>
                    </div>

                </div>
                }
            </div>
            <Login isLoginModal={isLoginModal} setLoginModal={setLoginModal} caller={caller} />
        </div>
    )
}

SignUp.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    events: PropTypes.object,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    formError: PropTypes.string,
}

SignUp.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    events: EMPTY_OBJECT,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: noop,
    isLoggedIn: false,
    formData: EMPTY_OBJECT,
    setFormData: noop,
    formError: undefined,
}


function mapStateToProps({ paymentReducer }) {
    return {
        data: paymentReducer,
    }

}
export default connect(mapStateToProps)(SignUp)
