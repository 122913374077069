const URL = {
	INDEX: '',
	HOME: '/:partner',
	ABOUT: '/:partner/about',
	CONTACT: '/:partner/contact',
	PAYMENT: '/:partner/payment',
	PAYMENT_SUCCESS: '/:partner/paymentsuccess',
	PAYMENT_FAIL: '/:partner/paymentfails',
	RECOVER_TICKET: '/:partner/recoverTicket',
	THANKYOU: '/:partner/download',
	SIGNUP: '/:partner/signup',
	FORFOT_PASSWORD: '/:partner/reset-password/:token_id',
	SEND_EMAIL_FORGOT_PASSWORD: '/:partner/send-email',
	PASS: '/:partner/pass',
	PASSCREATE: '/:partner/pass-create',
	USER: '/:partner/user',
	USER_PROFILE: '/:partner/user-profile',
	PASS_PAYMENT_SUCCESS: '/:partner/userpass-after-payment',
	PASS_PAYMENT_FAIL: '/:partner/userpass-payment-failed',
	NAVIGATING: '/navigating/:acc/:ref',
	NAVIGATING_RECEIPT: '/navigating/:acc/:ref/:isreceipt',
	NAVIGATING_PARTNER: '/navigating/:acc/:ref/:isreceipt/:secret',
	BOOKTICKET: '/:partner/booking',
	CHECKOUT_PASSES: '/:partner/booking/checkout-passes',
	AAA_PAYMENT_RESPONSE: '/:partner/booking/paymentResponse',
	AAA_PAYMENT_FAILED: '/:partner/booking/paymentFailed',
	AAA_PAYMENT_SUCCESS: '/:partner/booking/payment-success',
	AAA_FAILED_COMPONENT: '/:partner/booking/payment-failure',
	AAA_BOOKING_RESPONSE: '/:partner/booking/booking-response',
	TICKET: '/:partner/ticket/:ticketId',
	TICKET_VIEW: '/:partner/ticket',
	RESPONSIVE_RESERVATION_FACILITY: '/book-reservation',
	RESPONSIVE_RESERVATION: '/:partner/book-reservation',
	
	BASE_HOME: '',
	BASE_ABOUT: '/about',
	BASE_CONTACT: '/contact',
	BASE_PAYMENT: '/payment',
	BASE_PAYMENT_SUCCESS: '/paymentsuccess',
	BASE_PAYMENT_FAIL: '/paymentfails',
	BASE_RECOVER_TICKET: '/recoverTicket',
	BASE_THANKYOU: '/download',
	BASE_SIGNUP: '/signup',
	BASE_FORFOT_PASSWORD: '/reset-password/:token_id',
	BASE_SEND_EMAIL_FORGOT_PASSWORD: '/send-email',
	BASE_PASS: '/pass',
	BASE_PASSCREATE: '/pass-create',
	BASE_USER: '/user',
	BASE_USER_PROFILE: '/user-profile',
	BASE_PASS_PAYMENT_SUCCESS: '/userpass-after-payment',
	BASE_PASS_PAYMENT_FAIL: '/userpass-payment-failed',
	BASE_NAVIGATING: '/navigating/:acc/:ref',
	BASE_NAVIGATING_RECEIPT: '/navigating/:acc/:ref/:isreceipt',
	BASE_NAVIGATING_PARTNER: '/navigating/:acc/:ref/:isreceipt/:secret',
	BASE_BOOKTICKET: '/booking',
	BASE_CHECKOUT_PASSES: '/booking/checkout-passes',
	BASE_AAA_PAYMENT_RESPONSE: '/booking/paymentResponse',
	BASE_AAA_PAYMENT_FAILED: '/booking/paymentFailed',
	BASE_AAA_PAYMENT_SUCCESS: '/booking/payment-success',
	BASE_AAA_FAILED_COMPONENT: '/booking/payment-failure',
	BASE_AAA_BOOKING_RESPONSE: '/booking/booking-response',
	BASE_TICKET: '/ticket/:ticketId',
	BASE_TICKET_VIEW: '/ticket',
	//BASE_RESPONSIVE_RESERVATION: '/book-reservation',
	BASE_RESPONSIVE_RESERVATION: '/:partner',

	EXTERNAL: 'https://www.npmjs.com/package/qrcode.react',
	PARKENGAGE_LOGO_URL: 'https://parkengage.com/static/media/logo.8ed76367.svg',
	RESERVATION: 'https://demo-reservation.parkengage.com/',
}

export default URL
