import React from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import Ticket from '../pages/user/eTicketRecipt/Ticket'
import { downloadTicket } from '../redux/actions/eTicketRecipt'

function TicketModal(props) {
    const { setTicketModal, ticketModal, selItem, dispatch, loaderDownload, notify } = props
    const handleDownloadTicket = () => {
        if (!loaderDownload) {
            dispatch(downloadTicket(selItem?.ticket_number, selItem?.partner_id)).then((res) => {
                if (res?.status && res?.status == 500) {
                    notify('error', 'Something went wrong, Please try again!')
                }
                notify('Success', 'E-Ticket Downloaded')
            }).catch(() => {
                notify('error', 'Something went wrong, Please try again!')
            })
        }
    }
    return (
        <>
            <Modal show={ticketModal} onHide={() => setTicketModal()} centered size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title className='w-100 modal-title h5 fw-bold'>Ticket Details <i className='fa fa-download me-3 float-end mt-1' onClick={() => handleDownloadTicket()}></i></Modal.Title>
                </Modal.Header>
                <Modal.Body><Ticket ticketId={selItem?.ticket_number} ticketDetailsFromProps={selItem} /></Modal.Body>
            </Modal>
        </>
    )
}

TicketModal.propTypes = {
    dispatch: PropTypes.func,
    setTicketModal: PropTypes.func,
    ticketModal: PropTypes.bool,
    selItem: PropTypes.object,
    loaderDownload: PropTypes.bool,
    notify: PropTypes.func,
}

TicketModal.defaulProps = {
    dispatch: noop,
    setTicketModal: noop,
    ticketModal: false,
    selItem: {},
    loaderDownload: false,
    notify: noop,
}

function mapStateToProps() {
    return {}
}
export default connect(mapStateToProps)(TicketModal)