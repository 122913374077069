import React, { Component } from 'react'
import Aux from './../Wrapper/Auxiliary'
import moment from '../../../components/global/Time/Moment'
import CarouselItems from './CarouselItems'
import { Link } from 'react-router-dom'
import { BottomLogo } from '../Constant'
// import { makeAfterPayment } from '../../../redux/actions'
import { connect } from 'react-redux'
// import { APICONFIG } from '../../../redux/apiConfig'
import LoadingComponent from '../../../common/UI/loadingComponent'
import NotificationSystem from 'react-notification-system'
// import eventCallRequest from '../../../../src/utils/HelperEvent'
import * as actionCreators from '../../../redux/actions'
import { conformToMask } from 'react-text-mask'
import PropTypes from 'prop-types'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
import { noop } from 'lodash'
import { getLoggedUserData } from '../../../utils/HelperEvent'
import { phoneMasker } from '../../../utils/helper'


class DirectBooking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: '',
            reservationDetails:{},
            errorMessage:'',
            // systemError: false,
            phone: '',
            phonePre: '+1',
            email: '',
        }
        this.notificationSystem = React.createRef()
        this.dateDisplayFormat = 'MM/DD/YYYY'
        this.dateFormat = 'YYYY-MM-DD 00:00:00'
        this.existingDateFormat = 'll'
        this.timeDisplayFormat = 'h:mm A'
        this.phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    }

    carouselOptions = {
        nav: false,
        rewind: false,
        autoplay: false,
        margin: 30,
        loop: false,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            }
        }
    }

    

    componentDidMount = () => {

        const userData = JSON.parse(localStorage.getItem('userData'))
        const reservationData = JSON.parse(localStorage.getItem('reservationDetails'))

        // const directBookingData = JSON.parse(localStorage.getItem('directBookingData'))
        if((!userData || userData === '') || (!reservationData || reservationData === '')){
           window.open('/')
        }
        
        this.setState({reservationDetails: reservationData, userData: userData})
        
        // let logData = userData
        // logData = {...logData, directBookingData}
        // logData = {...logData, reservationData}
        // logData = {...logData, reservationData}

        // let setUserLog = eventCallRequest('Existing Pass Payment API Success and Page Loaded', JSON.stringify(logData))
        // this.props.eventRequest(setUserLog).then(() => { })        
        
    }

    render() {
    // const {isFetching} = this.props
    
        return (
            <Aux>
                <NotificationSystem ref={this.notificationSystem} />
                {!this.state.reservationDetails && <LoadingComponent/>}
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col-sm-12 col-md-6 col-12">
                            <div className="col my-4 text-center">
                                <Link to="/" className='text-decoration-none'>
                                    <h5 className="fw-bold text-primary">Parking at Main Street Parking</h5>
                                </Link>
                            </div>
                            <CarouselItems />
                            <BottomLogo
                                className="my-2 text-center bg-opacity-10 d-none d-sm-block"
                            />
                        </div>
                        {Object.values(this.state.reservationDetails).length > 0 && <div className="col-sm-12 col-md-6 col-12 bg-white p-0 border-start ">
                            <div className="ContentWrapper p-sm-5 p-2 mt-sm-5 mt-2 thankyou">
                                <div className="card shadow">
                                    <div className="card-header bg-primary ">
                                        <h2 className='card-title text-white fw-bolder text-center'>
                                            {Object.values(this.state.reservationDetails).length > 0 ? 'Thank You!!' : 'System Error!!'}

                                        </h2>
                                    </div>
                                    <div className='card-body text-center'>
                                        {(this.state.reservationDetails.pass && ((Object.values(this.state.reservationDetails.pass).length > 0 || this.state.reservationDetails.pass.length > 0))) ?
                                            <div className="border py-2 mb-3">
                                                <div className='row'>
                                                    <div className='col-sm '>
                                                        <small className="mb-0"> Your pass is valid till</small>
                                                        <h4 className="fw-bold m-0">{moment(this.state.reservationDetails.pass.end_date).format(this.dateDisplayFormat)}</h4>
                                                    </div>
                                                    <div className="col-auto border-start d-none d-sm-block">

                                                    </div>
                                                    <div className="col-12 my-2 border-top d-block d-sm-none">

                                                    </div>
                                                    <div className="col-sm">
                                                        <small className="mb-0">Pass Id# </small>
                                                        <h4 className="fw-bold m-0">{this.state.reservationDetails.pass.pass_code}</h4>
                                                    </div>
                                                </div>

                                            </div>

                                            : null}
                                        {(this.state.reservationDetails && (Object.values(this.state.reservationDetails.reservation).length > 0 || this.state.reservationDetails.reservation.length > 0)) ?
                                            <div className="border py-2 mb-3">
                                                <div className='row'>
                                                    <div className='col-sm'>
                                                        <small className="mb-0">Your parking has been scheduled for</small>
                                                        <h4 className="fw-bold m-0">{moment(this.state.reservationDetails.reservation.formatted_start_date, this.existingDateFormat).format(this.dateDisplayFormat)}</h4>
                                                    </div>
                                                    <div className="col-auto border-start d-none d-sm-block">

                                                    </div>
                                                    <div className="col-12 my-2 border-top d-block d-sm-none">

                                                    </div>
                                                    <div className="col-sm">
                                                        <small className="mb-0">Booking Id# </small> <h4 className="fw-bold m-0">{this.state.reservationDetails.reservation.ticketech_code}</h4>

                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className='card-footer text-center'>
                                        {(this.state.reservationDetails.pass) ?
                                            <div>
                                                <p className='mb-0'>Your pass details has been <br />sent to the confirmed mobile number and email</p>
                                                <p>
                                                    <span className="font-20 text-primary font-weight-bold">&nbsp;{this.state.phonePre}&nbsp;{getLoggedUserData()?.access_token ? phoneMasker(getLoggedUserData()?.user?.phone) : conformToMask(this.state.userData.phone, this.phoneNumberMask).conformedValue}</span> , <span className="font-20 text-primary font-weight-bold">{this.state.userData.email}</span></p></div>

                                            : <div><p className='mb-0'> {this.state.reservationDetails ? 'Thank you for parking with us. Your e-ticket details are sent to  mobile number and email' : this.state.errorMessage}
                                            </p>
                                               {this.state.reservationDetails ? <p>
                                                    <span className="font-20 text-primary font-weight-bold">&nbsp;{this.state.phonePre}&nbsp;{getLoggedUserData()?.access_token ? phoneMasker(getLoggedUserData()?.user?.phone) : conformToMask(this.state.userData.phone, this.phoneNumberMask).conformedValue}</span> , <span className="font-20 text-primary font-weight-bold">{this.state.userData.email}</span></p> : '' }</div>}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <BottomLogo
                            className="my-2 text-center bg-opacity-10 d-block d-sm-none"
                        />
                    </div>
                </div>
            </Aux>
        )
    }
}


DirectBooking.PropTypes = {
    history  : PropTypes.object,
    eventRequest : PropTypes.func,
    isFetching : PropTypes.bool
}

DirectBooking.defaultProps = {
    history  : EMPTY_OBJECT,
    eventRequest : noop,
    isFetching : false,

}





const mapStateToProps = ({ PassesReducer, PartnerReducer }) => {
    const { passes, isFetching, paymentRes, getGaragePriceRes, hasError, errorMessage, userPasses } = PassesReducer
    const { memberCheck, partnerDetails, eventRes } = PartnerReducer
    return {
        passes,
        userPasses,
        isFetching,
        paymentRes,
        getGaragePriceRes,
        hasError,
        errorMessage,
        memberCheck,
        eventRes,
        partnerDetails
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        eventRequest: (data) => dispatch(actionCreators.logEvent(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectBooking)
