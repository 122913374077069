
import React, { useEffect, useState } from 'react'
import noop from 'lodash/noop'
import Login from '../../../components/global/login'
import BookingHistory from '../bookingHistory/BookingHistory'
// import LinkAndUnlink from '../linkAndUnlink/LinkAndUnlink'
import { TabContent, TabPane } from 'reactstrap'
import PaymentSetting from '../paymentSetting/PaymentSetting'
import VehicleAndPermit from '../vehicleAndPermit/VehicleAndPermit'
import VehicleDetails from '../vehicleDetails'
import { useNavigate } from 'react-router-dom'
import URL from '../../../constants/urls'
import { EMPTY_ARRAY, EMPTY_OBJECT, worldportTabsNameList } from '../../../constants/globalConstants'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SearchTicket from '../eTicketRecipt/searchTicket'

function VehicleDemo(props) {
  const { isAuthenticated, userData, permitData, notify, loader } = props

  // FOR THE VEHICLE COUNT IN TH EFRONT UI 

  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userDetails, setuserDetails] = useState(false)
  const [tabItem, setTabItem] = useState(EMPTY_ARRAY)
  const partner = window.location.toString().split('/')[3]
  const [vehicleCount, setVehicleCount] = useState(false)


  // FOR TABS CHANGE 

  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    const isReceipt = localStorage.getItem('isreceipt')
    setTabItem(worldportTabsNameList)

    if (isReceipt && isReceipt != '') {
      setActiveTab(0)
    }
  }, [EMPTY_ARRAY])

  useEffect(() => {


    const permitDetails = permitData?.data
    const userDetailsPermit = permitDetails?.userDetails
    if (isAuthenticated) {
      setIsLoggedIn(true)
      const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
      const userDetails = getuserDatafromLocal?.user
      if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
        setuserDetails(userDetails)
      } else {
        const userDetails = userData?.data?.user
        setuserDetails(userDetails)
      }
    } else if (userDetailsPermit?.access_token && userDetailsPermit?.access_token != '') {
      setIsLoggedIn(true)
      setuserDetails(userDetailsPermit?.user)
    } else {
      const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
      const userDetails = getuserDatafromLocal?.user
      if (getuserDatafromLocal?.access_token && getuserDatafromLocal?.access_token != '') {
        setuserDetails(userDetails)
        setIsLoggedIn(true)
      } else {
        setIsLoggedIn(false)

        navigate(`/${partner}${URL?.BASE_HOME}`)

      }
    }
  }, [EMPTY_ARRAY, isAuthenticated, permitData])


  // FOR THE TABS CHANGE 

  const handleLinkClick = (e, value) => {
    localStorage.removeItem('isreceipt')
    setActiveTab(value)
  }

 
  return (
    <div>
      {/* main section ---  */}
      <main>
        <section className="formDesign p-sm-5">
          {/* for the maain header--- */}
          <VehicleAndPermit partner={partner} vehicleCount={vehicleCount} />
          <div className="container">
            <div className="row">
              <div className="col-sm-3 mb-3 col-12"  role="tablist" aria-orientation="vertical">
                <div className="list-group">
                  {tabItem?.map((tabNameItem, tabNameIndex) => {
                    return (
                      <button key={tabNameIndex} className={`list-group-item list-group-item-action ${activeTab === tabNameIndex ? 'active' : ''}`} type="button" onClick={(e) => handleLinkClick(e, tabNameIndex)}><i className={`fa fa-${tabNameItem?.icon} me-3`}></i>{tabNameItem?.tabName}</button>
                      //  <NavLink key={tabNameIndex} className={activeTab === tabNameIndex ? 'active' : null} onClick={(e) => handleLinkClick(e, tabNameIndex)}><i className={`fa fa-${tabNameItem?.icon} me-3`}></i>{tabNameItem?.tabName}</NavLink> 
                    )
                  })}
                </div>
              </div>

              <div className="col-12 mb-3 col-sm-9">
                <div className="p-4 card">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={0}>
                      {activeTab === 0 ?
                        <VehicleDetails setVehicleCount={setVehicleCount} vehicleCount={vehicleCount} />
                        : null
                      }
                    </TabPane>
                    <TabPane tabId={1}>
                      {activeTab === 1 ?
                        <PaymentSetting />
                        : null
                      }
                    </TabPane>
                    <TabPane tabId={2}>
                      {activeTab === 2 ?
                        <BookingHistory />
                        : null
                      }
                    </TabPane>
                    <TabPane tabId={3}>
                      {activeTab === 3 ?
                        <SearchTicket notify={notify} />
                        : null
                      }
                    </TabPane>
                    
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* login */}
      < Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} userDetails={userDetails} />
      {loader && <div
        className='top-0 justify-content-center position-fixed w-100 h-100 zIndexSuperHigh bg-black d-flex opacity text-center opacity-25 text-white' >
        <div className='d-flex align-items-center justify-content-center h-100'><i className='fa fa-spinner fa-spin'></i></div>
      </div>}
    </div>
  )
}

VehicleDemo.propTypes = {
  isAuthenticated: PropTypes.bool,
  userData: PropTypes.object,
  permitData: PropTypes.object,
  notify: PropTypes.func,
  loader: PropTypes.bool,
}

VehicleDemo.defaulProps = {
  isAuthenticated: false,
  userData: EMPTY_OBJECT,
  permitData: EMPTY_OBJECT,
  notify: noop,
  loader: false
}

function mapStateToProps({ login, paymentReducer, userDetailsReducer }) {
  return {
    isAuthenticated: login?.isAuthenticated,
    userData: login?.data,
    permitData: paymentReducer?.data,
    loader: userDetailsReducer?.loaderDownload
  }
}
export default connect(mapStateToProps)(VehicleDemo)