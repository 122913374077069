import { getConfigDetails } from '../../services/config'

export const APICONFIG = {
	// Diamond parking api start 
	getUserLoginUrl: `${getConfigDetails().baseURL}oauth/access_token`,
	getFacilityListUrl: `${getConfigDetails().baseURL}facility_llc`,
	getFacilityList: `${getConfigDetails().baseURL}diamond-permit-partner-facility`,
	getPermitRateListUrl: `${getConfigDetails().baseURL}diamond-partner-permit-rates`,

	// Permit PARTNER PERMIT RATES
	getArePermitRateListUrl: `${getConfigDetails().baseURL}are-partner-permit-rates`,
	getProRateListUrl: `${getConfigDetails().baseURL}diamond-partner-pro-rate`,

	// Permit PRO RATES
	getAreProRateListUrl: `${getConfigDetails().baseURL}are-partner-pro-rate`,
	getPermitDataUrl: `${getConfigDetails().baseURL}get-user-all-permits`,

	// Card details api start 
	// getCardDataUrl: `${getConfigDetails().baseURL}list-payment-methods`,
	// createCardUrl: `${getConfigDetails().baseURL}membership-payment-method`,
	// removeCardUrl: `${getConfigDetails().baseURL}delete-membership-payment-method`,

	getCardDataUrl: `${getConfigDetails().baseURL}get-planet-profile`,
	createCardUrl: `${getConfigDetails().baseURL}add-planet-profile`,
	removeCardUrl: `${getConfigDetails().baseURL}delete-planet-profile`,

	// booking history

	getBookingHistroyDataUrl: `${getConfigDetails().baseURL}get-user-booking`,

	getVehicleListUrl: `${getConfigDetails().baseURL}get-vehicle-list`,
	postVehicleListUrl: `${getConfigDetails().baseURL}add-vehicle`,
	deleteVehicleItemUrl: `${getConfigDetails().baseURL}delete-vehicle`,
	getWaitingListUrl: `${getConfigDetails().baseURL}wait-list-user-update`,
	getBookingUrl: `${getConfigDetails().baseURL}book-permit`,

	//BOOKPERMIT FOR Permit-Staging
	getAreBookingUrl: `${getConfigDetails().baseURL}book-permit-are`,

	// UPDATED USER PROFILE
	updateUserProfileUrl: `${getConfigDetails().baseURL}diamond-update-profile`,
	// Get user profile
	getUserProfileUrl: `${getConfigDetails().baseURL}diamond-get-user-by-id`,

	//PASSLIST API
	getPassListUrl: `${getConfigDetails().baseURL}get-partner-user-passes`,

	// Reset Password
	postRestPassword: `${getConfigDetails().baseURL}reset-are-password`,

	getFacilityPass: `${getConfigDetails().baseURL}get-user-facility-pass`,

	getArePassUrl: `${getConfigDetails().baseURL}book-pass-are`,

	getPaymentInitialUrl: `${getConfigDetails().baseURL}are-planet-payment-session`,
	saveBookingBeforePaymentUrl: `${getConfigDetails().baseURL}are-booking-before-payment`,
	permitTokenPaymentUrl: `${getConfigDetails().baseURL}are-payment`,
	saveBookingafterPaymentUrl: `${getConfigDetails().baseURL}are-booking-after-payment`,
	//pass
	getPassPaymentInitialUrl: `${getConfigDetails().baseURL}userpass-planet-payment-session`,
	savePassBookingBeforePaymentUrl: `${getConfigDetails().baseURL}userpass-before-payment`,
	userPassTokenPaymentUrl: `${getConfigDetails().baseURL}userpass-payment`,
	savePassBookingafterPaymentUrl: `${getConfigDetails().baseURL}userpass-after-payment`,

	// Get user profile
	getUserProfileData: `${getConfigDetails().baseURL}api`,



	// AAA API's

	//timeZone: 'America/Los_Angeles',
	///timeZone: 'America/New_York',
	defaultBookingLength: 24,
	clientSecret: getConfigDetails().CLIENT_SECRET,
	encryptKey: 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=',
	getPassesReqUrl: `${getConfigDetails().baseURL}rate-pass`,
	getUserPassesReqUrl: `${getConfigDetails().baseURL}user-passes`,
	getPartnerDetailsReqUrl: `${getConfigDetails().baseURL}partners-key-by-secret`,
	paymentReqUrl: `${getConfigDetails().baseURL}reservation`,
	verifyMemberReqUrl: `${getConfigDetails().baseURL}verify-member-user`,
	getGaragePriceUrl: `${getConfigDetails().baseURL}rate/facility-on-marker`,
	eventLogUrl: `${getConfigDetails().baseURL}addUserEvent`,
	getPaymentSessionUrl: `${getConfigDetails().baseURL}atlanta-planet-payment-session`,
	getReservationUrl: `${getConfigDetails().baseURL}atlanta-reservation`,
	// getBeforePayment: `${config.baseURL}/atlanta-booking-before-payment`,
	getChecksUrl: `${getConfigDetails().baseURL}check-booking-before-payment`,
	getAfterPayment: `${getConfigDetails().baseURL}aaa-booking-after-payment`,
	getPassDetails: `${getConfigDetails().baseURL}get-partner-user-passes-Byid`,
	getPermitDetails: `${getConfigDetails().baseURL}are-permit-details`,

	timeDropDown: [
		{ value: '00:00:00', time: '12:00 AM' }, { value: '00:30:00', time: '12:30 AM' },
		{ value: '01:00:00', time: '01:00 AM' }, { value: '01:30:00', time: '01:30 AM' },
		{ value: '02:00:00', time: '02:00 AM' }, { value: '02:30:00', time: '02:30 AM' },
		{ value: '03:00:00', time: '03:00 AM' }, { value: '03:30:00', time: '03:30 AM' },
		{ value: '04:00:00', time: '04:00 AM' }, { value: '04:30:00', time: '04:30 AM' },
		{ value: '05:00:00', time: '05:00 AM' }, { value: '05:30:00', time: '05:30 AM' },
		{ value: '06:00:00', time: '06:00 AM' }, { value: '06:30:00', time: '06:30 AM' },
		{ value: '07:00:00', time: '07:00 AM' }, { value: '07:30:00', time: '07:30 AM' },
		{ value: '08:00:00', time: '08:00 AM' }, { value: '08:30:00', time: '08:30 AM' },
		{ value: '09:00:00', time: '09:00 AM' }, { value: '09:30:00', time: '09:30 AM' },
		{ value: '10:00:00', time: '10:00 AM' }, { value: '10:30:00', time: '10:30 AM' },
		{ value: '11:00:00', time: '11:00 AM' }, { value: '11:30:00', time: '11:30 AM' },
		{ value: '12:00:00', time: '12:00 PM' }, { value: '12:30:00', time: '12:30 PM' },
		{ value: '13:00:00', time: '01:00 PM' }, { value: '13:30:00', time: '01:30 PM' },
		{ value: '14:00:00', time: '02:00 PM' }, { value: '14:30:00', time: '02:30 PM' },
		{ value: '15:00:00', time: '03:00 PM' }, { value: '15:30:00', time: '03:30 PM' },
		{ value: '16:00:00', time: '04:00 PM' }, { value: '16:30:00', time: '04:30 PM' },
		{ value: '17:00:00', time: '05:00 PM' }, { value: '17:30:00', time: '05:30 PM' },
		{ value: '18:00:00', time: '06:00 PM' }, { value: '18:30:00', time: '06:30 PM' },
		{ value: '19:00:00', time: '07:00 PM' }, { value: '19:30:00', time: '07:30 PM' },
		{ value: '20:00:00', time: '08:00 PM' }, { value: '20:30:00', time: '08:30 PM' },
		{ value: '21:00:00', time: '09:00 PM' }, { value: '21:30:00', time: '09:30 PM' },
		{ value: '22:00:00', time: '10:00 PM' }, { value: '22:30:00', time: '10:30 PM' },
		{ value: '23:00:00', time: '11:00 PM' }, { value: '23:30:00', time: '11:30 PM' }
	],


	getUserDetailUrl: `${getConfigDetails()?.baseURL}search-ticket-number`,
	getSearchTicketUrl: `${getConfigDetails()?.baseURL}get-receipt-ticket-details`,
	downloadEticketUrl: `${getConfigDetails()?.baseURL}download-receipt-pdfticket`,

	getFaclityUrl: `${getConfigDetails().baseURL}pave-web-get-facility-rate`,

	postUrlForPayment: `${getConfigDetails().baseURL}pave-make-web-ungated-checkin-payment`,

	// End Current Parking
	endParking: `${getConfigDetails()?.baseURL}pave-end-session`,
	getExtendDetails: `${getConfigDetails().baseURL}pave-extend-payment-details`,
	postExtendDays: `${getConfigDetails().baseURL}pave-days-amount`,
	postExtendSubmit: `${getConfigDetails().baseURL}pave-make-extend-payment`,
	getTicketDownloadUrl: `${getConfigDetails().baseURL}download-receipt-pdfticket`,

	singupUrl: `${getConfigDetails().baseURL}sign-up`,

	sendEmailResetPassword: `${getConfigDetails().baseURL}user/reset-password/email`,
	// GET EXTEND LIST
	getExtendList: `${getConfigDetails()?.baseURL}get-ticket-extend-list`,

	//reservation 
	// Promo code apply
	applyPromoCode: `${getConfigDetails().baseURL}validate_promocode`,
	//payment Initial Session API
	saveReservationPaymentUrl: `${getConfigDetails().baseURL}pave-user-reservation-guest`,
	// GET PARTNER DETAILS BY SLUG
	getPartnerBySlug: `${getConfigDetails().baseURL}partner-details-by-slug`,

	// GET Price DETAILS BY Time
	getPriceByTime: `${getConfigDetails().baseURL}rate/facility-on-marker`,
	downloadBookingEticketUrl: `${getConfigDetails()?.baseURL}aaa-download-booking-pdf-guest`,
	//getCheckInGateUrl: `${getConfigDetails().baseURL}facility-entry-gates`,
	getCheckInGateUrl: `${getConfigDetails().baseURL}cruise`,

}