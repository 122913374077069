
import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Button } from 'reactstrap'
import './Confermation.scss'
import { useState } from 'react'
import { postRestPassword } from '../../redux/actions/resetPasswordAction'
import { Circles } from 'react-loader-spinner'
import {  Form } from 'react-bootstrap'
import { newpasswordValidation } from '../../constants/globalConstants'



function ResetPassWordModal(props) {
    const { setResetModalOpen, isResetModalOpen, modalHeader, buttonLable, dispatch } = props
    const [passwordValues, setPasswordValues] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [resettingPassword, setResettingPassword] = useState(false)
    const [cpasswordShown, setCPasswordShown] = useState(false)
    const [npasswordShown, setNPasswordShown] = useState(false)
    const [error, setError] = useState({ new_password_strength: false })
    const toggle = () => setResetModalOpen(!isResetModalOpen)


    const handlePasswordInput = (event) => {
        const { name, value } = event.target
        setErrorMessage('')
        setPasswordValues({ ...passwordValues, [name]: value })
        if (name == 'new_password') {
            setError({ ...error, new_password_strength: false })
        }
    }

    const togglePasswordVisiblity = (e, key) => {
        if (key === 'cpassword') {
            setCPasswordShown(cpasswordShown ? false : true)
        }
        if (key === 'npassword') {
            setNPasswordShown(npasswordShown ? false : true)
        }     
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!newpasswordValidation(passwordValues?.new_password)) {
            setError({ ...error, new_password_strength: true })
            return
        }  
        setResettingPassword(true)
        const payload = { 'password': passwordValues?.new_password, 'old_password': passwordValues?.current_password }
        if (payload?.password !== '' && payload?.old_password !== '') {
            dispatch(postRestPassword(payload)).then((res) => {
                passwordValues['current_password'] = ''
                passwordValues['new_password'] = ''
                setPasswordValues({ ...passwordValues })
                setResettingPassword(false)
                if (res?.data && res?.status === 201) {
                    setResetModalOpen(!isResetModalOpen)
                } else if (res?.data === null && res?.errors?.message) {
                    setErrorMessage(res?.errors?.message)
                }
            }).catch(() => {
                setResettingPassword(false)
                setErrorMessage('Something went wrong, Please try again!')
            })
        }
    }

    return (
        <div>
            <Modal isOpen={isResetModalOpen} toggle={toggle} {...props}>
                <ModalHeader toggle={toggle}>{modalHeader}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                    <div className="mb-3 myiconset">
                        <Label for="examplePassword">Current Password</Label>
                        <Input type={cpasswordShown ?  'text' : 'password'} name="current_password" id="examplePassword" placeholder="Current Password" onChange={handlePasswordInput} value={passwordValues?.current_password ? passwordValues?.current_password : ''} />
                        <i className={cpasswordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'cpassword')}></i>
                    </div>
                    </FormGroup>
                    <FormGroup>
                    <div className="mb-3 myiconset">
                        <Label for="examplePassword">New Password</Label>
                        <Input type={npasswordShown ? 'text' : 'password'} name="new_password" id="examplePassword" placeholder="New Password" onChange={handlePasswordInput} value={passwordValues?.new_password ? passwordValues?.new_password : ''} />
                        <i className={npasswordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'npassword')}></i>
                    </div>
                    {(error?.new_password_strength) && <Form.Text className="text-danger">Password should 8 to 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character.</Form.Text>}
                    </FormGroup>

                    {errorMessage ? <span className='text-danger'>{errorMessage ? errorMessage : ''}</span> : ''}
                </ModalBody>
                <ModalFooter>
                    <Button className='modalButton d-flex align-items-center' color={buttonLable === 'Delete' ? 'danger' : 'primary'} onClick={(e) => handleSubmit(e)} disabled={passwordValues?.current_password && passwordValues.new_password ? false : true}>{buttonLable}{resettingPassword ? <span className=' ms-2'><Circles
                        height="20"
                        width="20"
                        color="#ffffff"
                        ariaLabel="circles-loading"
                        visible={true}
                    /></span> : null}</Button>{' '}
                    <Button className='modalButton' color="secondary" onClick={toggle}> Cancel </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


ResetPassWordModal.propTypes = {
    dispatch: PropTypes.func,
    setResetModalOpen: PropTypes.func,
    isResetModalOpen: PropTypes.bool,
    modalHeader: PropTypes.string,
    confermationMessage: PropTypes.string,
    buttonLable: PropTypes.string,
    handleDelete: PropTypes.func
}

ResetPassWordModal.defaulProps = {
    dispatch: noop,
    setResetModalOpen: PropTypes.func,
    isResetModalOpen: PropTypes.bool,
    modalHeader: PropTypes.string,
    confermationMessage: PropTypes.string,
    buttonLable: PropTypes.string,
    handleDelete: PropTypes.func
}

function mapStateToProps() {
    return {}
}
export default connect(mapStateToProps)(ResetPassWordModal)