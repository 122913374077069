import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
const cardnumbermask = createDefaultMaskGenerator('9999')
const expirymask = createDefaultMaskGenerator('99/99')
const phoneMask = createDefaultMaskGenerator('(999) 999-9999')
import 'react-toastify/dist/ReactToastify.css'
import { getTicketList } from '../../../redux/actions/eTicketRecipt'
import 'react-datepicker/dist/react-datepicker.css'
import { Circles } from 'react-loader-spinner'
// import Ticket from '../main'
import { errorMessage } from '../../../constants/errorMessage'
import { EMPTY_ARRAY } from '../../../constants/globalConstants'
import TicketList from './TicketList'
import { useEffect } from 'react'
import { renderNoResults } from '../../../components/global/NoDataFound'
// import logos from '../../assets/img/logos.svg'

function SearchTicket(props) {
    const { handleSubmit, control, formState: { errors }, reset } = useForm()
    const { dispatch, loader, notify } = props
    // const [phoneNumber, setPhoneNumber] = useState('')
    // const [cardNumber, setCardNumber] = useState('')
    // const [cardExpiry, setCardExpiry] = useState('')
    // const [ticketDetails, setTicketDetails] = useState('')
    const [searchByCard, setSearchByCard] = useState(true)
    const [ticketList, setTicketList] = useState(EMPTY_ARRAY)
    const [error, setError] = useState(null)


    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))?.user
        const partnersData = JSON.parse(localStorage.getItem('partnerData'))
        const payload = {
            card_expiry: '',
            card_number: '',
            checkin_date: '',
            phone: userData?.phone_number,
            ticket_number: '',
            role:partnersData?.roles?.[0],
            id:partnersData?.id

        }
        // if ((data?.card_number != '' && data?.card_expiry != '') || data?.phone != '') {
        dispatch(getTicketList(payload)).then((res) => {
            if (res?.status === 201 || res?.status === 200) {
                if (res?.data && res?.data != null) {
                    // notify('success', 'T successfully')
                    setTicketList(res?.data)
                    localStorage.setItem('partner_id', JSON.stringify(res?.data?.[0]?.partner_id))
                } else {
                    setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                    notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                }
            } else {
                setTicketList('')
                setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
            }
        }).catch(() => {
            setTicketList('')
            setError(errorMessage?.somethingWentWrong)
            notify('error', errorMessage?.somethingWentWrong)
        })
        // }  else {
        //     notify('error', 'Please fill the search details')
        // }
    }, [EMPTY_ARRAY])

    const onSubmit = (data) => {

        // const payload = { ...data, checkin_date: data?.checkin_date ? moment(data?.checkin_date).format('Y-MM-DD') : '', card_number: cardNumber, card_expiry: cardExpiry }
        // const isEmpty = Object.values(payload).every(x => x === null || x === '')
        const payload = {
            card_expiry: data?.card_expiry,
            card_number: data?.card_number,
            checkin_date: '',
            phone: data?.phone,
            ticket_number: '',

        }
        if ((data?.card_number != '' && data?.card_expiry != '') || data?.phone != '') {
            dispatch(getTicketList(payload)).then((res) => {
                if (res?.status === 201 || res?.status === 200) {
                    if (res?.data && res?.data != null) {
                        // notify('success', 'T successfully')
                        setTicketList(res?.data)
                    } else {
                        setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                        notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                    }
                } else {
                    setTicketList('')
                    setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                    notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                }
            }).catch(() => {
                setTicketList('')
                setError(errorMessage?.somethingWentWrong)
                notify('error', errorMessage?.somethingWentWrong)
            })
        } else {
            notify('error', 'Please fill the search details')
        }
    }

    // const handleCardNumber = (event) => {
    // 	setCardNumber(event)
    // }
    // const handlePhoneNumber = (event) => {
    // 	setPhoneNumber(event)
    // }

    // const handleCardExpiry = (event) => {
    // 	setCardExpiry(event)
    // }
    const handleSearchByEvent = () => {
        reset({
            card_expiry: '',
            card_number: '',
            phone: '',
        })
        setSearchByCard(!searchByCard)
    }

    return (
        <div>
            <div className="card bg-white rounded border-0">
                <h4 className="fw-bold">Receipts </h4>
                {/* <button className="btn btn-primary ms-auto" onClick={handleModalOpen}><i className="fa fa-plus"></i> Add</button> */}
                <div className="card-body">
                    {/* to hide the form ui style added */}
                    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'none' }}>
                        <div className="row mb-3">
                            <div className="row mb-3">
                                {/* <label className='switch'>
											<input type="checkbox" disabled={loader} checked={searchByCard} onChange={()=>handleSearchByEvent()} data-toggle="toggle"/>
											<span className='slider round'></span>
										</label> */}
                                <span className='fw-bold d-block'>Search By</span>
                                <div className='col-6'>
                                    <input type="checkbox" disabled={loader} checked={searchByCard} onChange={() => handleSearchByEvent()} data-toggle="toggle" />
                                    <span className='small text-black ms-2'>Card Details</span>
                                </div>
                                <div className='col-6'>
                                    <input type="checkbox" disabled={loader} checked={!searchByCard} onChange={() => handleSearchByEvent()} data-toggle="toggle" />
                                    <span className='small text-black ms-2'>Phone Number</span>
                                </div>
                            </div>
                            {/* Display if Card Number Searching is Enabled */}
                            {searchByCard ? <div className="col col-sm-12 col-12">
                                <div className="form-group"><label htmlFor="card_number">Card Information</label></div>
                                <div className="input-group form-group">
                                    <Controller
                                        name="card_number"
                                        type="text"
                                        id="card_number"
                                        control={control}
                                        // rules={{ required: (cardExpiry.length === 4 && cardNumber.length !== 4) ? true : false, maxLength: 4 }}
                                        rules={{
                                            required: { value: true, message: 'Card Number is required' },
                                            minLength: { value: 4, message: 'Minimum 4 characters allowed' },
                                            maxLength: { value: 4, message: 'Maximum 4 characters allowed' },
                                        }}
                                        render={({ field }) => <MaskedInput
                                            maskGenerator={cardnumbermask}
                                            autoComplete="off"
                                            pattern="[0-9 ]{4}"
                                            maxLength='4'
                                            {...field}
                                            id='card_number'
                                            title="Card no. should be 15-16 digit numberic"
                                            className="form-control specialBorder"
                                            onInvalid={e => e.target.setCustomValidity('Please enter a valid card number')}
                                            onInput={e => e.target.setCustomValidity('')}
                                            placeholder="Last 4 digit"
                                        // onChange={(event) => field.onChange(handleCardNumber(event))}
                                        />}
                                    />

                                    {/* expiry input filled  */}
                                    <Controller
                                        name="card_expiry"
                                        type="text"
                                        id="card_expiry"
                                        control={control}
                                        // rules={{ required: (cardNumber.length === 4 && cardExpiry.length !== 4) ? true : false, maxLength: 5 }}
                                        rules={{
                                            required: { value: true, message: 'Card Expiry is required' },
                                            minLength: { value: 4, message: 'Minimum 4 characters allowed' },
                                            maxLength: { value: 4, message: 'Maximum 4 characters allowed' },
                                        }}
                                        render={({ field }) => <MaskedInput
                                            maskGenerator={expirymask}
                                            autoComplete="off"
                                            pattern="(?:0?[1-9]|1[0-2])/[0-9 ]{2}"
                                            maxLength='5'
                                            {...field}
                                            id='card_expiry'
                                            className="form-control"
                                            onInvalid={e => e.target.setCustomValidity('Please enter mm/yy format card expiry date')}
                                            onInput={e => e.target.setCustomValidity('')}
                                            placeholder="mm/yy"
                                        // onChange={(event) => field.onChange(handleCardExpiry(event))}
                                        />}
                                    />
                                </div>
                                {errors?.card_number && <span className='text-danger small'>{errors?.card_number?.message}</span>}
                                {errors?.card_expiry && <span className='text-danger small'>{errors?.card_expiry?.message}</span>}

                            </div> :
                                /* Display if Phone Number Searching is Enabled */
                                <div className="col col-sm-12 col-12">
                                    <div className="form-group"><label htmlFor="card_number">Phone Number</label></div>
                                    <div className="input-group form-group">
                                        <Controller
                                            name="phone"
                                            type="text"
                                            id="phone"
                                            control={control}
                                            rules={{
                                                required: { value: true, message: 'This field is required' },
                                                minLength: { value: 10, message: 'Minimum 10 characters allowed' },
                                                maxLength: { value: 10, message: 'Maximum 10 characters allowed' },
                                            }}
                                            render={({ field }) => <MaskedInput
                                                maskGenerator={phoneMask}
                                                autoComplete="off"
                                                maxLength='14'
                                                {...field}
                                                id='phone'
                                                title="Phone number should be 10 digit numberic"
                                                className="form-control"
                                                onInvalid={e => e.target.setCustomValidity('Please enter a valid phone number')}
                                                onInput={e => e.target.setCustomValidity('')}
                                                placeholder="Phone Number"
                                            // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                                            />}
                                        />


                                    </div>
                                    {errors?.phone && <span className='text-danger small'>{errors?.phone?.message}</span>}

                                </div>}

                            <div className="col-sm-2 mt-3">
                                <div className='row'>
                                    <div className='col'>
                                        <button type="submit" name="submit" className="btn mt-2 w-100" style={{ backgroundColor: '#27A8E1', color: 'white' }} onClick={handleSubmit} > <div className='d-flex justify-content-center align-items-center'>
                                            <span>Search</span>
                                            <span className='ms-2'>
                                                {loader ? <Circles
                                                    height="20"
                                                    width="20"
                                                    style={{ display: 'inline-block' }}
                                                    color="#ffffff"
                                                    ariaLabel="circles-loading"
                                                    visible={true}
                                                /> : null}
                                            </span>
                                        </div>
                                        </button>
                                    </div>
                                    {/* {ticketList?.ticket_number && <div className='col-6'>
                                        <button 
											type="button" 
											name="submit" 
											className="btn mt-2 w-100" 
											style={{ backgroundColor: '#27A8E1', color: 'white' }} 
											onClick={() => handleDownloadTicket(ticketList?.ticket_number)} > 
											<div className='d-flex justify-content-center align-items-center'>
												<span>Download <i className="fa fa-download"></i></span>
												<span className='ms-2'>
													{loaderDownload ? <Circles
														height="20"
														width="20"
														style={{ display: 'inline-block' }}
														color="#ffffff"
														ariaLabel="circles-loading"
														visible={true}
													/> : null}
												</span>
											</div>
                                        </button>
                                    </div>} */}
                                </div>
                            </div>
                        </div>
                    </form>
                    {loader ? <span className='ms-2 d-flex justify-content-center'><Circles
                        height="50"
                        width="50"
                        color="var(--bs-primary)"
                        ariaLabel="circles-loading"
                        visible={true}
                    /></span> : ticketList?.data?.length > 0 ? <TicketList ticketList={ticketList} notify={notify} /> : renderNoResults(error)}
                </div>
            </div>

        </div>

    )
}


SearchTicket.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    loaderSms: PropTypes.bool,
    loaderDownload: PropTypes.bool,
    notify: PropTypes.func,
}

SearchTicket.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
}

function mapStateToProps({ userDetailsReducer }) {
    return {
        loader: userDetailsReducer?.loader,
        loaderSms: userDetailsReducer?.loaderSms,
        loaderDownload: userDetailsReducer?.loaderDownload,
    }
}
export default connect(mapStateToProps)(SearchTicket)

