import CONSTANTS from '../constants'
import { getRequest, putRequest, postRequest, deleteRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

// Calls the API to get Facility Initials
export const getCardDataList = (page = '', search = '', sortBy = '', sortByOrder = '') => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_CARD_LIST_REQUEST })
   // console.log('pagepage' , page)
    let url=''
    if(page)
     url = `${APICONFIG.getCardDataUrl}/page/${page}`
    else
    url = `${APICONFIG.getCardDataUrl}`

    let endpoint = `${url}`
    if ((sortBy && sortBy !== '') && (search && search !== '')) {
        endpoint = `${url}?search=${search}&sort=${sortBy}&sortBy=${sortByOrder}`
    } else if ((sortBy && sortBy !== '')) {
        endpoint = `${url}?sort=${sortBy}&sortBy=${sortByOrder}`
    } else if ((search && search !== '')) {
        endpoint = `${url}?search=${search}`
    } else {
        endpoint = `${url}`
    }
    return getRequest(endpoint).then((res) => {
        dispatch({ type: CONSTANTS.GET_CARD_LIST_SUCCESS, data: res.data })
        return res
    }).catch(() => {
        dispatch({ type: CONSTANTS.GET_CARD_LIST_FAILURE })
    })
}


export const createCardData = (payload) => dispatch => {
    dispatch({ type: CONSTANTS.CREATE_CARD_REQUEST })
    return postRequest(APICONFIG.createCardUrl, payload).then((res) => {
        dispatch({ type: CONSTANTS.CREATE_CARD_SUCCESS, data: res.data })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.CREATE_CARD_FAILURE })
        return err
    })
}

export const updateCardData = (payload, cardId) => (dispatch) => {
    dispatch({ type: CONSTANTS.UPDATE_CARD_REQUEST })
    return putRequest(`${APICONFIG.updateCardDataUrl}/${cardId}`, payload).then((res) => {
        dispatch({ type: CONSTANTS.UPDATE_CARD_SUCCESS, data: res.data })
        return res.data
    }).catch(() => {
        dispatch({ type: CONSTANTS.UPDATE_CARD_FAILURE })
    })
}


export const removeCardData = (cardId) => dispatch => {
    dispatch({ type: CONSTANTS.CARD_DELETE_REQUEST })
    return deleteRequest(`${APICONFIG.removeCardUrl}/${cardId}`).then(() => {
        dispatch({ type: CONSTANTS.CARD_DELETE_SUCCESS })
    }).catch(() => {
        dispatch({ type: CONSTANTS.CARD_DELETE_FAILED })
    })
}

