import React from 'react'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_OBJECT } from '../../../constants/globalConstants'
import { downloadTicket } from '../../../redux/actions/eTicketRecipt'


function Receipt(props) {

    const { handleDetails, receipt, key, notify, dispatch, loader } = props
    const handleDownloadTicket = (ticketId, partner_id) => {
        if (!loader) {
            dispatch(downloadTicket(ticketId, partner_id)).then((res) => {
                if (res?.status && res?.status == 500) {
                    notify('error', 'Something went wrong, Please try again!')
                }
                notify('Success', 'E-Ticket Downloaded')
            }).catch(() => {
                notify('error', 'Something went wrong, Please try again!')
            })
        }
    }

    return (
        <div>
            <Row key={key} className='d-flex'>
                <div className='align-items-center border-bottom d-flex'>
                    <Col sm={3} xs={3} className='small d-flex align-items-center justify-content-center ' onClick={() => { handleDetails(receipt) }}>
                        <div className='text-primary fw-bold' >{receipt?.ticket_number}</div>
                    </Col>
                    <Col sm={7} xs={7} className='ms-2 py-3' onClick={() => { handleDetails(receipt) }}>
                        {/* <small>{faciltyData?.facility?.between_streets}</small> */}
                        <div className='text-black small fw-bold truncateName' >{receipt?.user?.name}</div>
                        <div className='mb-0 small text-black'>{receipt?.facility?.full_name}</div>
                        <small className='mt-0 small header-s text-secondary'>{moment(receipt?.check_in_datetime).format('ddd, MMMM D, h:mm A')}</small>
                    </Col>
                    <Col sm={2} xs={2} className='h-100 small d-flex align-items-center justify-content-center zIndex' onClick={() => handleDownloadTicket(receipt?.ticket_number, receipt?.partner_id)}>
                        <i className='fa fa-download'></i>
                    </Col>
                </div>
            </Row>

        </div>
    )
}

Receipt.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    handleDetails: PropTypes.func,
    receipt: PropTypes.object,
    key: PropTypes.string,
    notify: PropTypes.func,

}

Receipt.defaulProps = {
    dispatch: noop,
    loader: true,
    handleDetails: noop,
    receipt: EMPTY_OBJECT,
    key: '',
    notify: noop,

}

function mapStateToProps({ userDetailsReducer }) {
    return {
        loader: userDetailsReducer?.loaderDownload
    }
}
export default connect(mapStateToProps)(Receipt)