import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
//import { EncryptionHandler, getpartnerDetails } from '../../utils/helper'
import { EncryptionHandler,changeTimeZone, isLoggedIn,sortPortalPermissionByKey,isPermissionExist,loggedInUserData } from '../../utils/helper'
import { getCardDataList } from '../../redux/actions/cardDetailsAction'

import { QRCodeSVG } from 'qrcode.react'
import './reservation.css'
// import { getConfigDetails } from '../../services/config'

import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Circles } from 'react-loader-spinner'
// import setHours from 'date-fns/setHours'
// import setMinutes from 'date-fns/setMinutes'
// import { errorMessage } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import {
  applyPromoCodeReservation,
  saveReservationPayment,
  downloadTicket,
} from '../../redux/actions/widgetReservationAction'
import { getVehicleDataList } from '../../redux/actions/VehicleListAction'
//import moment from 'moment'
//import moment from '../../Global/Time/Moment'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
//import { useParams, useLocation } from 'react-router-dom'
// import { useLocation } from 'react-router-dom'

import { getPriceDetailsByTime,getCheckInGate } from '../../redux/actions/widgetReservationAction'
function responsiveReservation(props) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    trigger,
  } = useForm()
  //   const { dispatch, loader, notify } = props
  const { dispatch, notify, partnerDetails } = props
  // console.log('partnerDetails',partnerDetails)
  //const [facilityTimeZone, setFacilityTimeZone] = useState()
  // const cardnumbermask = createDefaultMaskGenerator('9999-9999-9999-9999-9999')
  const cardnumbermask = createDefaultMaskGenerator('9999 9999 9999 9999')
  const expirymask = createDefaultMaskGenerator('99/99')
  const phoneMask = createDefaultMaskGenerator('(999) 999-9999')
  const [page, setPage] = useState(1)
  // const [partners] = useState('worldport-la')
  // const [isValidPartner, setIsValidPartner] = useState(false)
  // const [partnerDetails, setpartnerDetails] = useState(EMPTY_OBJECT)
  const [facility, setFacility] = useState(EMPTY_ARRAY)

  const [facilityId, setFacilityId] = useState('')
  const [priceDetails, setPriceDetails] = useState(EMPTY_OBJECT)
  // const [ setPriceDetailsResponce] =useState(EMPTY_OBJECT)
  const [promoCodeApply, setPromoCodeApply] = useState(false)
  const [discount, setDiscount] = useState()
  //payment
  const [bookingStatus, setBookingStatus] = useState(false)
  const [bookingError, setBookingError] = useState(false)
  const [totalHoursLength, setTotalHoursLength] = useState(0)
  const [ticketDetails, setTicketDetails] = useState(EMPTY_OBJECT)
  const [isLoading, setIsLoading] = useState(true)
  const [facilityName, setFacilityName] = useState(true)
  const [promocode, setPromocode] = useState('')
  const [webSourceBooking, setWebSourceBooking] = useState('')
  const [mapURL, setMapURL] = useState('')
  // const [loginData, setLoginData] = useState(EMPTY_OBJECT)
  // const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [mobile, setMobile] = useState('')
  //const [ticketId, setTicketId] = useState('')
  const [isLicensePlate, setIsLicensePlate] = useState(false)
  const [loaderTicket, setLoaderTicket] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [entryDateTime, setEntryDateTime] = useState(new Date())
  const [exitDateTime, setExitDateTime] = useState(new Date())
  const [startDateTime, setStartDateTime] = useState(new Date())
  const [isCardFormVisible, setCardFormVisible] = useState(false)
  const [cardList, setCardList] = useState(EMPTY_ARRAY)
  const [paymentProfileId, setPaymentProfileId] = useState('')
  const [userConsent, setUserConsent] = useState(false)
  const [loaderPrice, setLoaderPrice] = useState(false)
  const [paymentDisable, setPaymentDisable] = useState(false)
  const [partnerUserId, setPartnerUserId] = useState(false)
  const [checkInGate, setCheckInGate] = useState(EMPTY_ARRAY)
  const [checkInGateID, setCheckInGateID] = useState('')
  const [accessPermissions, setAccessPermissions] = useState('')


  //vehicel

  const [isVehicleTableVisible, setIsVehicleTableVisible] = useState(false)
  const [vehicleList, setVehicleList] = useState(EMPTY_ARRAY)
  const [vehicalId, setVehicalId] = useState('')
  

  useEffect(() => {
		const getAccessPermissionData = sortPortalPermissionByKey(partnerDetails, 'Restricted Permissions')
		setAccessPermissions(getAccessPermissionData)
	},[EMPTY_ARRAY, partnerDetails])

  useEffect(() => {
    let facilityList = partnerDetails?.facilities
    facilityList = (facilityList && facilityList.length > 0) ? facilityList.filter((v) => v.is_lot != '1') : []
    setFacility(facilityList)
  }, [partnerDetails])

  const handleVehicleChange = (event) => {
    clearErrors('vehicle')
    setVehicalId(event.target.value)
    setValue('vehicle', event.target.value)
    setValue('license_plate', event.target.value)
  }

  const showVehicleTable = () => {
    clearErrors('vehicle')
    setIsVehicleTableVisible(true)
  }

  const removeVehicleMake = (e, i) => {
    console.log(e, i)
    setIsVehicleTableVisible(false)
  }

  const handleDownloadTicket = (Id, ticketNumber) => {
    if (!loaderTicket) {
      setLoaderTicket(true)
      dispatch(downloadTicket(Id, ticketNumber))
        .then((res) => {
          setLoaderTicket(false)
          if (res?.status && res?.status == 500) {
            notify('error', 'Something went wrong, Please try again!')
          }
          notify('Success', 'E-Ticket Downloaded!')
        })
        .catch(() => {
          setLoaderTicket(false)
          notify('error', 'Something went wrong, Please try again!')
        })
    }
  }

  const resetFormValue = () => {
    reset()
    setTotalHoursLength(0)
    setPaymentProfileId('')
    setPromoCodeApply(false)
    setDiscount(0)
    setPromoCodeApply(false)
    setPromocode()
    //setPriceDetailsResponce(EMPTY_OBJECT)
    setPriceDetails(EMPTY_OBJECT)
    setValue('entry_time', new Date(entryDateTime))
    setValue('exit_time', new Date(exitDateTime))
    setValue('garage', facilityId)
    setPaymentDisable(false)
    setVehicalId('')
    setValue('vehicle', '')

  }

  const applyPromocode = () => {
    let promoForm = getValues()
    if (promoForm.email) {
      if (promoForm.promo_code) {
        let promoCodePayload = {
          is_guest: isLoggedIn() ? false : true,
          tax_amount: priceDetails.tax_fee,
          client_id: partnerDetails?.clients?.secret,
          email: promoForm.email, //'dhirendra002@yopmail.com',
          promocode: promoForm.promo_code, //'TESTWORLDPORT',
          amount: priceDetails.parking_amount,//payable_amount,parking_amount
          processing_fee: priceDetails.processing_fee,
        }
        dispatch(applyPromoCodeReservation(promoCodePayload))
          .then((response) => {
            if (response.status == 200 && response.data) {
              setPromocode(promoCodePayload.promocode)
              setPromoCodeApply(true)
              setDiscount(response.data.discount_in_dollar)
              let checkPayment = priceDetails.payable_amount - response.data.discount_in_dollar
              if (checkPayment == 0) {
                setPaymentDisable(true)
              } else {
                setPaymentDisable(false)
              }
              notify(
                'success',
                response.data
                  ? response.data.message
                  : 'Promocode applied successfully!'
              )
            } else {
              setPaymentDisable(false)
              notify(
                'error',
                response.errors
                  ? response.errors.message
                  : 'Please Enter Valid Promo Code'
              )
            }
          })
          .catch((err) => {
            notify('error', err)
          })
      } else {
        notify('error', 'Please Enter Promo Code')
      }
    } else {
      notify('error', 'Please Enter email')
    }
  }

  const resetPriceValue = () => {
    setPromoCodeApply(false)
    setDiscount(0)
  }

  const removePromocode = () => {
    setPromoCodeApply(false)
    setDiscount(0)
    setPromocode()
    setValue('promo_code', '')
    if (priceDetails.payable_amount > 0) {
      setPaymentDisable(false)
    }
  }

  const diff_hours = (endTime, startTime) => {
    const startDate = new Date(startTime)
    const endDate = new Date(endTime)
    let timeDiff
    if (!isNaN(startDate) && !isNaN(endDate)) {
      const timeDifferenceInMilliseconds = endDate - startDate
      const hours = Math.floor(timeDifferenceInMilliseconds / 3600000)
      const minutes = Math.floor((timeDifferenceInMilliseconds % 3600000) / 60000)
      timeDiff = `${hours}.${minutes}`
    } else {
      timeDiff = 'Invalid Time'
    }
    setTotalHoursLength(timeDiff)
    return timeDiff

  }



  const dateTimeFormatefunction = (date) => {
    let dateformate =
      date.toLocaleDateString('en-CA') +
      ' ' +
      String(date.getHours()).padStart(2, '0') +
      ':' +
      String(date.getMinutes()).padStart(2, '0') +
      ':' +
      String(date.getSeconds()).padStart(2, '0')
    return dateformate
  }

  const getNumberPrice = (value) => {
    let data = value.toString()
    let price
    if (data != 'N/A') {
      price = data.replace(/,/g, '')
      price = Number(price)
    } else {
      price = 'N/A'
    }

    return price
  }

  const getCheckInGateLane =(facilityIds)=>{
   let facility= facilityIds?facilityIds:facilityId
   if(facility){
    dispatch(getCheckInGate(facility)).then((res) => {
      setCheckInGate(res.data)
    })
   }
    
  }

  const getPriceDetaitlsToggle = (partnerId, facilityIds, startDate, endDate) => {
    //console.log('coming getPriceDetaitlsToggle',startDate,endDate)    
    setIsLoading(true)
    setLoaderPrice(true)
    setPaymentDisable(false)
    let user_id = partnerId
    let dt1 = startDate
    let dt2 = endDate
    // let dt1 = startDate ? startDate : entryDateTime
    // let dt2 = endDate ? endDate : exitDateTime
    if (user_id && facilityIds && dt1 <= dt2 && dt1 != null && dt2 != null) {
      let hours = diff_hours(dt2, dt1)
      if (hours >= 1) {
        setTotalHoursLength(hours)
        setTimeout(() => {
          let entryTime = dateTimeFormatefunction(new Date(dt1))
          let slug = `arrival_time=${entryTime}&length_of_stay=${hours}&use_bonus=0&user_id=${user_id}`
          dispatch(getPriceDetailsByTime(facilityIds, slug)).then((res) => {
            let priceRes
            let isFacilityClosed = res?.data?.isFacilityClosed
            if (res?.data && isFacilityClosed != 1) {
              // setPriceDetailsResponce(res?.data)
              let price = Number(parseFloat(getNumberPrice(res?.data?.price)).toFixed(2))
              let tax_fee = Number(parseFloat(getNumberPrice(res?.data?.tax_fee)).toFixed(2))
              let processing_fee = Number(parseFloat(getNumberPrice(res?.data?.facility?.processing_fee)).toFixed(2))
              let payableAmount = price + tax_fee + processing_fee
              if (payableAmount == 0) {
                setPaymentDisable(true)
              } else {
                setPaymentDisable(false)
              }
              let priceRes = {
                parking_amount:
                  res?.data?.price != 'N/A'
                    ? price.toFixed(2)
                    : 'N/A',
                tax_fee:
                  res?.data?.tax_fee != 'N/A'
                    ? tax_fee.toFixed(2)
                    : 'N/A',
                processing_fee:
                  res?.data?.facility?.processing_fee != 'N/A'
                    ? processing_fee.toFixed(2)
                    : 'N/A',
                payable_amount:
                  res?.data?.price != 'N/A'
                    ? (payableAmount).toFixed(2)
                    : 'N/A',
              }

              res?.data?.price == 'N/A'
                ? setIsLoading(true)
                : setIsLoading(false)
              setPriceDetails(priceRes)
              //console.log('priceRes',priceRes)
            } else {
              priceRes = {
                parking_amount: 'N/A',
                tax_fee: 'N/A',
                processing_fee: 'N/A',
                payable_amount: 'N/A',
              }
              setPriceDetails(priceRes)
            }
            setLoaderPrice(false)
          })
        }, 0)
      } else {
        setLoaderPrice(false)
        setIsLoading(true)
        notify('error', 'Booking is available for minimum 1 hour!')
      }
    } else {

      let priceRes = {
        parking_amount: 'N/A',
        tax_fee: 'N/A',
        processing_fee: 'N/A',
        payable_amount: 'N/A',
      }
      setPriceDetails(priceRes)
      setLoaderPrice(false)
      if (facilityIds != '') {
        notify('error', 'sorry,something went wrong!')
      }
      // notify('error', 'sorry,something went wrong!')
    }
  }


  const handleEntryTime = (event) => {
    // console.log('handleEntryTime', event)
    resetPriceValue()
    setEntryDateTime(event)
    setValue('entry_time', event)
    if (event) {
      setExitDateTime(new Date(event).setHours(new Date(event).getHours() + 2))
      setValue(
        'exit_time',
        new Date(event).setHours(new Date(event).getHours() + 2)
      )
      clearErrors('exit_time')
      getPriceDetaitlsToggle(partnerUserId, facilityId, getValues().entry_time, getValues().exit_time)
    } else {
      getPriceDetaitlsToggle(partnerUserId, facilityId, event, getValues().exit_time)
    }
    clearErrors('entry_time')
  }

  const handleExitTime = (event) => {
    resetPriceValue()
    if (event == null) {
      setExitDateTime(event)
      setValue('exit_time', event)
    }

    if (event) {
      let dt1 = entryDateTime
      let dt2 = event
      let hours = diff_hours(dt2, dt1)
      if (dt2 > dt1) {
        if (hours > 24 * 30) {
          notify('error', 'Monthly parking not available!')
          // setIsLoading(true)
        } else if (hours < 1) {
          notify('error', 'Booking is available for minimum 1 hour!')
          //setIsLoading(true)
        } else {
          resetPriceValue()
          setExitDateTime(event)
          setValue('exit_time', event)
          clearErrors('exit_time')
          getPriceDetaitlsToggle(partnerUserId, facilityId, getValues().entry_time, getValues().exit_time)
        }

      } else {
        notify('error', 'End time should not be less then start time!')
        setIsLoading(true)

      }
    } else {
      getPriceDetaitlsToggle(partnerUserId, facilityId, event, event)
    }
  }
  

  const handelCheckInGate = (event) => {
    clearErrors('check_in_gate')
    setValue('check_in_gate', event.target.value)
    setCheckInGateID(event.target.value)
  }

  


  const handelGarage = (event) => {
    clearErrors('garage')
    setValue('garage', event.target.value)
    if (event.target.value) {
      let selectedFcility = facility.filter(
        (v) => v.id == event.target.value
      )
      let partnerFacilityId = event.target.value
      setFacilityId(partnerFacilityId)
      getCheckInGateLane(partnerFacilityId)
      setFacilityName(
        selectedFcility[0]?.full_name
          ? selectedFcility[0]?.full_name
          : facility[0]?.full_name
      )
      //new set time base on facility time zone
      //setFacilityTimeZone(selectedFcility[0]?.timezone)
      const d2 = moment(new Date()).tz(`${selectedFcility[0]?.timezone}`).format('YYYY-MM-DDTHH:mm:ss')
      let dateTime = timeRoundOff(d2)
      setValue('entry_time', new Date(dateTime))
      setValue('exit_time', new Date(dateTime).setHours(new Date(dateTime).getHours() + 2))
      setEntryDateTime(new Date(dateTime))
      setStartDateTime(new Date(dateTime))
      setExitDateTime(new Date(dateTime).setHours(new Date(dateTime).getHours() + 2))
      getPriceDetaitlsToggle(partnerUserId, partnerFacilityId, new Date(dateTime), new Date(dateTime).setHours(new Date(dateTime).getHours() + 2))
    } else {
      getPriceDetaitlsToggle(partnerUserId, '', entryDateTime, exitDateTime)

    }
  }
  //const { partner } = useParams()
 // const location = useLocation()
  // const bookingWeb = new URLSearchParams(location.search).get('web')
  let bookingWeb=location.host

  const timeRoundOff = (date) => {
    const start = moment(new Date(date))
    const remainder = 15 - (start.minute() % 15)
    return moment(start).add(remainder, 'minutes').format('YYYY-MM-DDTHH:mm')
  }

  useEffect(() => {
    loggedInUser()
    if (partnerDetails && Object.keys(partnerDetails).length > 0) {
      let user_id = (partnerDetails?.brand_setting?.user_id) ? partnerDetails?.brand_setting?.user_id : partnerDetails?.partner_details?.brand_setting?.user_id
      let facilityList = partnerDetails?.facilities
      facilityList = (facilityList && facilityList.length > 0) ? facilityList.filter((v) => v.is_lot != '1') : []

      let partnerFacilityId = facilityList.length == 1 ? facilityList[0]?.id : ''
      setWebSourceBooking(bookingWeb)
      setFacility(facilityList)
      setFacilityId(partnerFacilityId)
      setPartnerUserId(user_id)
      //setFacilityName(facilityList?.[0]?.full_name) 
      setFacilityName(facilityList.length == 1 ? facilityList[0]?.full_name : '')
      const d2 = moment(new Date())
        .tz(`${facilityList?.[0]?.timezone}`)
        .format('YYYY-MM-DDTHH:mm:ss')
      let dateTime = timeRoundOff(d2)
      setValue('entry_time', new Date(dateTime))
      setValue('exit_time', new Date(dateTime).setHours(new Date(dateTime).getHours() + 2))
      setEntryDateTime(new Date(dateTime))
      setStartDateTime(new Date(dateTime))
      setExitDateTime(new Date(dateTime).setHours(new Date(dateTime).getHours() + 2))
      setValue('garage', partnerFacilityId)
      getCheckInGateLane(partnerFacilityId)
      getPriceDetaitlsToggle(user_id, partnerFacilityId, new Date(dateTime), new Date(dateTime).setHours(new Date(dateTime).getHours() + 2))

    }

    if (isLoggedIn()) {

      dispatch(getCardDataList())
        .then((res) => {
          if (res?.data) {
            setCardList(res?.data?.payments)
            if (res?.data?.payments.length > 0) {
              setCardFormVisible(false)
            } else {
              setCardFormVisible(true)
            }
            // setIsLoading(false)
          }
        })
        .catch(() => {
          // setIsLoading(false)
        })
      //vehical
      dispatch(getVehicleDataList()).then((res) => {
        setVehicleList(res?.data)
        if (res?.data?.length > 0) {
          setIsVehicleTableVisible(false)
        } else {
          setIsVehicleTableVisible(true)
        }
      })

      if (vehicleList?.length > 0 && vehicleList[0]?.license_plate != '') {
        setIsVehicleTableVisible(false)
      } else {
        setIsVehicleTableVisible(true)
      }
    } else {
      setCardFormVisible(true)
      setIsVehicleTableVisible(true)
    }
  }, [EMPTY_ARRAY, partnerDetails])

  const formatAMPM = (date) => {
    var hours = new Date(date).getHours()
    var minutes = new Date(date).getMinutes()
    var ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  const loggedInUser = () => {
    const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
    // setLoginData(getuserDatafromLocal?.user)
    if (
      getuserDatafromLocal?.access_token &&
      getuserDatafromLocal?.access_token != ''
    ) {
      const signupFormData = JSON.parse(localStorage.getItem('userData'))
      setCardFormVisible(true)
      if (Object.values(signupFormData?.user)?.length > 0) {
        const loggedInUserData = signupFormData?.user
        setValue('name', loggedInUserData?.name ? loggedInUserData?.name : '')
        setValue(
          'email',
          loggedInUserData?.email ? loggedInUserData?.email : ''
        )
        setValue(
          'phone',
          loggedInUserData?.phone_number ? loggedInUserData?.phone_number : ''
        )
        //setValue('license_plate',loggedInUserData?.license_number ? loggedInUserData?.license_number  : '')
        setMobile(
          loggedInUserData?.phone_number ? loggedInUserData?.phone_number : ''
        )
      }
    } else {
      //setIsLoggedIn(false)
      setCardFormVisible(false)
    }
  }

  const newBooking = () => {
    resetFormValue()
    setPage(1)
    setBookingStatus(false)
    setBookingError(false)
    setTicketDetails(EMPTY_OBJECT)
    setValue('entry_time', new Date(entryDateTime))
    setValue('exit_time', new Date(exitDateTime))
    setIsLoading(false)
    // login details
    loggedInUser()
    // isLoggedIn() ? setCardFormVisible(false) : setCardFormVisible(true)
    if (isLoggedIn()) {

      dispatch(getCardDataList())
        .then((res) => {
          if (res?.data) {
            setCardList(res?.data?.payments)
            if (res?.data?.payments.length > 0) {
              setCardFormVisible(false)
            } else {
              setCardFormVisible(true)
            }
            // setIsLoading(false)
          }
        })
        .catch(() => {
          // setIsLoading(false)
        })
      //vehical
      dispatch(getVehicleDataList()).then((res) => {
        setVehicleList(res?.data)
        if (res?.data?.length > 0) {
          setIsVehicleTableVisible(false)
        } else {
          setIsVehicleTableVisible(true)
        }
      })

      if (vehicleList?.length > 0 && vehicleList[0]?.license_plate != '') {
        setIsVehicleTableVisible(false)
      } else {
        setIsVehicleTableVisible(true)
      }
    } else {
      setCardFormVisible(true)
      setIsVehicleTableVisible(true)
    }
    getPriceDetaitlsToggle(partnerUserId, facilityId, entryDateTime, exitDateTime)
  }

  const tabClick = (page) => {
    setPage(page)
  }

  const nextPage = () => {
    let formValue = getValues()
    if (page == 1) {
      trigger('garage')
      trigger('entry_time')
      trigger('exit_time')
    }

    if (page == 2) {
      trigger('name')
      trigger('email')
      trigger('phone')
      trigger('license_plate')
      trigger('vehicle')
    }


    if (
      page == 1 &&
      formValue?.garage &&
      formValue?.entry_time &&
      formValue?.exit_time
    ) {
      if (exitDateTime >= entryDateTime) {
        setPage(page + 1)
      } else {
        notify('error', 'Please enter valid start and end date!')
      }
    }
    if (
      page == 2 &&
      formValue?.name &&
      formValue?.email &&
      formValue?.phone &&
      !('email' in errors) &&
      !('phone' in errors)
    ) {
      clearErrors('vehicle')
      if (
        formValue?.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) &&
        formValue?.phone.length == 10 && (formValue?.vehicle || formValue?.license_plate)
      ) {
        setPage(page + 1)
      }
    }
  }

  const prePage = () => {
    setPage(page - 1)
  }

  const onSubmit = (data) => {
    if (
      data?.garage &&
      data?.entry_time &&
      data?.exit_time &&
      data?.name &&
      data?.email &&
      data?.phone
    ) {
      setIsLoading(true)
      setIsSubmit(true)
      let nonce
      if (isCardFormVisible || (data?.ccv && data?.ccv != '')) {
        let cardData = {
          name_on_card: data?.card_name ? data?.card_name : '',
          card_number: data?.card_number
            ? data?.card_number?.replace(/\D/g, '')
            : '',
          expiration_date: data?.card_expiry
            ? data?.card_expiry?.replace(/\D/g, '')
            : '',
          security_code: data?.ccv ? data?.ccv : '',
          zip_code: '201301',
        }

        nonce = EncryptionHandler(cardData)
      } else {
        nonce = ''
      }


      let garageDetails = facility?.filter(
        (v) => (v.id == data?.garage)
      )
      const description =
        'Reservation for ' +
        garageDetails[0]?.full_name +
        ' Garage begining at ' +
        dateTimeFormatefunction(entryDateTime) +
        ' lasting for ' +
        parseFloat(totalHoursLength).toFixed(2) +
        ''
      let discount_amount = discount ? parseFloat(discount).toFixed(2) : '0.00'
      let totalAmount = priceDetails.payable_amount
        ? parseFloat(
          parseFloat(priceDetails.payable_amount) - discount_amount
        ).toFixed(2)
        : '0.00'
      let tax_fee = priceDetails?.tax_fee
        ? parseFloat(priceDetails?.tax_fee).toFixed(2)
        : '0.00'
      let processing_fee = priceDetails?.processing_fee
        ? parseFloat(priceDetails?.processing_fee).toFixed(2)
        : '0.00'
      const payloadRequest = {
        warning_on_reservation: '0',
        is_opt_out: '',
        length: parseFloat(totalHoursLength).toFixed(2),
        length_of_stay: parseFloat(totalHoursLength).toFixed(2),
        processing_fee: processing_fee,
        //user_concent:userConsent ? '1':'0',
        user_consent: userConsent ? '1' : '0',
        tax_fee: tax_fee,
        vehicle_id: (isLoggedIn() && !isVehicleTableVisible) ? vehicalId : '',
        total: totalAmount,
        arrival: dateTimeFormatefunction(entryDateTime),
        is_card_req: '1',
        facility_id: data?.garage,
        warning_on_reservation_msg_email: '',
        use_bonus: '0',
        description: description,
        warning_on_reservation_msg: '',
        companyName: '',
        //  user_id: partnerDetails.brand_setting.user_id,
       // user_id: partnerDetails?.brand_setting?.user_id ? partnerDetails?.brand_setting?.user_id : partnerDetails?.partner_details?.brand_setting?.user_id,
       user_id: isLoggedIn() ? loggedInUserData()?.user?.id : '',
       
       payment_profile_id:
          isLoggedIn() && !isCardFormVisible ? paymentProfileId : '',
        coupon_threshold_price: '0',
        email: data?.email,
        phone: data?.phone,
        name: data?.name,
        nonce: nonce,
        payment_type: 'credit_card',
        savePaymentMethod: '',
        promocode: promocode,
        is_promocode_redeemed: promoCodeApply,
        redeemed_amount: discount_amount,
        booking_source: webSourceBooking,
        license_plate:
          (isLoggedIn() && !isVehicleTableVisible)
            ? vehicalId
            : data?.license_plate,
        is_license_plate: isLicensePlate ? 1 : 0,
        cruise_id: checkInGateID?checkInGateID:'',
      }
     
      const currentDate = changeTimeZone(new Date(), partnerDetails?.facilities[0]?.timezone)

     if (exitDateTime >= entryDateTime && entryDateTime >= currentDate) {
        dispatch(saveReservationPayment(payloadRequest)).then((response) => {
          const responseData = response?.data

          const url =
            'https://www.google.com/maps/place/' +
            responseData?.reservation?.facility?.geolocations?.address_1 + '/' +
            responseData?.reservation?.facility?.geolocations?.latitude +
            ',' +
            responseData?.reservation?.facility?.geolocations?.longitude
          if (responseData) {
            setTicketDetails(responseData)
            setBookingStatus(true)
            setMapURL(url)
            setPage(4)
            resetFormValue()
            notify('success', 'Booking confirmed!')
            setIsLoading(false)
            setIsSubmit(false)
            // setTicketId(responseData?.reservation?.id)
          } else {
            //resetFormValue()
            setPage(3)
            setBookingStatus(false)
            setBookingError(true)
            const errors = response?.errors
            setTicketDetails(
              errors
                ? errors?.message
                : 'Something went wrong, Please try again!'
            )
            notify(
              'error',
              errors
                ? errors?.message
                : 'Something went wrong, Please try again!'
            )
            setIsLoading(false)
            setIsSubmit(false)
          }
        })
      } else {
        setIsLoading(false)
        notify('error', 'Please enter valid start and end time!')
      }
    } else {
      notify('error', 'Please fill the required details')
      setIsLoading(false)
      setIsSubmit(false)
    }
  }

  const handleCardSelectChange = (e) => {
    clearErrors('paymentProfileIDUser')
    const { value } = e.target
    const selectedCard = cardList?.filter((card) => card?.token == value)
    setPaymentProfileId(selectedCard[0]?.token ? selectedCard[0]?.token : '')
    setValue('paymentProfileIDUser', selectedCard[0]?.token ? selectedCard[0]?.token : '')
  }

  const showCardForm = () => {
    setCardFormVisible(true)
    clearErrors('card_number')
    clearErrors('card_expiry')
    setValue('card_number', '')
    setValue('card_expiry', '')
    clearErrors('paymentProfileIDUser')
    //setFormData({ ...formData, ['card_pan']: '' })
  }

  const hideCardForm = () => {
    setCardFormVisible(false)
    //setFormData({ ...formData, ['card_pan']: '' })
  }

  //  console.log('errors',errors)
  //  console.log('getValues',getValues())
  


  const filterPassedTime = (time) => {
    const currentDate = changeTimeZone(new Date(), partnerDetails?.facilities[0]?.timezone)
    const selectedDate = time

    return currentDate.getTime() < selectedDate.getTime()
  }

  return (
    
    <div>
      {page == 3 && bookingError && (
        <div
          className="alert alert-warning mb-0 alert-dismissible fade show"
          role="alert"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-auto">
                <i className="fa fa-exclamation-triangle fa-2x"></i>
              </div>
              <div className="col">
                <b>Error!</b> {ticketDetails ? ticketDetails : '-'}
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  onClick={newBooking}
                  className="btn btn-primary"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                >
                  New Booking
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}

      <div className="container py-5 flud">
        {page != 4 && (
          <h2 className="fw-light mb-4 mt-4 text-primary">
            Book Parking at your convenience
          </h2>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          {page != 4 && (
            <div className="row">
              <div className="col-sm-8">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  {/* step 1 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={
                          page == 1
                            ? 'accordion-button'
                            : 'accordion-button collapsed'
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded={page == 1 ? true : false}
                        aria-controls="collapseOne"
                        onClick={() => tabClick(1)}
                      >
                        <h5 className="m-0">
                          Parking Details{' '}
                        </h5>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={
                        page == 1
                          ? 'accordion-collapse collapse show'
                          : 'accordion-collapse collapse'
                      }
                      data-bs-parent="#accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-body">
                        <div className="row">
                          <div className={isPermissionExist(accessPermissions, 'Checkin Gate') ? 'col-sm-6':'col-sm-4'}>
                            <div className="mb-3">
                              <label className="form-label">
                                Facility Name{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                //  onChange={(event) => handelGarage(event)}
                                name="garage"
                                type="select"
                                id="garage"
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required',
                                  },
                                }}
                                render={({ field }) => (
                                  <select
                                    autoComplete="off"
                                    className="form-select"
                                    {...field}
                                    id="garage"
                                    selected={facilityId ? facilityId : ''}
                                    onChange={(event) => handelGarage(event)}
                                  >
                                    {/* <option value="">
                                      Select Facility name
                                    </option> */}
                                    {facility?.length > 1 && (
                                      <option value="">
                                        Select Facility Name
                                      </option>
                                    )}
                                    {facility?.length > 0 && facility?.map((v, i) => {
                                      return (
                                        <option key={i} value={v.id}>
                                          {v.full_name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                )}
                              />
                              {errors?.garage && (
                                <span className="text-danger small">
                                  {errors?.garage?.message}
                                </span>
                              )}
                            </div>
                          </div>
                          
                       {isPermissionExist(accessPermissions, 'Checkin Gate') ?
                        <div className={isPermissionExist(accessPermissions, 'Checkin Gate') ? 'col-sm-6':'col-sm-4'}>
                            <div className="mb-3">
                              <label className="form-label">
                                Cruise Line{' '}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Controller
                                name="check_in_gate"
                                type="select"
                                id="check_in_gate"
                                control={control}
                                rules={{
                                  required: {
                                    value: false,
                                    message: 'This field is required',
                                  },
                                }}
                                render={({ field }) => (
                                  <select
                                    autoComplete="off"
                                    className="form-select"
                                    {...field}
                                    id="check_in_gate"
                                    selected={checkInGateID ? checkInGateID : ''}
                                    onChange={(event) => handelCheckInGate(event)}
                                  >
                                   
                                   <option value="">
                                      Select Cruise Line
                                      </option>
                                    {checkInGate?.length > 0 ? checkInGate?.map((v, i) => {
                                      return (
                                        
                                        <option key={i} value={v.id}>
                                          {v.cruise_name}
                                        </option>
                                      )
                                    }):''}
                                  </select>
                                )}
                              />
                              {errors?.check_in_gate && (
                                <span className="text-danger small">
                                  {errors?.check_in_gate?.message}
                                </span>
                              )}
                            </div>
                          </div>:''}
                          
                          <div className={isPermissionExist(accessPermissions, 'Checkin Gate') ? 'col-sm-6':'col-sm-4'}>
                            <div className="mb-3">
                              <label className="form-label">
                                Start Date / Time{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="entry_time"
                                type="text"
                                id="entry_time"
                                control={control}
                                disabled={true}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required',
                                  },
                                }}
                                render={() => (
                                  <DatePicker
                                    selected={entryDateTime}
                                    // isClearable
                                    onChange={(date) => handleEntryTime(date)}
                                    className="form-control "
                                    timeIntervals={15}
                                    autoComplete="off"
                                    id="entry_time"
                                    name="entry_time"
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    minDate={startDateTime}
                                    onKeyDown={(e) => { e.preventDefault() }}
                                    filterTime={filterPassedTime}
                                  // minTime={moment(entryDateTime).format('YYYY-MM-DD')>moment(new Date()).format('YYYY-MM-DD') ?setHours(setMinutes(entryDateTime,0), 0):setHours(setMinutes(entryDateTime.getMinutes(), moment(new Date()).format('ss')), entryDateTime.getHours())}
                                  // maxTime={setHours(setMinutes(entryDateTime, 30), 23)}
                                  />
                                )}
                              />
                              {errors?.entry_time && (
                                <span className="text-danger small">
                                  {errors?.entry_time?.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className={isPermissionExist(accessPermissions, 'Checkin Gate') ? 'col-sm-6':'col-sm-4'}>
                            <div className="mb-3">
                              <label className="form-label">
                                End Date / Time{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="exit_time"
                                type="text"
                                id="exit_time"
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required',
                                  },
                                }}
                                render={() => (
                                  <DatePicker
                                    selected={exitDateTime}
                                    onChange={(date) => handleExitTime(date)}
                                    // isClearable
                                    //onChange={(event) => field.onChange(handleCardNumberDD(event))}
                                    className="form-control"
                                    timeIntervals={15}
                                    autoComplete="off"
                                    id="exit_time"
                                    name="exit_time"
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    minDate={entryDateTime}
                                    onKeyDown={(e) => { e.preventDefault() }}
                                    filterTime={filterPassedTime}
                                  // minTime={moment(exitDateTime).format('YYYY-MM-DD')>moment(entryDateTime).format('YYYY-MM-DD') ?setHours(setMinutes(exitDateTime, 0), 0):setHours(setMinutes(entryDateTime.getMinutes(), moment(entryDateTime).format('ss')), entryDateTime.getHours())}
                                  // maxTime={setHours(setMinutes(entryDateTime, 30), 23)}
                                  />
                                )}
                              />
                              {errors?.exit_time && (
                                <span className="text-danger small">
                                  {errors?.exit_time?.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 text-end">
                          <button
                            className="btn btn-primary px-4  text-white"
                            onClick={nextPage}
                            disabled={isLoading}
                            type="button"
                          >
                            <span className="ms-2">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* step 2 */}
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={
                          page == 2
                            ? 'accordion-button'
                            : 'accordion-button collapsed'
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded={page == 2 ? true : false}
                        aria-controls="collapseTwo"
                        onClick={() => tabClick(2)}
                      >
                        <h5 className="m-0">Customer Details</h5>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={
                        page == 2
                          ? 'accordion-collapse collapse show'
                          : 'accordion-collapse collapse'
                      }
                      data-bs-parent="#accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-body">
                        <div className="row ">
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="name"
                                type="text"
                                id="name"
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required',
                                  },
                                }}
                                render={({ field }) => (
                                  <MaskedInput
                                    autoComplete="off"
                                    className="form-control"
                                    {...field}
                                    id="name"
                                    placeholder="Name"
                                  />
                                )}
                              />
                              {errors?.name && (
                                <span className="text-danger small">
                                  {errors?.name?.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Email Address{' '}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="email"
                                type="text"
                                id="email"
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required',
                                  },

                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message:
                                      'Please enter a valid Email address',
                                  },
                                }}
                                render={({ field }) => (
                                  <MaskedInput
                                    autoComplete="off"
                                    className="form-control"
                                    {...field}
                                    id="email"
                                    placeholder="Email Address"
                                  //onChange={(event) => field.onChange(handleInputChange(event))}
                                  />
                                )}
                              />
                              {errors?.email && (
                                <span className="text-danger small">
                                  {errors?.email?.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Phone Number{' '}
                                <span className="text-danger">*</span>
                              </label>
                              {/* <input type="number" className="form-control" /> */}
                              <Controller
                                name="phone"
                                type="text"
                                id="phone"
                                control={control}
                                //onChange={handlePhoneNumber}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'This field is required',
                                  },
                                  minLength: {
                                    value: 10,
                                    message: 'Minimum 10 characters allowed',
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: 'Maximum 10 characters allowed',
                                  },
                                }}
                                render={({ field }) => (
                                  //console.log(field, 'field')
                                  <MaskedInput
                                    maskGenerator={phoneMask}
                                    autoComplete="off"
                                    maxLength="14"
                                    {...field}
                                    value={mobile ? mobile : ''}
                                    title="Phone number should be 10 digit numeric"
                                    className="form-control"
                                    isValidating={true}
                                    onInvalid={(e) =>
                                      e.target.setCustomValidity(
                                        'Please enter a valid phone number'
                                      )
                                    }
                                    onInput={(e) =>
                                      e.target.setCustomValidity('')
                                    }
                                    placeholder="Phone Number"
                                  //onChange={(e) =>handlePhoneNumber(e)}

                                  // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                                  />
                                )}
                              />

                              {errors?.phone && (
                                <span className="text-danger small">
                                  {errors?.phone?.message}
                                </span>
                              )}
                            </div>
                          </div>

                          {/* vehical */}
                          {isLoggedIn() && vehicleList?.length > 0 && <hr />}

                          {isLoggedIn() && vehicleList?.length > 0 && (
                            <div className="row ">
                              {vehicleList?.length > 0 && (
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <Controller
                                      // onChange={(event) =>
                                      //   handleVehicleChange(event)
                                      // }
                                      name="vehicle"
                                      type="select"
                                      id="vehicle"
                                      control={control}
                                      rules={{
                                        required: {
                                          value: !isVehicleTableVisible,
                                          message: 'This field is required',
                                        },
                                      }}
                                      render={({ field }) => (
                                        <select
                                          disabled={isVehicleTableVisible}
                                          autoComplete="off"
                                          className="form-select"
                                          {...field}
                                          id="vehicle"
                                          selected={vehicalId}
                                          onChange={(event) =>
                                            handleVehicleChange(event)
                                          }
                                        >
                                          <option value="">
                                            Select Vehicle
                                          </option>

                                          {vehicleList?.map(
                                            (vehicle, index) => {
                                              return (
                                                <option
                                                  value={
                                                    vehicle?.license_plate_number
                                                  }
                                                  key={index}
                                                >
                                                  {
                                                    vehicle?.license_plate_number
                                                  }
                                                </option>
                                              )
                                            }
                                          )}
                                        </select>
                                      )}
                                    />
                                    {errors?.vehicle && (
                                      <span className="text-danger small">
                                        {errors?.vehicle?.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="col-sm-6 mb-3">
                                {vehicleList?.length > 0 && (
                                  <span className="badge badge-primary text-dark fw-bold me-2">
                                    OR
                                  </span>
                                )}{' '}
                                {vehicleList?.length > 0 && (
                                  <button
                                    type='button'
                                    className="btn btn-primary"
                                    onClick={showVehicleTable}
                                  >
                                    <i className="fa fa-plus"></i> Add New
                                    vehicle
                                  </button>
                                )}
                              </div>
                            </div>
                          )}

                          {isVehicleTableVisible ? (
                            <div className="col-sm-6">
                              <div className="mb-3 ">
                                <label className="form-label">
                                  License Plate
                                  <span className="text-danger">*</span>
                                </label>
                                <div className={showVehicleTable && isLoggedIn() ? 'input-group' : ''}>
                                  <Controller
                                    name="license_plate"
                                    type="text"
                                    id="license_plate"
                                    control={control}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: 'This field is required',
                                      },
                                      minLength: {
                                        value: 3,
                                        message: 'Minimum 3 characters allowed',
                                      },
                                      maxLength: {
                                        value: 10,
                                        message:
                                          'Maximum 10 characters allowed',
                                      },
                                    }}
                                    render={({ field }) => (
                                      <MaskedInput
                                        autoComplete="off"
                                        {...field}
                                        id="license_plate"
                                        title="Licence plate should be 10 digit"
                                        className="form-control"
                                        placeholder="Licence Plate"
                                        maxLength="10"
                                      // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                                      />
                                    )}
                                  />
                                  {errors?.license_plate && (
                                    <span className="text-danger small">
                                      {errors?.license_plate?.message}
                                    </span>
                                  )}

                                  {(isLoggedIn() && vehicleList?.length > 0) ? (
                                    <button
                                      type="button"
                                      className="btn bg-danger"
                                      onClick={() => removeVehicleMake()}
                                    >
                                      <i className="fa fa-minus"></i>
                                    </button>
                                  ) : null}
                                </div>

                              </div>

                              <div className=" my-2">
                                {(isLoggedIn()) ?
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="isLicensePlate"
                                      name="isLicensePlate"
                                      onClick={() =>
                                        setIsLicensePlate(!isLicensePlate)
                                      }
                                      checked={isLicensePlate}
                                    />

                                    <small>Set as default</small>
                                  </div> : ''}
                              </div>
                            </div>

                          ) : (
                            ''
                          )}

                          {/* </div> */}
                          {/* end */}
                        </div>

                        <div className="mb-3">
                          <div className="row">
                            <div className="col-6">
                              <button
                                className="btn btn-secondary  w-100 text-white"
                                onClick={prePage}
                                type="button"
                              >
                                Previous
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className="btn btn-primary  w-100 text-white"
                                onClick={nextPage}
                                type="button"
                                disabled={isLoading}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* step 3 */}

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={
                          page == 3
                            ? 'accordion-button'
                            : 'accordion-button collapsed'
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded={page == 3 ? true : false}
                        aria-controls="collapseThree"
                        onClick={() => tabClick(3)}
                      >
                        <h5 className="m-0">{paymentDisable ? 'Reservation Book' : 'Payment Details'}</h5>
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className={
                        page == 3
                          ? 'accordion-collapse collapse show'
                          : 'accordion-collapse collapse'
                      }
                      data-bs-parent="#accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-body">
                        {/* save card */}
                        {!paymentDisable && <>

                          {isLoggedIn() && (
                            <div className="row">
                              {cardList?.length > 0 && (
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Select Card{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                      //  onChange={(event) => handleCardSelectChange(event)}
                                      name="paymentProfileIDUser"
                                      type="select"
                                      id="paymentProfileIDUser"
                                      control={control}
                                      rules={{
                                        required: {
                                          value: isCardFormVisible ? false : true,
                                          message: 'This field is required',
                                        },
                                      }}
                                      render={({ field }) => (
                                        <select
                                          disabled={isCardFormVisible}
                                          autoComplete="off"
                                          className="form-select"
                                          {...field}
                                          id="paymentProfileIDUser"
                                          selected={paymentProfileId}
                                          onChange={(event) =>
                                            handleCardSelectChange(event)
                                          }
                                        >
                                          <option value="">Select Card</option>
                                          {cardList?.map((card, index) => {
                                            return (
                                              <option
                                                value={card?.token}
                                                selected={
                                                  card?.token == paymentProfileId
                                                }
                                                key={index}
                                              >{`XXXX XXXX XXXX ${card?.card_last_four.slice(
                                                0,
                                                4
                                              )} (${card?.expiry.slice(
                                                0,
                                                2
                                              )}/${card?.expiry.slice(
                                                2,
                                                4
                                              )})`}</option>
                                            )
                                          })}
                                        </select>
                                      )}
                                    />
                                    {errors?.paymentProfileIDUser && (
                                      <span className="text-danger small">
                                        {errors?.paymentProfileIDUser?.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                              {cardList?.length > 0 && !isCardFormVisible ? (
                                <div className="col-sm-6  mt-4 px-2 py-2">
                                  {cardList?.length > 0 && (
                                    <span className="badge badge-primary text-dark fw-bold me-2">
                                      OR
                                    </span>
                                  )}
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={showCardForm}
                                  >
                                    <i className="fa fa-plus"></i> Add New Card
                                  </button>
                                </div>
                              ) : (
                                cardList?.length > 0 && (
                                  <div className="col-sm-6 mt-4 px-2 py-2">
                                    <span className="badge badge-primary text-dark fw-bold me-2">
                                      OR
                                    </span>
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={hideCardForm}
                                    >
                                      Select Existing Card
                                    </button>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {/* end */}

                          {(cardList?.length < 1 || isCardFormVisible) && (
                            <div className="mb-3">
                              <div className="mb-3">
                                <label className="form-label">
                                  Name on Card{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                {/* <input type="text" className="form-control" /> */}
                                <Controller
                                  name="card_name"
                                  type="text"
                                  id="card_name"
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: 'This field is required',
                                    },
                                  }}
                                  render={({ field }) => (
                                    <MaskedInput
                                      autoComplete="off"
                                      className="form-control"
                                      {...field}
                                      id="card_name"
                                      placeholder="Name on Card"
                                    />
                                  )}
                                />
                                {errors?.card_name && (
                                  <span className="text-danger small">
                                    {errors?.card_name?.message}
                                  </span>
                                )}
                              </div>

                              <div className="mb-3">
                                <label className="form-label">
                                  Card Number{' '}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="d-block position-relative ">
                                  {/* <input type="text" className="w-100 p-1 border-0" />{' '} */}
                                  <Controller
                                    name="card_number"
                                    type="text"
                                    id="card_number"
                                    control={control}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: 'This field is required',
                                      },
                                      maxLength: {
                                        value: 19,
                                        message: 'Maximum 16 characters allowed',
                                      },
                                    }}
                                    render={({ field }) => (
                                      <MaskedInput
                                        maskGenerator={cardnumbermask}
                                        autoComplete="off"
                                        pattern="[0-9 ]{18,19}"
                                        maxLength='19'
                                        {...field}
                                        id="card_number"
                                        title="Card Number should be 15-16 digit"
                                        className="form-control"
                                        onInvalid={(e) =>
                                          e.target.setCustomValidity(
                                            'Please enter a valid card number'
                                          )
                                        }
                                        onInput={(e) =>
                                          e.target.setCustomValidity('')
                                        }
                                        placeholder="Card Number"
                                      />
                                    )}
                                  />

                                  <i className=" fa text-primary fa-credit-card me-2 position-absolute top-50 end-0 translate-middle-y"></i>
                                </div>
                                {errors?.card_number && (
                                  <span className="text-danger small">
                                    {errors?.card_number?.message}
                                  </span>
                                )}
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Expiry Date{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                    {/* <input type="text" className="form-control" /> */}
                                    <Controller
                                      name="card_expiry"
                                      type="text"
                                      id="card_expiry"
                                      control={control}
                                      // rules={{ required: (cardNumber.length === 4 && cardExpiry.length !== 4) ? true : false, maxLength: 5 }}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: 'This field is required',
                                        },
                                        minLength: {
                                          value: 4,
                                          message: 'Minimum 4 characters allowed',
                                        },
                                        maxLength: {
                                          value: 4,
                                          message: 'Maximum 4 characters allowed',
                                        },
                                      }}
                                      render={({ field }) => (
                                        <MaskedInput
                                          maskGenerator={expirymask}
                                          autoComplete="off"
                                          pattern="(?:0?[1-9]|1[0-2])/[0-9 ]{2}"
                                          maxLength="5"
                                          {...field}
                                          id="card_expiry"
                                          className="form-control"
                                          onInvalid={(e) =>
                                            e.target.setCustomValidity(
                                              'Please enter MM/YY format card expiry date'
                                            )
                                          }
                                          onInput={(e) =>
                                            e.target.setCustomValidity('')
                                          }
                                          placeholder="MM/YY"
                                        // onChange={(event) => field.onChange(handleCardExpiry(event))}
                                        />
                                      )}
                                    />
                                    {errors?.card_expiry && (
                                      <span className="text-danger small">
                                        {errors?.card_expiry?.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      CVV <span className="text-danger">*</span>
                                    </label>
                                    <Controller
                                      name="ccv"
                                      type="text"
                                      id="ccv"
                                      control={control}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: 'This field is required',
                                        },
                                        minLength: {
                                          value: 3,
                                          message: 'Minimum 3 characters allowed',
                                        },
                                        maxLength: {
                                          value: 4,
                                          message: 'Maximum 4 characters allowed',
                                        },
                                      }}
                                      render={({ field }) => (
                                        <MaskedInput
                                          autoComplete="off"
                                          type="password"
                                          // pattern="([1-9]|1[0-2])/[0-9 ]{2}"
                                          maxLength="4"
                                          minLength="3"
                                          className="form-control"
                                          {...field}
                                          id="ccv"
                                          placeholder="CVV"
                                        />
                                      )}
                                    />
                                    {errors?.ccv && (
                                      <span className="text-danger small">
                                        {errors?.ccv?.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className=" my-2">
                                {(isLoggedIn()) && <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="userConsent"
                                    name="userConsent"
                                    onClick={() => setUserConsent(!userConsent)}
                                    checked={userConsent}
                                  />
                                  <small>Save card</small>
                                </div>}
                              </div>
                            </div>
                          )}
                        </>}
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-6">
                              <button
                                className="btn btn-secondary  w-100 text-white"
                                onClick={prePage}
                                disabled={isLoading}
                                type="button"
                              >
                                Previous
                              </button>
                            </div>
                            <div className="col-6">
                              <div className="col">
                                <button
                                  type="submit"
                                  name="submit"
                                  className="btn btn-primary  w-100 text-white"
                                  onClick={handleSubmit}
                                  disabled={isLoading}
                                >
                                  {/* page==3 ? isLoading :true  */}
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span>{paymentDisable ? 'Confirm & Book' : 'Confirm & Pay'}</span>
                                    {page == 3 && (
                                      <span className="ms-2">
                                        {isLoading && isSubmit ? (
                                          <Circles
                                            height="20"
                                            width="20"
                                            style={{ display: 'inline-block' }}
                                            color="#ffffff"
                                            ariaLabel="circles-loading"
                                            visible={true}
                                          />
                                        ) : null}
                                      </span>
                                    )}
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end */}
                </div>
              </div>
              {/* cart details */}
              <div className="col-sm-4">
                <div className="card  shadow-sm border-0">
                  <div className="card-body">
                    <div className="mb-3">
                      <label className="form-label">Apply Promo Code</label>
                      <div className="input-group ">
                        <Controller
                          name="promo_code"
                          type="text"
                          id="promo_code"
                          control={control}
                          rules={{
                            required: {
                              value: false,
                              message: 'This field is required',
                            },
                          }}
                          render={({ field }) => (
                            <MaskedInput
                              autoComplete="off"
                              {...field}
                              id="promo_code"
                              title="Enter Promo Code"
                              className="form-control"
                              placeholder="Enter Promo Code"
                            // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                            />
                          )}
                        />
                        {promoCodeApply ? <button
                          className="btn btn-danger text-white"
                          type="button"
                          onClick={removePromocode}
                        >
                          Remove
                        </button> : <button
                          className="btn btn-primary text-white"
                          type="button"
                          onClick={applyPromocode}
                        >
                          Apply
                        </button>
                        }
                      </div>
                    </div>
                    <div className="mb-3">
                      <b className="text-secondory">Reservation Details</b>
                    </div>
                    <div className="mb-3">
                      <h4>{facilityName}</h4>
                    </div>
                  </div>
                  <div className="mb-3 border border-end-0 border-start-0 p-2 bg-light">
                    <div className="row">
                      <div className="col text-center">
                        <small className="mb-2 d-block">
                          Entry Date / Time
                        </small>
                        <h6>
                          {/* en-CA */}
                          {entryDateTime
                            ? ('0' + (entryDateTime.getMonth() + 1)).slice(-2) +
                            '-' +
                            entryDateTime.getDate() +
                            '-' +
                            entryDateTime.getFullYear()
                            : '-'}
                          <br />
                          {entryDateTime ? formatAMPM(entryDateTime) : '-'}
                        </h6>
                      </div>
                      <div className="col-auto">
                        <div
                          className="bg-primary"
                          style={{ height: '100%', width: '2px' }}
                        ></div>
                      </div>
                      <div className="col-auto border-right border-priamry"></div>
                      <div className="col text-center">
                        <small className="mb-2 d-block">Exit Date / Time</small>
                        <h6>
                          {exitDateTime
                            ? (
                              '0' +
                              (new Date(exitDateTime).getMonth() + 1)
                            ).slice(-2) +
                            '-' +
                            new Date(exitDateTime).getDate() +
                            '-' +
                            new Date(exitDateTime).getFullYear()
                            : '-'}
                          <br />
                          {exitDateTime ? formatAMPM(exitDateTime) : '-'}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 border-bottom">
                    <div className="d-flex justify-content-between mb-1 ">
                      <p className="m-0">Name</p>{' '}
                      <p style={{ wordBreak: 'break-all' }} className="m-0">
                        {getValues()?.name ? getValues()?.name : '-'}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-1 ">
                      <p className="m-0">Email ID</p>{' '}
                      <p style={{ wordBreak: 'break-all' }} className="m-0">
                        {getValues()?.email ? getValues()?.email : '-'}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-1 ">
                      <p className="m-0">Phone No</p>{' '}
                      <p style={{ wordBreak: 'break-all' }} className="m-0">
                        {getValues()?.email ? getValues()?.phone : '-'}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-1 ">
                      <p className="m-0">License Plate</p>{' '}
                      <p style={{ wordBreak: 'break-all' }} className="m-0">
                        {getValues()?.license_plate
                          ? getValues()?.license_plate
                          : '-'}
                      </p>
                    </div>
                  </div>
                  {loaderPrice ?
                    <span className='mx-auto my-5 d-block' style={{ width: '30px' }}><Circles
                      height="20"
                      width="20"
                      style={{ display: 'inline-block' }}
                      color="#000"
                      ariaLabel="circles-loading"
                      visible={true}
                    /></span> :
                    priceDetails?.parking_amount != 'N/A' ? (
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-1 ">
                          <p className="m-0">Parking Amount</p>{' '}
                          <p className="m-0">
                            <b>
                              $
                              {priceDetails?.parking_amount
                                ? priceDetails?.parking_amount
                                : '0.00'}
                            </b>
                          </p>
                        </div>
                        {priceDetails?.tax_fee > 0 && (
                          <div className="d-flex justify-content-between mb-1 ">
                            <p className="m-0"> Tax</p>{' '}
                            <p className="m-0">
                              <b>
                                $
                                {priceDetails?.tax_fee
                                  ? priceDetails?.tax_fee
                                  : '0.00'}
                              </b>
                            </p>
                          </div>
                        )}
                        {priceDetails?.processing_fee > 0 && (
                          <div className="d-flex justify-content-between mb-1 ">
                            <p className="m-0"> Processing Fee</p>{' '}
                            <p className="m-0">
                              <b>
                                $
                                {priceDetails?.processing_fee
                                  ? priceDetails?.processing_fee
                                  : '0.00'}
                              </b>
                            </p>
                          </div>
                        )}
                        {promoCodeApply && (
                          <div className="d-flex justify-content-between mb-1 ">
                            <p className="m-0"> Promo Code</p>{' '}
                            <p className="m-0">
                              <b>- ${parseFloat(discount).toFixed(2)}</b>
                            </p>
                          </div>
                        )}

                        <div className="d-flex border-top justify-content-between mb-1 pt-2 ">
                          <h4 className="font-weight-bold text-primary m-0 ">
                            Payable Amount{' '}
                          </h4>
                          {promoCodeApply &&
                            priceDetails?.payable_amount >= discount ? (
                            <h4 className="font-weight-bold text-primary ">
                              $
                              {priceDetails?.payable_amount
                                ? parseFloat(Number(priceDetails?.payable_amount) -
                                  Number(discount)).toFixed(2)
                                : '0.00'}{' '}
                            </h4>
                          ) : (
                            <h4 className="font-weight-bold text-primary ">
                              $
                              {priceDetails?.payable_amount
                                ? priceDetails?.payable_amount
                                : '0.00'}{' '}
                            </h4>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-1 ">
                          <p className="m-0">Parking Amount</p>{' '}
                          <p className="m-0">
                            <b>{priceDetails?.parking_amount}</b>
                          </p>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )}

          {page == 4 && (
            <div className="card shadow-lg  border-0  mt-5 p-5">
              {bookingStatus && ticketDetails?.ref_id ? (
                <div className="row justify-content-center mb-3">
                  <div className="col-sm-3">
                    <h1 className="text-center text-success mb-2">
                      <i className="fa-regular fa-circle-check fa-2x"></i>
                    </h1>
                    <h4 className="text-center">Thank You for Booking</h4>
                    <p className="text-center">
                      {(isLoggedIn()) ? 'Your e-receipt details has been sent to the registered mobile Phone number and Email Address ' : 'Your e-receipt details has been sent to your Phone number and Email Address'}

                    </p>
                  </div>
                  <div className="col-sm-6">
                    <div className="border  p-3 border-dark">
                      <div className="row  ">
                        <div className="col-auto">
                          <QRCodeSVG
                            value={ticketDetails?.reservation?.ticketech_code}
                            //value={`${getConfigDetails()?.adminUrl}/admin/tickets?ticket_number=${ticketDetails?.reservation?.ticketech_code}`}
                            width="100px"
                            height="100px"
                          />
                        </div>
                        <div className="col">
                          <p className="mb-1">
                            Ticket Id:{' '}
                            {ticketDetails?.reservation?.ticketech_code}
                          </p>
                          <p className="mb-1">
                            Start Date/Time:{' '}
                            {ticketDetails?.reservation?.formatted_start_date_time}
                          </p>
                          <p className="mb-1">
                            End Date/Time:{' '}
                            {ticketDetails?.reservation?.formatted_end_date_time}
                          </p>
                          <p className="mb-0">
                            <b>
                              {ticketDetails?.reservation?.facility?.full_name}
                              {ticketDetails?.reservation?.cruise_name ? `(${ticketDetails?.reservation?.cruise_name })`:''}
                              
                            </b>
                          </p>
                          {ticketDetails?.reservation?.facility?.geolocations
                            ?.city ?
                            <p className="mb-0">
                              Entrance:
                              {
                                ticketDetails?.reservation?.facility?.geolocations
                                  ?.city
                              }
                            </p> : ''}

                          <a
                            target="_blank"
                            href={mapURL}
                            className="btn btn-link p-0"
                            rel="noreferrer"
                          >
                            Get Directions{' '}
                            <i className="fa-solid fa-diamond-turn-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex mt-3">
                      <a
                        className="btn btn-primary me-2 w-100"
                        onClick={() =>
                          handleDownloadTicket(
                            ticketDetails?.reservation?.id,
                            ticketDetails?.reservation?.ticketech_code
                          )
                        }
                        disabled={loaderTicket}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          Download Ticket &nbsp;
                          <i className="fa fa-download"></i>
                          <span className="ms-2">
                            {loaderTicket ? (
                              <Circles
                                height="20"
                                width="20"
                                style={{ display: 'inline-block' }}
                                color="#ffffff"
                                ariaLabel="circles-loading"
                                visible={true}
                              />
                            ) : null}
                          </span>
                        </div>
                      </a>
                      <button
                        className="btn btn-primary   w-100 text-white"
                        onClick={newBooking}
                      >
                        New Booking
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

responsiveReservation.propTypes = {
  dispatch: PropTypes.func,
  loader: PropTypes.bool,
  loaderSms: PropTypes.bool,
  loaderDownload: PropTypes.bool,
  notify: PropTypes.func,
  partnerDetails: PropTypes.object,
}

responsiveReservation.defaulProps = {
  dispatch: noop,
  loader: false,
  notify: noop,
  partnerDetails: EMPTY_OBJECT,
}

function mapStateToProps({ userDetailsReducer }) {
  return {
    loader: userDetailsReducer?.loader,
    loaderSms: userDetailsReducer?.loaderSms,
    loaderDownload: userDetailsReducer?.loaderDownload,
  }
}
export default connect(mapStateToProps)(responsiveReservation)
