import pickBy from 'lodash/pickBy'
import { STORAGE_KEY } from '../constants'
import {
  DAYS,
  EVENT_TYPES,
  EVENT_TYPES_VALUES,
  EVENT_TYPES_VALUES_LOGS,
  MONTHNAME,
  MONTH_NAMES,
  validCard,
  validPassword,
  validPhone,
} from '../constants/globalConstants'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import { getConfigDetails } from '../services/config'
import Resizer from 'react-image-file-resizer'
import master from '../assets/img/mastercard.png'
import visa from '../assets/img/visa_credit.png'
import discover from '../assets/img/discover_card.png'
import diner from '../assets/img/diners_card.png'
import amex from '../assets/img/amax_card.png'
import jcb from '../assets/img/jcb_hr.png'
import blank from '../assets/img/blank_credit_card.png'
import masterimg from '../assets/img/master_img.png'
import parkingpayments from '../assets/img/parkingpayments.png'
import townsend from '../assets/img/townsend.png'
import jordanImage from '../assets/img/intrapark_blue1bg.png'
import breezeImg from '../assets/img/logo-breeze.png'
import colonialImg from '../assets/img/logo-colonial.png'
import logos from '../assets/img/logos.svg'

let userAgent = navigator.userAgent || navigator.vendor
const iOS = (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  ? true : false

//   Sat Apr 01 2023 17:05:00 GMT+0530 (India Standard Time)
export const formatDate = (date) => {
  let date_final
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    date_final = MONTH_NAMES[newDate.getMonth()] + ' ' + newDate.getDate()
  } else {
    let format_date = new Date(date)
    date_final =
      MONTH_NAMES[format_date.getMonth()] + ' ' + format_date.getDate()
  }
  return date_final
}

export const formatDateDays = (date) => {
  let date_final
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    date_final = DAYS[newDate.getDay()]
  } else {
    let format_date = new Date(date)
    date_final = DAYS[format_date.getDay()]
  }
  return date_final
}

export const formatAMPM = (date) => {
  let strTime
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    let hours = newDate.getHours()
    let minutes = newDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes ? (minutes < 10 ? '0' + minutes : minutes) : '00'
    strTime = hours + ':' + minutes + ' ' + ampm
  } else {
    let hours = new Date(date).getHours()
    let minutes = new Date(date).getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes ? (minutes < 10 ? '0' + minutes : minutes) : '00'
    strTime = hours + ':' + minutes + ' ' + ampm
  }
  return strTime
}

export const dateFormatter = (date, format) => moment(date).format(format)
// export const dateFormatterMonth = (date, format) => moment(date).format(format)

export function formatMonthYear(date) {
  let date_final
  if (iOS) {
    let arr = date.split(/[- :]/)
    const newDate = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    )
    date_final =
      MONTHNAME[newDate.getMonth()] + ' ' + newDate.getFullYear()
  } else {
    let format_date = new Date(date)
    date_final =
      MONTHNAME[format_date.getMonth()] + ' ' + format_date.getFullYear()
  }

  return date_final
}



export const getEventType = (event) => {
  switch (event) {
    case EVENT_TYPES.EVENT:
      return EVENT_TYPES_VALUES.EVENT
    case EVENT_TYPES.BUNDLE_PASS:
      return EVENT_TYPES_VALUES.BUNDLE_PASS
    case EVENT_TYPES.PARTY_PASS:
      return EVENT_TYPES_VALUES.PARTY_PASS
    case EVENT_TYPES.SIMPLE:
      return EVENT_TYPES_VALUES.SIMPLE
    default:
      return EVENT_TYPES_VALUES.EVENT
  }
}
// export const getEventTypeById = (event_id, log) => {
//   switch (event_id) {
//     case `${getConfigDetails().FULL_SEASON}`:
//       return log ? EVENT_TYPES_VALUES_LOGS.BUNDLE_PASS : EVENT_TYPES_VALUES.BUNDLE_PASS
//     case `${getConfigDetails().HALF_SEASON}`:
//       return  log ? EVENT_TYPES_VALUES_LOGS.PARTY_PASS : EVENT_TYPES_VALUES.PARTY_PASS
//     case `${getConfigDetails().SIX_PACK}`:
//       return log ? EVENT_TYPES_VALUES_LOGS.SIMPLE : EVENT_TYPES_VALUES.SIMPLE
//     default:
//       return log ? EVENT_TYPES_VALUES_LOGS.EVENT : EVENT_TYPES_VALUES.EVENT
//   }
// }

export const getEventTypeByPassType = (pass_type, is_full_season, log) => {
  // switch (event_id) {
  if (pass_type == EVENT_TYPES?.BUNDLE_PASS && is_full_season == 1) {
    return log ? EVENT_TYPES_VALUES_LOGS.BUNDLE_PASS : EVENT_TYPES_VALUES.BUNDLE_PASS
  } else if (pass_type == EVENT_TYPES?.BUNDLE_PASS && is_full_season != 1) {
    return log ? EVENT_TYPES_VALUES_LOGS.PARTY_PASS : EVENT_TYPES_VALUES.PARTY_PASS
  } else if (pass_type == EVENT_TYPES?.PARTY_PASS) {
    return log ? EVENT_TYPES_VALUES_LOGS.SIMPLE : EVENT_TYPES_VALUES.SIMPLE
  } else {
    return log ? EVENT_TYPES_VALUES_LOGS.EVENT : EVENT_TYPES_VALUES.EVENT
  }
}

export const getEventCount = (eventId) => {
  // const halfSeasonEvents = localStorage.getItem('userChooseEvents')
  const eventDetails = JSON.parse(localStorage.getItem(EVENT_TYPES?.EVENT))

  switch (eventId) {
    case getConfigDetails().HALF_SEASON:
      return eventDetails?.all_event?.length / 2
    case getConfigDetails().FULL_SEASON:
      return eventDetails?.all_event?.length
    case getConfigDetails().SIX_PACK:
      return 6
    default:
      return 1
  }
}

export const getSanitizedValues = (values) =>
  pickBy(values, (value) => {
    const expression = !(
      value === 0 ||
      value === '' ||
      value === undefined ||
      value === null ||
      Number.isNaN(value) ||
      value?.length === 0
    )
    return expression
  })

const setLocalStorage = (userData) => {
  userData.map((data) => {
    localStorage.setItem(data.key, data.value)
  })
}

export const getLoginDetailFromSession = () =>
  localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN)

export const getBearerToken = () => localStorage.getItem(STORAGE_KEY.ID_TOKEN)

export const setLoginDetailInSession = (loggedInUserData) => {
  const userData = [
    {
      key: STORAGE_KEY.ACCESS_TOKEN,
      value: loggedInUserData?.AccessToken,
    },
    {
      key: STORAGE_KEY.REFRESH_TOKEN,
      value: loggedInUserData?.RefreshToken,
    },
    {
      key: STORAGE_KEY.ID_TOKEN,
      value: loggedInUserData?.IdToken,
    },
    {
      key: STORAGE_KEY.TOKEN_TYPE,
      value: loggedInUserData?.TokenType,
    },
  ]
  setLocalStorage(userData)
}

export function isLoggedIn() {
  const userDataFromStorage = localStorage.getItem('userData') ? localStorage.getItem('userData') : '{}'
  const userData = JSON.parse(userDataFromStorage)
  const access_token = userData?.access_token
  if (access_token && access_token != '') {
    return true
  } else {
    return false
  }
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length
}

export function getAllowedRoutes(routes) {
  const role = localStorage.getItem('userRole')
  return routes.filter(({ permission }) => {
    if (!permission) return true
    else if (!isArrayWithLength(permission)) return true
    else return permission.indexOf(role) !== -1
  })
}

export function classes() {
  return [...arguments].join(' ')
}

export const clearSessionDetails = () => {
  localStorage.clear()
}

export const restrictInputValues = (event) => {
  if (!`${event.target.value}${event.key}`.match(/^[0-9]{0,6}$/)) {
    // block the input if result does not match
    event.preventDefault()
    event.stopPropagation()
    return false
  }
}

export const restrictSpecialChar = (e) => {
  const result = (e.key == '!' || e.key == '@' || e.key == '#' || e.key == '$' || e.key == '%' || e.key == '^'
    || e.key == '&' || e.key == '~' || e.key == '`' || e.key == '*' || e.key == '(' || e.key == ')' || e.key == '_'
    || e.key == '=' || e.key == '+' || e.key == '[' || e.key == ']' || e.key == '{' || e.key == '}' || e.keyCode == 220
    || e.key == '|' || e.key == ':' || e.key == ';' || e.keyCode == 222 || e.key == ' ' || e.key == '?' || e.key == '.' || e.key == ',' || e.key == '>' || e.key == '<')
  if (result) {
    e.preventDefault()
  } else {
    return result
  }
}

export const getEventLogName = (pass_type, is_full_season) => `MAPCO - BOOK NOW - ${getEventTypeByPassType(pass_type, is_full_season, 'log')}`


export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return pattern.test(email)
}

export const validatePhone = (phone) => {
  // eslint-disable-next-line no-useless-escape
  return validPhone.test(phone)
}

export const validatePassword = (pwd) => {
  // eslint-disable-next-line no-useless-escape
  return validPassword.test(pwd)
}
export const matchPassword = (pwd, cpwd) => {
  // eslint-disable-next-line no-useless-escape
  return pwd == cpwd
}

export const validateCardNumber = (card) => {
  // eslint-disable-next-line no-useless-escape
  return validCard.test(card)
}

export const fileChangedHandler = (event, fileId, setIdProof, idProof, fileWidth, fileHeight, setIdErrProof) => {
  let fileInput = false
  let file = document.getElementById(fileId).files[0]

  fileInput = file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif')
  if (fileInput) {
    try {
      Resizer.imageFileResizer(
        file,
        fileWidth,
        fileHeight,
        'PNG', // fileType(JPEG, PNG,WEBP)
        100,  // quality compression(for jpeg)
        0, // rotation(0-100)
        (uri) => {
          new Promise((resolve, reject) => {
            const img = new Image()
            // the following handler will fire after a successful loading of the image
            img.onload = () => {
              const { naturalWidth: width, naturalHeight: height } = img
              resolve({ width, height })
              if (fileId == 'front') {
                setIdProof({ ...idProof, ...{ ['front']: uri } })
                setIdErrProof({ ...idProof, ...{ ['front']: uri } })
              } else {
                setIdProof({ ...idProof, ...{ ['back']: uri } })
                setIdErrProof({ ...idProof, ...{ ['back']: uri } })
              }
            }
            // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
            img.onerror = () => {
              reject('There was some problem with the image.')
            }
            img.src = URL.createObjectURL(uri)
          })
        },
        'file' // outputType( base64, blob or file)
      )
    } catch (err) {
      // setIdProof({fileId : ''})
    }
  } else {
    // setIdProof({fileId : ''})
  }
}

export const encryptionHandler = (d) => {
  var nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code
  var CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
        iv: '',
        s: ''
      }
      if (cipherParams.iv)
        j.iv = cipherParams.iv.toString()
      if (cipherParams.salt)
        j.s = cipherParams.salt.toString()
      return JSON.stringify(j)
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr)
      var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
      if (j.iv)
        cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
      if (j.s)
        cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
      return cipherParams
    }
  }
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=', { format: CryptoJSAesJson }).toString()
  return encrypted
}

export const phoneMasker = (phone) => {

  if (phone && phone.length >= 10) {
    var USNumber = phone.match(/(\d{3})(\d{3})(\d{4})/)
    return USNumber = '(' + USNumber[1] + ') ' + USNumber[2] + '-' + USNumber[3]
  } else {
    return '-'
  }

}
export const formattedExpiry = (expiry) => {
  return expiry.slice(0, 2) + '/' + expiry.slice(2)
}

export const getCardIcon = (type) => {

  const cardType = type?.toLowerCase()

  switch (cardType) {
    case 'visa':
      return visa
    case 'vs':
      return visa
    case 'mastercard':
      return master
    case 'mastercardpng':
      return masterimg
    case 'mc':
      return master
    case 'm/c':
      return master
    case 'discover':
      return discover
    case 'dinersclub':
      return diner
    case 'americanexpress':
      return amex
    case 'amex':
      return amex
    case 'jcb':
      return jcb
    default:
      return blank
  }
}

export const loggedInUserData = () => {
  const userData = JSON.parse(localStorage.getItem('userData') ? localStorage.getItem('userData') : '{}')
  return userData
}

export const isPartnerTownsend = (partnerSlug) => {
  if (partnerSlug == 'townsend') return true
  return false
}


export const getLocation = (faciltyData) => {
  const location = faciltyData?.geolocations
  return `https://www.google.com/maps?daddr=${location?.latitude},${location?.longitude}+(${faciltyData?.full_name?.replace(/ /g, '+')})`
}

export const getPartnerDetails = () => {
  const partner = (localStorage.getItem('partnerData') && localStorage.getItem('partnerData') != 'undefined') ? JSON.parse(localStorage.getItem('partnerData')) : (sessionStorage.getItem('partnerData') && sessionStorage.getItem('partnerData') != 'undefined') ? JSON.parse(sessionStorage.getItem('partnerData')) : {}
  return partner
}

export const getBreakUpDetails = (paymentData) => {
  let listItem = []

  if (paymentData?.parking_amount && paymentData?.parking_amount != '0.00') {
    listItem = [...listItem, { 'headerName': 'Parking Amount', 'val': `$${paymentData?.parking_amount ? Number(paymentData?.parking_amount).toFixed(2) : '0.00'}` }]
  }
  if (paymentData?.processing_fee && paymentData?.processing_fee != '0.00' && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Processing Fee', 'val': `$${paymentData?.processing_fee ? paymentData?.processing_fee : '0.00'}` }]
  }

  if (((paymentData?.tax_rate && paymentData?.tax_rate != '0.00') || (paymentData?.tax_fee && paymentData?.tax_fee != '0.00')) && paymentData?.parking_amount != '0.00' && Number(paymentData?.parking_amount) > 0 ) {
    listItem = [...listItem, { 'headerName': 'Tax', 'val': `$${paymentData?.tax_rate ? Number(paymentData?.tax_rate).toFixed(2) : paymentData?.tax_fee ? Number(paymentData?.tax_fee).toFixed(2) : '0.00'}` }]
  }
  
  if (paymentData?.paid_amount && paymentData?.paid_amount !== '' && Number(paymentData?.paid_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Validated Amount', 'val': `- $${paymentData?.paid_amount ? Number(paymentData?.paid_amount)?.toFixed(2) : 0}` }]
  }

  if (paymentData?.discount_hours && paymentData?.discount_hours != '') {
    listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : '-', 'val': `- $${paymentData?.discount_hours ? paymentData?.discount_hours : '-'}`  }]
  }

  if (paymentData?.amount_paid && paymentData?.amount_paid !== '' && Number(paymentData?.amount_paid) > 0 && Number(paymentData?.payable_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Amount Paid', 'val': `- $${paymentData?.amount_paid ? Number(paymentData?.amount_paid)?.toFixed(2) : 0}` }]
  }

  if (paymentData?.overstay_amount && paymentData?.overstay_amount !== '' && paymentData?.overstay_amount != '0.00' && Number(paymentData?.overstay_amount) > 0) {
    listItem = [...listItem, { 'headerName': 'Overstay Amount', 'val': `$${paymentData?.overstay_amount ? Number(paymentData?.overstay_amount).toFixed(2) : 0}` }]
  }

  if (paymentData?.discount_amount && paymentData?.discount_amount != '' && Number(paymentData?.discount_amount) > 0) {
    listItem = [...listItem, { 'headerName': paymentData?.discount_hours ? `Validated ${paymentData?.discount_hours} Hrs Free` : 'Discount Amount', 'val': `- $${paymentData?.discount_amount ? Number(paymentData?.discount_amount)?.toFixed(2) : 0}` }]
  }

  

  return listItem

}
export const priceGreaterOrNot = (ticketDetails) => {
  const paymentDataKeys = ['parking_amount', 'amount_paid', 'processing_fee', 'tax_rate', 'payable_amount', 'grand_total', 'discount_amount', 'paid_amount']
  let isGreaterOrNot = paymentDataKeys.map((key) => {
    if ((ticketDetails?.[key] && Number(ticketDetails?.[key]) > 0)) {
      return true
    } else {
      return false
    }
  })
  if (isGreaterOrNot.includes(true)) {
    return true
  }
}

export const getErrorMessage = (response) => {
  let errorMsg = 'Sorry! something went wrong, please try again.'
  const errorsList = response.errors
  if (errorsList.message !== 'Validation Errors') {
    errorMsg = errorsList.message
  } else {
    for (const key in errorsList.detail) {
      if (Object.prototype.hasOwnProperty.call(errorsList.detail, key)) {
        if (typeof errorsList.detail === 'undefined') {
          errorMsg = errorsList.message
        } else {
          errorMsg = Array.isArray(errorsList.detail[key]) ? errorsList.detail[key][0] : errorsList.detail[key]
        }
      }
    }
  }

  return errorMsg
}
export const EncryptionHandler = (d) => {
  var nonceString = d.name_on_card + ':' + d.card_number + ':' + d.expiration_date + ':' + d.security_code + ':' + d.zip_code
  var CryptoJSAesJson = {
      stringify: function (cipherParams) {
          var j = {
              ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
              iv: '',
              s: ''
          }
          if (cipherParams.iv)
              j.iv = cipherParams.iv.toString()
          if (cipherParams.salt)
              j.s = cipherParams.salt.toString()
          return JSON.stringify(j)
      },
      parse: function (jsonStr) {
          var j = JSON.parse(jsonStr)
          var cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
          if (j.iv)
              cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
          if (j.s)
              cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
          return cipherParams
      }
  }
  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(nonceString), 'Tw5qNOJLe5GFe7rjFsentNNQBsRHstbNzTP/18lkjnY=', { format: CryptoJSAesJson }).toString()
  return encrypted
}


export const isNotUrl = (partnerslug) => {
  if(partnerslug != 'book-reservation' && partnerslug != 'pass-create' && partnerslug != 'booking'){
    return true
  }else{
    return false
  }
}

export const getPartnerLogo = (name) => {

  const patnerName = name?.toLowerCase()
  switch (patnerName) {

    case 'parkingpayments':
      return parkingpayments
    case 'townsend':
      return townsend
    case 'demoparkengage':
      return logos
    case 'intrapark':
      return jordanImage
    case 'worldport-la':
        return breezeImg
    case 'colonialparking':
      return colonialImg    
    default:
      return logos
  }
}

export function sortPortalPermissionByKey(partnerDetails, sectionName) {
  return partnerDetails?.customer_portal_permission?.[sectionName]?.map((item) => item)
}
export const isPermissionExist = (data, itemName) => {
  const filterObj = (data?.length > 0) && data?.filter((item) => item?.display_name == itemName)
  if (filterObj[0]?.is_default == '1') {
    return true
  } else {
    return false
  }
}
export const getPartnerTagline = (name) => {

  const patnerName = name?.toLowerCase()
  switch (patnerName) {

    case 'parkingpayments':
      return 'Parking Payments'
    case 'townsend':
      return 'PCI Townsend Parking'
    case 'demoparkengage':
      return 'Parkengage Parking'
    case 'intrapark':
      return 'IntraPark Parking'
    case 'worldport-la':
      return 'Breeze Parking'
    case 'colonialparking':
      return 'colonial Parking'  
    default:
      return 'Parkengage Parking'
  }
}

export const changeTimeZone = (date, timeZone) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    )
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  )
}