import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import URL from '../../constants/urls'
import noop from 'lodash/noop'
import { useNavigate } from 'react-router-dom'
import ModalLogin from './modalLogin'
import {
  getPartnerDetails,
  isNotUrl,
  isPartnerTownsend,
} from '../../utils/helper'
import whitepng from '../../assets/img/whitebg.png'
import { getConfigDetails } from '../../services/config'
//import townsend from '../../assets/img/townsend.png'
//import breezeImg from '../../assets/img/logo-breeze.png'

function NavbarComponent(props) {
  //console.log('isPartnerTownsend',isPartnerTownsend)
  // const { isAuthenticated, userData, permitData, waitListData, passData, paymentData } = props
  const {
    isAuthenticated,
    userData,
    waitListData,
    paymentData,
    setIsOpened,
    isOpened,
    notify,
    partnerDetails,
  } = props
  //const { isAuthenticated, userData, waitListData, paymentData, setIsOpened, isOpened, notify } = props

  //console.log('partnerDetails',partnerDetails)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userDetails, setuserDetails] = useState(false)
  const [partnerName, setPartnerName] = useState('')
  const [caller, setCaller] = useState()
  // const [isOpened, setIsOpened] = useState(false)
  const [show, setShow] = useState(false)
  const [isWorldPort, setIsWorldPort] = useState(false)
  const [isLogo, setIsLogo] = useState('')

  // for modal open and close

  // const [isLoginModal, setLoginModal] = useState(false)
  //
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const partner = window.location.toString().split('/')[3]
//  const facility = window.location.toString().split('/')[4]
  //let facility_shortName='PWCC'
  // if(localStorage.getItem('partnerData')!=undefined){
  // 	// const partnerData =localStorage.getItem('partnerData')? JSON.parse(localStorage.getItem('partnerData')):''
  // 	// facility_shortName=partnerData?.facilities[0]?.short_name
  // 	facility_shortName='PWCC'
  // }else{
  // 	facility_shortName='PWCC'
  // }

  useEffect(() => {
    if (partner && partner != 'navigating' && isNotUrl(partner)) {
      setPartnerName(partner)
    } else if (getPartnerDetails()?.partner_slug) {
      setPartnerName(getPartnerDetails()?.partner_slug)
    }

    
  }, [EMPTY_ARRAY, partner])

  useEffect(() => {
    setIsWorldPort(true)
  }, [pathname, partner])

  useEffect(() => {
    localStorage.setItem('isLoggedIn', false)
    const waitListDetails = waitListData?.userDetails
    // const permitDetails = permitData?.data
    // const userDetailsPass = passData?.data?.userDetails
    // const userDetailsPermit = permitDetails?.userDetails
    const userDetailsPayment = paymentData?.data?.userDetails
    if (isAuthenticated) {
      setIsLoggedIn(true)
      const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
      const userDetails = getuserDatafromLocal?.user
      if (
        getuserDatafromLocal?.access_token &&
        getuserDatafromLocal?.access_token != ''
      ) {
        setuserDetails(userDetails)
      } else {
        const userDetails = userData?.data?.user
        setuserDetails(userDetails)
      }
    } else if (
      userDetailsPayment?.access_token &&
      userDetailsPayment?.access_token != ''
    ) {
      setIsLoggedIn(true)
      localStorage.setItem('isLoggedIn', true)
      setuserDetails(userDetailsPayment?.user)
    }
    // else if (userDetailsPermit?.access_token && userDetailsPermit?.access_token != '') {
    // 	setIsLoggedIn(true)
    // 	localStorage.setItem('isLoggedIn', true)
    // 	setuserDetails(userDetailsPermit?.user)
    // }
    else if (
      waitListDetails?.access_token &&
      waitListDetails?.access_token != ''
    ) {
      setIsLoggedIn(true)
      localStorage.setItem('isLoggedIn', true)
      setuserDetails(waitListDetails?.user)
    }
    //  else if (userDetailsPass?.access_token && userDetailsPass?.access_token != '') {
    // 	setIsLoggedIn(true)
    // 	localStorage.setItem('isLoggedIn', true)
    // 	setuserDetails(userDetailsPass?.user)
    // }
    else {
      const getuserDatafromLocal = JSON.parse(localStorage.getItem('userData'))
      const userDetails = getuserDatafromLocal?.user
      if (
        getuserDatafromLocal?.access_token &&
        getuserDatafromLocal?.access_token != ''
      ) {
        setuserDetails(userDetails)
        setIsLoggedIn(true)
        localStorage.setItem('isLoggedIn', true)
      } else {
        setIsLoggedIn(false)
      }
    }
    // }, [EMPTY_ARRAY, isAuthenticated, permitData, waitListData, passData,paymentData])
  }, [EMPTY_ARRAY, isAuthenticated, waitListData, paymentData, pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(()=>{
   let logoURLData= partnerDetails
                  ? partnerDetails?.brand_setting
                    ? partnerDetails?.brand_setting?.logourl
                    : partnerDetails?.partner_details?.brand_setting?.logourl
                  : getPartnerDetails()?.brand_setting
                  ? getPartnerDetails()?.brand_setting?.logourl
                    ? getPartnerDetails()?.brand_setting?.logourl
                    : getPartnerDetails()?.parter_details?.brand_setting
                        ?.logourl
                  : isPartnerTownsend(partner)
                  ? whitepng
                  : URL?.PARKENGAGE_LOGO_URL
      setIsLogo(logoURLData)            
  },[partnerDetails])

  const handleLogout = () => {
    localStorage.removeItem('userData')
    localStorage.removeItem('isLoggedIn')

    if (getPartnerDetails()) {
      sessionStorage.setItem(
        'partnerSecret',
        getConfigDetails()?.CLIENT_SECRET
      )
      sessionStorage.setItem(
        'partnerData',
        JSON.stringify(getPartnerDetails())
      )
    }
    localStorage.removeItem('partnerSecret')
    localStorage.removeItem('partnerData')
    setIsLoggedIn(false)
    navigate(`${URL?.INDEX}/${partnerName}`)
    navigate(0)
  }
  const handleModalOpen = () => {
    setShow(true)
    setCaller('nav')
    var navbarDiv = document.getElementById('mainNavID')
    navbarDiv.classList.remove('show')
  }

  const handleReservationTab = () => {
    navigate(`${URL?.INDEX}/${partnerName}`)
    navigate(0)
   // window.location.reload()
  }

  const handleActiveTab = () => {
    setIsOpened(false)
    var navbarDiv = document.getElementById('mainNavID')
    navbarDiv.classList.remove('show')
  }
  const handleDropDown = (e) => {
    e.stopPropagation()
    if (isOpened) {
      setIsOpened(false)
    } else {
      setIsOpened(true)
    }
  }
  return (
    <header>
      <nav className="navbar navbar-expand-sm fixed-top navbar-light shadow bg-white">
        <div className="container">
          <a href={`${URL?.INDEX}/${partnerName}`}>
            {/* <img  src={breezeImg} width="140px" alt={getPartnerDetails()?.name}></img> */}

            {/* <img src='https://staging-api.parkengage.com/brand-settings-logo/20' width="140px" alt={getPartnerDetails()?.name} /> */}
            {/* <img
              src={
                partnerDetails
                  ? partnerDetails?.brand_setting
                    ? partnerDetails?.brand_setting?.logourl
                    : partnerDetails?.partner_details?.brand_setting?.logourl
                  : getPartnerDetails()?.brand_setting
                  ? getPartnerDetails()?.brand_setting?.logourl
                    ? getPartnerDetails()?.brand_setting?.logourl
                    : getPartnerDetails()?.parter_details?.brand_setting
                        ?.logourl
                  : isPartnerTownsend(partner)
                  ? whitepng
                  : URL?.PARKENGAGE_LOGO_URL
              }
              width="140px"
              alt={getPartnerDetails()?.name}
            /> */}
           {isLogo ? <img
              src={isLogo}
              width="140px"
              alt={getPartnerDetails()?.name}
            />:''
           } 
          </a>

          {
            <>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainNavID"
                aria-controls="mainNavID"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="mainNavID">
                <ul className="navbar-nav ms-auto">
                  {
                    <li className="nav-item">
                      <Link
                        className={
                          pathname == `${URL?.INDEX}/${partnerName}` ||
                          isWorldPort
                            ? 'nav-link activeLink'
                            : 'nav-link'
                        }
                        to={`${URL?.INDEX}/${partnerName}`}
                        onClick={() => handleReservationTab()}
                      >
                        Reservation
                      </Link>
                      {/* <Link className={((pathname == `/${partnerName}/${URL.BASE_RESPONSIVE_RESERVATION}`) || isWorldPort) ? 'nav-link activeLink' : 'nav-link'} to={(facility && isNotUrl(facility)) ? `${partnerName}/${URL.BASE_RESPONSIVE_RESERVATION}` : `${partnerName}${URL.BASE_RESPONSIVE_RESERVATION}`} onClick={() => handleActiveTab()}>Reservation</Link> */}

                      {/* <Link className={((pathname == `/${partnerName}/${URL.BASE_RESPONSIVE_RESERVATION}`) || isWorldPort) ? 'nav-link activeLink' : 'nav-link'} to={(facility && isNotUrl(facility)) ? `${partnerName}${URL.BASE_RESPONSIVE_RESERVATION}` : `${partnerName}${URL.BASE_RESPONSIVE_RESERVATION}`} onClick={() => handleReservationTab()}>Reservation</Link> */}
                    </li>
                  }
                </ul>
              </div>
            </>
          }
          <ul className="ms-0 mt-3 ps-0">
            {isLoggedIn ? (
              <li className="dropdown listStyleNone" id="accountmenucontainer">
                {/* <a className=" dropdown-toggle px-3" id="accountmenu" href="#" role="button" data-bs-toggle="dropdown"
										aria-expanded="false" onClick={(e) => handleDropDown(e)}>
										<b>{userDetails?.name}</b>
									</a> */}
                <i
                  className="far fa-user-circle text-primary fa-lg"
                  onClick={(e) => handleDropDown(e)}
                >
                  {/* <b>{userDetails?.name}</b> */}
                </i>
                <ul
                  className={
                    isOpened
                      ? 'dropdown-menu dropdown-menu-end d-block right-0'
                      : 'dropdown-menu dropdown-menu-end right-0'
                  }
                >
                  <li>
                    <Link
                      className={
                        pathname == `/${partnerName}${URL?.BASE_USER}`
                          ? 'nav-link activeLink ms-2'
                          : 'nav-link ms-2'
                      }
                      to={`${partnerName}${URL?.BASE_USER}`}
                      onClick={() => handleActiveTab()}
                    >
                      My Account
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className={
                        pathname == `/${partnerName}${URL?.BASE_USER_PROFILE}`
                          ? 'nav-link activeLink ms-2'
                          : 'nav-link ms-2'
                      }
                      to={`${partnerName}${URL?.BASE_USER_PROFILE}`}
                      onClick={() => handleActiveTab()}
                    >
                      Profile
                    </Link>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      type="button"
                      className="dropdown-item ms-2 ps-0"
                      onClick={(e) => handleLogout(e)}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            ) : (
              <li className="listStyleNone">
                <i
                  className="fa fa-sign-in text-primary"
                  onClick={handleModalOpen}
                ></i>
              </li>
            )}
          </ul>
        </div>
      </nav>
      <ModalLogin
        setShow={setShow}
        show={show}
        caller={caller}
        notify={notify}
        userDetails={userDetails}
      />

      {/* <Login isLoginModal={isLoginModal} setLoginModal={setLoginModal} caller={caller} /> */}
    </header>
  )
}

NavbarComponent.propTypes = {
  dispatch: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  userData: PropTypes.object,
  // permitData: EMPTY_OBJECT,
  // passData: EMPTY_OBJECT,
  paymentData: PropTypes.object,
  isModalOpen: PropTypes.bool,
  waitListData: PropTypes.object,
  setIsOpened: PropTypes.func,
  isOpened: PropTypes.bool,
  notify: PropTypes.func,
  partnerDetails: PropTypes.object,
}

NavbarComponent.defaulProps = {
  dispatch: noop,
  isAuthenticated: false,
  userData: EMPTY_OBJECT,
  // permitData: EMPTY_OBJECT,
  // passData: EMPTY_OBJECT,
  paymentData: EMPTY_OBJECT,
  isModalOpen: false,
  waitListData: EMPTY_OBJECT,
  setIsOpened: noop,
  isOpened: false,
  notify: noop,
  partnerDetails: EMPTY_OBJECT,
}

function mapStateToProps({
  login,
  paymentReducer,
  basicInfoReducer,
  waitingListReducer,
}) {
  return {
    isAuthenticated: login?.isAuthenticated,
    userData: login?.data,
    // permitData: paymentReducer?.data,
    // passData: paymentReducer?.passData,
    paymentData: paymentReducer?.data,
    isModalOpen: basicInfoReducer?.isModalOpen,
    waitListData: waitingListReducer?.waitList?.data,
  }
}
export default connect(mapStateToProps)(NavbarComponent)
