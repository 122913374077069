import { combineReducers } from 'redux'
import login from './login'
import paymentReducer from './paymentReducer'
import basicInfoReducer from './basicInfoReducer'
import permitListReducer from './permitListReducer'
import waitingListReducer from './waitingListReducer'
import passListReducer from './passListReducer'
import vehicleListReducer from './vehicleListReducer'
import resetPasswordReducer from './resetPasswordReducer'
import PassesReducer from './PassesReducer'
import PartnerReducer from './PartnerReducer'
import bookingHistoryReducer from './bookingHistoryReducer'
import cardDetailsReducer from './cardDetailsReducer'
import userDetailsReducer from './userDetailsReducer'
import widgetReservationReducer from './widgetReservationReducer'

export default combineReducers({
	login,
	widgetReservationReducer,
	paymentReducer,
	basicInfoReducer,
	permitListReducer,
	waitingListReducer,
	vehicleListReducer,
	passListReducer,
	resetPasswordReducer,
	PassesReducer,
	PartnerReducer,
	bookingHistoryReducer,
	cardDetailsReducer,
	userDetailsReducer
})
