import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
// import FooterComponent from '../components/global/footer'
import NavbarComponent from '../components/global/navbar'
import URL from '../constants/urls'
import About from '../pages/about/About'
import Contact from '../pages/contact'
import Pass from '../pages/pass'
import CreatePass from '../pages/passPurchase/main/CreatePass'
import SignUp from '../pages/signup'
import ThankYou from '../pages/thankyou'
import PaymentFails from '../pages/thankyou/PaymentFails'
import UserProfile from '../pages/user/profile/UserProfile'
import VehicleDemo from '../pages/user/vehicleDemo/VehicleDemo'
import Confirmation from '../pages/passPurchase/confirmation/Confirmation'
import Navigating from '../pages/user/MasqueradeUserLogin'
import HomeComponent from '../pages/bookingPortal/home/homeComponent'
import PaymentResponse from '../pages/bookingPortal/pass/paymentResponse'
import PassComponent from '../pages/bookingPortal/pass/passComponent'
import PaymentFailed from '../pages/bookingPortal/pass/paymentFailed'
import FailedComponent from '../pages/bookingPortal/pass/failedComponent'
import DirectBookingResponse from '../pages/bookingPortal/pass/directBookingResponse'
import ConfirmationComponent from '../pages/bookingPortal/pass/confirmationComponent'
import Ticket from '../pages/user/eTicketRecipt/Ticket'
import { toast, ToastContainer } from 'react-toastify'
import { getLoggedUserData } from '../utils/HelperEvent'
import {  getPartnerLogo, getPartnerTagline } from '../utils/helper'

import { getPartnerDetails, isNotUrl, isPartnerTownsend } from '../utils/helper'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants/globalConstants'
import usePathDetector from '../Hooks/pathChangeDetector'
import SendEmailResetPassword from '../components/global/SendEmailResetPassword'
import ResponsiveReservation from '../pages/responsiveReservation/responsiveReservation'
import { getPartnerDetailsBySLug } from '../redux/actions/partnerAction'
import PageNotFound from '../pages/bookingPortal/not-found/error-404'



function RoutesComponent(props) {
	const {dispatch, isFetching} = props
	//const path = 'worldport'
	const path = window.location.toString().split('/')[3]
	const facility = window.location.toString().split('/')[4]
	const [isOpened, setIsOpened] = useState(false)
	const [isNavigating, setIsNavigating] = useState(false)
	const [partnerDetails, setPartnerDetails] = useState(EMPTY_OBJECT)
	// const [faviconPath, setFaviconPath] = useState('')
    // const [title, setTitle] = useState('')

	window.addEventListener('click', function () {
		setIsOpened(false)
	})

	
	const isPathChanged = usePathDetector()
    useEffect(() => {

        if (path && path != 'navigating') {
			let apiSlug
			if(facility && isNotUrl(facility)){
				apiSlug=`${path}/${facility}`
			}else{
				apiSlug=path
			}
			

            dispatch(getPartnerDetailsBySLug(apiSlug)).then((res) => {
                localStorage.setItem('partnerData', JSON.stringify(res?.data))
                const partnerData = res?.data
				setPartnerDetails(partnerData)	

				const newLogoPath = partnerData?.brand_setting ? partnerData?.brand_setting?.logourl : partnerData?.partner_details?.brand_setting?.logourl ? partnerData?.partner_details?.brand_setting?.logourl : getPartnerLogo(path) 
				const newTitle = partnerData?.brand_setting ? partnerData?.brand_setting?.name : partnerData?.partner_details?.brand_setting?.name ? partnerData?.partner_details?.brand_setting?.name : getPartnerTagline(path) 
				const newFaviconPath = partnerData?.brand_setting ? partnerData?.brand_setting?.faviconurl : partnerData?.partner_details?.brand_setting?.faviconurl ? partnerData?.partner_details?.brand_setting?.faviconurl : getPartnerLogo(path) 
				updateTitleFavicon(newTitle,newFaviconPath,newLogoPath)

				let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
                let rgb = partnerData?.brand_setting ? JSON.parse(partnerData?.brand_setting?.rgb_color) : JSON.parse(partnerData?.partner_details?.brand_setting?.rgb_color)
                if (color) {
                    document.documentElement.style.setProperty('--bs-primary', color)
                    document.documentElement.style.setProperty('base-color', color)
                    document.documentElement.style.setProperty('primary', color)
                    document.documentElement.style.setProperty('textcolor', color)
                    document.documentElement.style.setProperty('--bs-btn-bg', color)
					document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
                    document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
                    document.documentElement.style.setProperty('--bs-list-group-active-color', color)
                    document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
                }
            }).catch((err) => {
                console.log(err)
            })
        } else if(path != 'navigating'){
			//window.location.replace('/worldport-la')

            localStorage.removeItem('partnerSecret')
        }else{
            localStorage.removeItem('partnerSecret')
		}
    },[path])

	// useEffect(() => {
    //     const updateFavicon = () => {
    //      // const favicon = document.querySelector('link[rel="icon"]')
    //      // favicon.href = faviconPath?faviconPath:''
    //     }
    
    //     const updateTitle = () => {
    //      // document.title = title
    //     }
    
    //     updateFavicon()
    //     updateTitle()
    // }, [faviconPath, title])

	const updateTitleFavicon=(newTitle,newFaviconPath,newLogoPath)=>{
		const favicon = document.querySelector('link[rel="icon"]')		
		favicon.href = newFaviconPath
		
		const widgetLogo = document.querySelector('#logoIframe')
		// widgetLogo.src=newFaviconPath
		widgetLogo.src=newLogoPath
		document.title = newTitle
	}

    useEffect(() => {
		
		if(path != 'navigating'){
			// const newFaviconPath = partnerDetails?.brand_setting ? partnerDetails?.brand_setting?.logourl : partnerDetails?.partner_details?.brand_setting?.logourl ? partnerDetails?.partner_details?.brand_setting?.logourl : getPartnerLogo(path) 
			// const newTitle = partnerDetails?.brand_setting ? partnerDetails?.brand_setting?.name : partnerDetails?.partner_details?.brand_setting?.name ? partnerDetails?.partner_details?.brand_setting?.name : getPartnerTagline(path) 
		
			//updateTitleFavicon(newTitle,newFaviconPath)
			
		}
        
    },[path])

	useEffect(() => {
		if (path == 'navigating') {
			setIsNavigating(false)
		} else {
			setIsNavigating(true)
		}
		const partnerData = getPartnerDetails()
		const color = partnerData?.brand_setting?.color
		if (partnerData?.brand_setting && partnerData?.brand_setting?.color) {
			document.documentElement.style.setProperty('--bs-primary', color)
			document.documentElement.style.setProperty('base-color', color)
			document.documentElement.style.setProperty('primary', color)
			document.documentElement.style.setProperty('textcolor', color)
			document.documentElement.style.setProperty('--bs-btn-bg', color)
			document.documentElement.style.setProperty('--bs-primary-rgb', '34, 32, 93')
			document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
			document.documentElement.style.setProperty('--bs-list-group-active-color', color)
			document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
		}
	}, [EMPTY_ARRAY, isPathChanged])

	const notify = (type, message) => {
		if (type === 'error') {
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			})
			toast.clearWaitingQueue()
		} else if (type === 'success') {
			toast.success(message, {
				position: toast.POSITION.TOP_RIGHT
			})
			toast.clearWaitingQueue()
		}
	}




	return (
		<BrowserRouter forceRefresh className="mt-3">
			{isFetching ? null : <NavbarComponent isOpened={isOpened} setIsOpened={setIsOpened} notify={notify} partnerDetails={partnerDetails}/>}
			{isFetching ? null : <Routes>
				<Route
					path={URL.HOME}
					element={
						<ResponsiveReservation notify={notify} partnerDetails={partnerDetails}/>
					}
				/>
				<Route
					path={URL.SEND_EMAIL_FORGOT_PASSWORD}
					element={
						<SendEmailResetPassword notify={notify} />
					}
				/>
				<Route
					path={URL.THANKYOU}
					element={
						<ThankYou />
					}
				/>
				<Route
					path={URL.ABOUT}
					element={
						<About />
					}
				/>
				<Route
					path={URL.SIGNUP}
					element={
						<SignUp />
					}
				/>
				<Route
					path={URL.PASS}
					element={
						<Pass />
					}
				/>
				<Route
					path={URL.PASSCREATE}
					element={
						<CreatePass />
					}
				/>
				<Route
					path={URL.CONTACT}
					element={
						<Contact />
					}
				/>
				<Route
					path={URL.USER}
					element={
						<VehicleDemo notify={notify} />
					}
				/>
				<Route
					path={URL.USER_PROFILE}
					element={
						<UserProfile />
					}
				/>
				<Route
					path={URL.PAYMENT_SUCCESS}
					element={
						<ThankYou />
					}
				/>

				<Route
					path={URL.PAYMENT_FAIL}
					element={
						<PaymentFails />
					}
				/>
				<Route
					path={URL.PASS_PAYMENT_SUCCESS}
					element={
						<Confirmation />
					}
				/>
				<Route
					path={URL.PASS_PAYMENT_FAIL}
					element={
						<Confirmation />
					}
				/>
				<Route
					path={URL.NAVIGATING}
					element={
						<Navigating />
					}
				/>
				<Route
					path={URL.NAVIGATING_RECEIPT}
					element={
						<Navigating />
					}
				/>
				<Route
					path={URL.NAVIGATING_PARTNER}
					element={
						<Navigating />
					}
				/>
				<Route
					path={URL.BOOKTICKET}
					element={
						getLoggedUserData()?.access_token ? <PassComponent /> : <HomeComponent />
					}
				/>
				<Route
					path={URL.CHECKOUT_PASSES}
					element={
						<PassComponent />
					}
				/>
				<Route
					path={URL.AAA_PAYMENT_RESPONSE}
					element={
						<PaymentResponse />
					}
				/>
				<Route
					path={URL.AAA_FAILED_COMPONENT}
					element={
						<PaymentFailed />
					}
				/>
				<Route
					path={URL.AAA_PAYMENT_SUCCESS}
					element={
						<ConfirmationComponent />
					}
				/>
				<Route
					path={URL.AAA_PAYMENT_FAILED}
					element={
						<FailedComponent />
					}
				/>
				<Route
					path={URL.AAA_BOOKING_RESPONSE}
					element={
						<DirectBookingResponse />
					}
				/>

				<Route
					path={URL.TICKET}
					element={
						<Ticket notify={notify} />
					}
				/>
				<Route
					path={URL.RESPONSIVE_RESERVATION}
					element={
						<ResponsiveReservation notify={notify} partnerDetails={partnerDetails}/>
					}
				/>
				<Route
					path={URL.RESPONSIVE_RESERVATION_FACILITY}
					element={
						<ResponsiveReservation notify={notify} partnerDetails={partnerDetails}/>
					}
				/>
			
				<Route path="*" element={
					<PageNotFound notify={notify} partnerDetails={partnerDetails}/>
					// <ResponsiveReservation notify={notify} partnerDetails={partnerDetails}/>
				}>

				</Route>
			</Routes>}
			{/* {isFetching ? null : (!isPartnerTownsend(path) && isNavigating) && <FooterComponent />} */}
			{isFetching ? null : (!isPartnerTownsend(path) && isNavigating) }

			<ToastContainer limit={1} />
		</BrowserRouter>
	)
}

RoutesComponent.propTypes = {
    dispatch: PropTypes.func,
    isFetching: PropTypes.bool,
}

RoutesComponent.defaulProps = {
    dispatch: noop,
    isFetching: false

}

function mapStateToProps({ PartnerReducer }) {
    return {
        
        isFetching: PartnerReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(RoutesComponent)
