import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { userLogin } from '../../redux/actions/login'
import { useNavigate } from 'react-router-dom'
import URL from '../../constants/urls'
import { Circles } from 'react-loader-spinner'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { getConfigDetails } from '../../services/config'

function Login(props) {

    const { dispatch, isLoginModal, setLoginModal, caller } = props

    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate()
	const partner = window.location.toString().split('/')[3]
    const [loginPayload, setLoginPayload] = useState({ 'username': '', 'password': '', 'client_id': getConfigDetails()?.CLIENT_ID, 'client_secret': getConfigDetails()?.CLIENT_SECRET, 'grant_type': 'password' })
    const [isLoading, setIsLoading] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false)


    const handleLogin = (e) => {
        const { name, value } = e.target
        setLoginPayload({ ...loginPayload, [name]: value })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(event)
        }
    }

    
    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') {
            setPasswordShown(passwordShown ? false : true)
        }
          
    }


    const handleSubmit = (event) => {
        setIsLoading(true)
        event.preventDefault()
        if (loginPayload?.username != '' && loginPayload?.password != '') {
            dispatch(userLogin(loginPayload)).then((res) => {
                setIsLoading(false)
                const userData = res?.data

                if (userData) {
                    setIsLoading(false)
                    if (caller == 'signup') {
                        localStorage.setItem('isLoggedIn', true)
                        localStorage.setItem('userData', JSON.stringify(userData))
                        setLoginModal(false)

                    } else {
                        localStorage.setItem('isLoggedIn', true)
                        localStorage.setItem('userData', JSON.stringify(userData))
                        setLoginModal(false)
                        navigate(`/${partner}${URL?.BASE_USER}`)
                        // navigate(0)

                    }

                } else {
                    setErrorMessage(res?.errors?.message ? res?.errors?.message : 'Something went wrong, Please try again!')
                }

            }).catch((err) => {
                const response = err?.errors
                setIsLoading(false)

                setErrorMessage(response?.message ? response?.message : 'Something went wrong, Please try again!')

            })
        } else {
            setIsLoading(false)
            setErrorMessage('Please enter user name & password')
        }
    }

    return (
        <>
            <Modal
                size="md"
                show={isLoginModal}
                onHide={() => setLoginModal(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                onKeyPress={(e) => handleKeyPress(e)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Login
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">User Name</label>
                        <input type="email" name='username' className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleLogin} />
                    </div>
                    <div className="mb-3 myiconset">
                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                        <input type={passwordShown ? 'text' : 'password'} name='password' className="form-control" id="exampleInputPassword1" onChange={handleLogin} />
                        <i className={passwordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                    </div>
                    <span className='text-danger'>{errorMessage}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" className="btn btn-primary d-flex" onClick={(e) => handleSubmit(e)}>Submit {isLoading ? <span className=' ms-2'><Circles
                        height="20"
                        width="20"
                        color="#ffffff"
                        ariaLabel="circles-loading"
                        visible={true}
                    /></span> : null}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


Login.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    events: PropTypes.object,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: PropTypes.array,
    setPage: PropTypes.string,
    setFront: PropTypes.func,
    setBack: PropTypes.func,
    setIsLoggedIn: PropTypes.func,
    homePayload: PropTypes.object,
    // 
    isLoginModal: PropTypes.bool,
    setLoginModal: PropTypes.func,
    caller: PropTypes.string

}

Login.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    events: EMPTY_OBJECT,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: undefined,
    setFront: noop,
    setBack: noop,
    setIsLoggedIn: noop,
    homePayload: EMPTY_OBJECT,
    // 
    isLoginModal: PropTypes.bool,
    setLoginModal: PropTypes.func,
    caller: undefined

}

function mapStateToProps({ login }) {
    return {
        loader: login?.isAuthenticated,
    }
}
export default connect(mapStateToProps)(Login)