import CONSTANTS from '../constants'
import { getRequest ,downloadFile,postRequest} from '../../services'
// import { APICONFIG } from '../apiConfig'
import { APICONFIG } from '../config/apiConfig'


// GET Price BY Time
export const getPriceDetailsByTime = (facility,slug) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_RESERVATION_PRICE_REQUEST })
    return getRequest(`${APICONFIG.getPriceByTime}/${facility}?${slug}`).then((res) => {
        dispatch({ type: CONSTANTS.GET_RESERVATION_PRICE_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_RESERVATION_PRICE_FAILURE, errorMessage: err })
        return err
    })
}

// GET Price BY Time
export const applyPromoCodeReservation = (payload) => (dispatch) => {
   // console.log('payload',payload)
    dispatch({ type: CONSTANTS.GET_RESERVATION_PROMO_CODE_REQUEST })
    return postRequest(APICONFIG.applyPromoCode, payload).then((res) => {
        dispatch({ type: CONSTANTS.GET_RESERVATION_PROMO_CODE_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_RESERVATION_PROMO_CODE_FAILURE, errorMessage: err })
        return err
    })
}

export const saveReservationPayment = (payload) => (dispatch) => {
    //console.log('payment payload',payload)
    dispatch({ type: CONSTANTS.SAVE_RESERVATION_REQUEST })
    return postRequest(APICONFIG.saveReservationPaymentUrl, payload).then((res) => {
        dispatch({ type: CONSTANTS.SAVE_RESERVATION_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.SAVE_RESERVATION_FAILURE, errorMessage: err })
        return err
    })
}

// GET PARTNER BY SLUG
export const getPartnerDetailsBySLug = (slug) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_REQUEST })
    return getRequest(`${APICONFIG.getPartnerBySlug}/${slug}`).then((res) => {
        dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_FAILURE, errorMessage: err })
        return err
    })
}



export const downloadTicket = (payload,ticketNumber) => (dispatch) => {
    dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_REQUEST })
    return downloadFile(`${APICONFIG.downloadBookingEticketUrl}/${payload}`, payload, `${ticketNumber ? ticketNumber : 'ticket'}.pdf`).then((res) => {
        dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_FAILURE })
        return err
    })
}

// GET gate controll
export const getCheckInGate = (facility) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_CRUISE_LINE_REQUEST })
    return getRequest(`${APICONFIG.getCheckInGateUrl}/${facility}`).then((res) => {
        dispatch({ type: CONSTANTS.GET_CRUISE_LINE_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_CRUISE_LINE_FAILURE, errorMessage: err })
        return err
    })
}