import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/globalConstants'
// import { getUserProfile } from '../../../redux/actions/login'
import { getLoggedUserData } from '../../../utils/HelperEvent'

function VehicleAndPermit(props) {
    const [userData, setUserData] = useState(EMPTY_OBJECT)
    const {vehicleCount} = props
    useEffect(() => {
        const userDetails = getLoggedUserData()
        setUserData(userDetails?.user)
    }, [EMPTY_ARRAY, EMPTY_OBJECT])
    return (
        <div className="container mb-4" id="profileSnap">
            <div className="card card-body mt-4 mt-sm-0 ">
                <div className="row  align-items-center">
                    <div className="col-12 col-sm mb-sm-0 mb-3">
                        <small className="fw-light">Welcome Back</small>
                        <h1 className="fw-bold mb-0 text-primary">{userData?.name}</h1>
                    </div>
                    
                    <div className="col-sm-auto col-5  text-center">
                    <div className="p-2 border h-00  "><span className="h4 fw-bold text-secondary">{vehicleCount ? vehicleCount : (userData?.userVehicleCount && userData?.userVehicleCount != '') ? userData?.userVehicleCount : 0}</span><br /><small>Vehicle Added</small>
                        </div>
                        {/* <div className="p-2 border h-00  "><span className="h4 fw-bold text-secondary">{(userData?.userVehicleCount && userData?.userVehicleCount != '') ? userData?.userVehicleCount : 0}</span><br /><small>Vehicle Added</small>
                        </div> */}
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

VehicleAndPermit.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    userData: PropTypes.EMPTY_OBJECT,
    partner: PropTypes.string,
    vehicleCount: PropTypes.number,

}

VehicleAndPermit.defaulProps = {
    dispatch: noop,
    loader: PropTypes.bool,
    userData: PropTypes.EMPTY_OBJECT,
    partner: undefined,
    vehicleCount: 0,
}

function mapStateToProps({ permitListReducer, login }) {
    return { loader: permitListReducer?.loader, userData: login?.userDetails }
}
export default connect(mapStateToProps)(VehicleAndPermit)
