import CONSTANTS from '../constants'
import { getRequest } from '../../services'
import { APICONFIG } from '../config/apiConfig'

// Calls the API to get Facility Initials
export const getFacilityList = () => (dispatch) => {
	dispatch({ type: CONSTANTS.GET_FACILITY_REQUEST })
	return getRequest(APICONFIG.getFacilityList).then((res) => {
		dispatch({ type: CONSTANTS.GET_FACILITY_SUCCESS, data: res.data })
		return res.data
	}).catch(() => {
		dispatch({ type: CONSTANTS.GET_FACILITY_FAILURE })
	})
}

//Permit rate list
// export const permitRateList = (data) => (dispatch) => {
// 	dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_REQUEST })
// 	return getRequest(`${APICONFIG.getPermitRateListUrl}?facility_id=${data?.facility_id}&&driving_license=${data?.driving_license}`).then((res) => {
// 		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_SUCCESS, data: res })
// 		return res
// 	}).catch(() => {
// 		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_FAILURE })
// 	})
// }

//Permit rate list
export const permitRateList = (data) => (dispatch) => {
	dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_REQUEST })
	return getRequest(`${APICONFIG.getArePermitRateListUrl}?facility_id=${data?.facility_id}&&driving_license=${data?.driving_license}`).then((res) => {
		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_SUCCESS, data: res })
		return res
	}).catch(() => {
		dispatch({ type: CONSTANTS.GET_PERMIT_RATE_LIST_FAILURE })
	})
}

// OPEN MODAL ACTION
	export const modalAction = (data) => (dispatch) => {
		dispatch({ type: CONSTANTS.OPEN_MODAL, isModalOpen: data })
		// return data
	}