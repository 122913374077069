import React, { useState, useEffect } from 'react'
import { Circles } from 'react-loader-spinner'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'
import AdminListTablePage from '../../../components/global/dataTable'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/globalConstants'
import { getDataBookingHistory } from '../../../redux/actions/bookingHistoryAction'
import moment from 'moment/moment'
import {
    downloadTicket,
  } from '../../../redux/actions/widgetReservationAction'

function BookingHistory(props) {
    console.log('props>>>',props)
    const [isLoading, setIsLoading] = useState(false)
    const [bookingData, setBookingData] = useState(EMPTY_ARRAY)
    const [search, setSearch] = useState('')
    const [loaderTicket,setLoaderTicket]=useState(false)

    const { current_page, per_page, dispatch, total} = props

    useEffect(() => {
        getNewBookingList()
    }, EMPTY_ARRAY)

    

    const worldPortcolumns = [
        { header: 'Ticket Number', id: 'ticket_number', accessor: e => e?.ticketech_code ? e?.ticketech_code : '-', style: { minWidth: '5rem' } },
        { header: 'Facility Name', id: 'facility_name', accessor: e => e?.facility ? e.facility?.full_name : '-', style: { minWidth: '5rem' } },
        { header: 'Check-in Date & Time', id: 'checkin_date', accessor: e => e?.created_at ? moment(e?.created_at).format('MM/DD/YYYY HH:MM A') : '-', style: { minWidth: '7rem' } },
       // { header: 'Start Parking Date/Time', id: 'checkin_date', accessor: e => e?.start_timestamp ? moment(e?.start_timestamp).format('MM/DD/YYYY HH:MM A') : '-', style: { minWidth: '7rem' } },
        { header: 'Check-out Date & Time', id: 'checkin_date', accessor: e => e?.end_time ? moment(e?.end_time.date).format('MM/DD/YYYY HH:MM A') : '-', style: { minWidth: '7rem' } },
        
        { header: 'Amount', id: 'amount', accessor: e =>'$'+ e?.total, style: { minWidth: '5rem' } },
        // { header: 'Vehicle', id: 'vehicle', accessor: e => e.is_allocated === 1 ? 'Yes' : 'No' },
         { header: 'Action', accessor: e => renderRowActions(e) }
    ]

    const renderRowActions = (data) => {
        return (
          <div className="d-flex action-btn">
           <a  className="btn btn-primary me-2 w-100"
                              onClick={()=>handleDownloadTicket(data?.id,data?.ticketech_code)}
                              
                              disabled={loaderTicket}
                            >
                              <div className="d-flex justify-content-center align-items-center">

                              &nbsp; 
                                 <i className="fa fa-download"></i>
                                
                              </div>
                            </a>
            {/* <a title="Edit" className="m-1 mt-0" href="#" onClick={(event) => {
              event.stopPropagation()
              event.preventDefault()
              handleDownloadTicket(data.id,data.id)
            }}>
              <i className="bi bi-pencil-fill edit"></i>
            </a> */}
           
          </div>
        )
      }
      const handleDownloadTicket = (Id,ticketNumber) => {
        if(!loaderTicket){
            setLoaderTicket(true)    
            dispatch(downloadTicket(Id,ticketNumber)).then((res) => {
                setLoaderTicket(false)
                if (res?.status && res?.status == 500) {
                    //notify('error', 'Something went wrong, Please try again!')
                }
               // notify('Success', 'E-Ticket Downloaded')
            }).catch(() => {
                setLoaderTicket(false)
               // notify('error', 'Something went wrong, Please try again!')
            })
        }
       
        
    }
    // const renderRowActions = (data) => {
    //     return (
    //       <div className="d-flex action-btn">
    //         <a title="Edit" className="m-1 mt-0" href="#" onClick={(event) => {
    //           event.stopPropagation();
    //           event.preventDefault();
    //           getUpdate(data.id)
    //         }}>
    //           <i className="bi bi-pencil-fill edit"></i>
    //         </a>
    //         {(data.is_allocated !== 1) && <a title="Delete" className="mt-0 ms-2" href="#" onClick={(event) => {
    //           event.stopPropagation();
    //           event.preventDefault();
    //           getDelete(data.id)
    //         }}>
    //           <i className="bi bi-trash3 delete"></i>
    //         </a>}
    //       </div>
    //     );
    //   };

    const getNewBookingList = (page = 1, search = '', sortBy = '', sortOrder = '') => {
        setIsLoading(true)
        setSearch(search)
        dispatch(getDataBookingHistory(page, search, sortBy, sortOrder)).then((res) => {
            setBookingData(res?.data)
            setIsLoading(false)
        })
    }


    return (
        <div>
            <div className="d-flex mb-4">
                <h4 className="fw-bold">Booking History </h4>
            </div>
            {isLoading ? <span className='ms-6 d-flex justify-content-center loaderdesign section dashboard'><Circles
                height="700"
                width="50"
                color="var(--bs-primary)"
                ariaLabel="circles-loading"
                visible={true}
            /></span> :
                <div className="card mb-3 table-responsive">
                    <div className="card-header"><b>Booking List</b></div>
                    <AdminListTablePage
                        data={bookingData}
                        columns={worldPortcolumns}
                        getNewRecords={getNewBookingList}
                        curPage={current_page}
                        perPage={per_page}
                        total={total}
                        showSearch={true}
                        search={search}
                    />
                </div>}
        </div>
    )
}

BookingHistory.propTypes = {
    dispatch: noop,
    isAuthenticated: PropTypes.bool,
    userData: PropTypes.object,
    permitData: PropTypes.object,
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    total: PropTypes.number,
}

BookingHistory.defaulProps = {
    isAuthenticated: false,
    userData: EMPTY_OBJECT,
    permitData: EMPTY_OBJECT,
}

function mapStateToProps({ bookingHistoryReducer }) {
    const { current_page, per_page, total } = bookingHistoryReducer
    return {
        current_page,
        per_page,
        total,
    }
}
export default connect(mapStateToProps)(BookingHistory)