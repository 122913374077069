import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, saveCard } from '../../../constants/globalConstants'
import { getCardDataList, removeCardData, createCardData } from '../../../redux/actions/cardDetailsAction'
import { encryptionHandler } from '../../../utils/helper'
import { Circles } from 'react-loader-spinner'
import ConfermationModal from '../../../components/global/ConfermationModal'
import AddCardModal from '../../../components/global/addCardModal'
import AdminListTablePage from '../../../components/global/dataTable'

function PaymentSetting(props) {
    const { current_page, per_page, dispatch, total } = props
    const [cardList, setCardList] = useState(EMPTY_ARRAY)
    const [expiry, setExpiry] = useState()
    const [card, setCard] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [error, setError] = useState(false)
    const [payload, setPayload] = useState(saveCard)
    const [isModalOpen, setModal] = useState(false)
    const [confermationModal, setConfermationModal] = useState(false)
    const [deleteItem, setDeleteItem] = useState(EMPTY_ARRAY)
    const [search, setSearch] = useState('')

    const columns = [
        { header: 'Card Number', id: 'card_number', accessor: e => e?.card_last_four ? `XXXX XXXX XXXX ${e?.card_last_four}` : '-' },
        { header: 'Expiry Date', id: 'expiry_Date', accessor: e => (e?.expiry !== null && e?.expiry != '') ? `${e?.expiry?.slice(0, 2)}/${e?.expiry?.slice(-2)}` : '-' },
        { header: 'Actions', accessor: e => renderRowActions(e) }
    ]

    const renderRowActions = (data) => {
        return (
            <div className="d-flex action-btn">
                <a title="Delete" className="mt-0 ms-2" href="#" onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    handleRemoveCard(event, data?.id)
                }}>
                    <i className="fa  fa-trash"></i>
                </a>
            </div>
        )
    }


    useEffect(() => {
        paymentListData()
    }, [EMPTY_ARRAY])


    const paymentListData = (page = 1, search = '', sortBy = '', sortOrder = '') => {
        setIsLoading(true)
        setSearch(search)
        dispatch(getCardDataList(page, search, sortBy, sortOrder)).then((res) => {
            console.log(res?.data?.payments, 'res?.data?.payments')
            setCardList(res?.data?.payments)
            setIsLoading(false)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    const handleInputFieldsData = (e) => {
        const { name, value } = e.target
        setPayload({ ...payload, [name]: value })
    }

    const handleCardChange = (value) => {
        setCard(value?.trim())
        payload['card_number'] = value?.trim()
        setPayload({ ...payload })
    }

    const handleExpiryChange = (value) => {
        setExpiry(value?.trim())
        payload['expiration_date'] = value?.trim()
        setPayload({ ...payload })
    }

    const handleRemoveCard = (e, item) => {
        e.preventDefault()
        setDeleteItem(item)
        setConfermationModal(true)
    }

    // Delete Vehicle item
    const handleDelete = () => {
        if (deleteItem) {
            setIsLoading(true)
            dispatch(removeCardData(deleteItem)).then(() => {
                setConfermationModal(false)
                paymentListData()
                setIsLoading(false)
            }).catch(() => {
                setIsLoading(false)
                setConfermationModal(false)
            })
        }
    }

    const handleSubmit = () => {
        setError(true)
        if (Object.values(payload)?.length > 0 && payload?.card_number && payload?.card_number?.length === 16 && payload?.expiration_date && payload?.security_code && payload?.zip_code_on_card) {
            const nonce = encryptionHandler(payload)

            // let formData = new FormData()    //formdata object
            // formData.append('nonce', nonce)   //append the values with key, value pair
            // formData.append('payment_type', 'credit_card')   //append the values with key, value pair
            // formData.append('is_auto_deduction', 0)   //append the values with key, value pair

            setIsLoading(true)
            setErrorMessage('')

            const finalPayload = { 'nonce': nonce, 'payment_type': 'credit_card', 'savePaymentMethod': true }
            dispatch(createCardData(finalPayload)).then((res) => {
                if (res?.data !== null) {
                    setPayload({ card_number: '', expiration_date: '' })
                    setCard('')
                    setExpiry('')
                    setIsEdit(false)
                    setModal(false)
                    setError(false)
                    paymentListData()
                } else if (res?.data === null && res?.errors?.message) {
                    setErrorMessage(res?.errors?.message)
                    setIsLoading(false)
                }
            })
        } else {
            setIsLoading(false)
            setErrorMessage('')
        }
    }

    const handleModalOpen = () => {
        setModal(true)
    }

    console.log('payload', payload)

    return (
        <div>
            <div className="d-flex mb-4">
                <h4 className="fw-bold">Payment Settings </h4>
                <button className="btn btn-primary ms-auto" onClick={handleModalOpen}><i className="fa fa-plus"></i> Add</button>
            </div>
            {isLoading ? <span className='ms-2 d-flex justify-content-center'><Circles
                height="50"
                width="50"
                color="var(--bs-primary)"
                ariaLabel="circles-loading"
                visible={true}
            /></span> :
                <div className="card mb-3">
                    <div className="card-header">  <b>Card List</b> </div>
                    <AdminListTablePage
                        data={cardList}
                        columns={columns}
                        getNewRecords={paymentListData}
                        curPage={current_page}
                        perPage={per_page}
                        total={total}
                        showSearch={false}
                        search={search}
                    />
                </div>}

            <AddCardModal isModalOpen={isModalOpen} setModal={setModal} payload={payload} modalName='Add New Card' isLoading={isLoading} handleCardChange={handleCardChange} handleExpiryChange={handleExpiryChange} handleSubmit={handleSubmit} expiry={expiry} card={card} errorMessage={errorMessage} isEdit={isEdit} error={error} setErrorMessage={setErrorMessage} setError={setError} setCard={setCard} setExpiry={setExpiry} handleInputFieldsData={handleInputFieldsData} />
            <ConfermationModal setConfermationModal={setConfermationModal} confermationModal={confermationModal} confermationHeading='Confirm Delete' confermationMessage='Are you sure you want to delete?' buttonLable='Delete' handleDelete={handleDelete} />
        </div>
    )
}

PaymentSetting.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    current_page: PropTypes.number,
    per_page: PropTypes.number,
    total: PropTypes.number,
}

PaymentSetting.defaulProps = {
    dispatch: noop,
    loader: PropTypes.bool,
}
function mapStateToProps({ cardDetailsReducer }) {
    const { current_page, per_page, total, loader } = cardDetailsReducer
    return {
        loader,
        current_page,
        per_page,
        total,
    }
}
export default connect(mapStateToProps)(PaymentSetting)