import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

function Header(props) {
    const { loggedInError} = props
  return (
    <section className="hero position-relative">
    
        
            <div className="container ">
                <div className="row justify-content-md-end">
                {loggedInError && <div className="alert alert-danger">
                    <strong>Danger!</strong> {loggedInError}.
                </div>}
                    <div className="col-sm-6 me-4">
                    <div className="p-3 topBox text-white">
                                <h1 >We Make Parking Easy</h1>
                                <p>
                                    Parking Offers the smooth and easy parking process, which helps you to
                                    book your monthly parking hassle free and easy.
                                </p>
                                {/* {page == 'thankyou' ? <a href="" className="btn btn-primary">Buy Now</a>: <a href="#forms" className="btn btn-primary">Buy Now</a>} */}
                            </div>
                        

                    </div>
                </div>
            </div>
        </section>
  )
}

Header.propTypes = {
	page: PropTypes.string,
	loggedInError: PropTypes.string,
}

Header.defaulProps = {
	page: undefined,
	loggedInError: undefined
}

function mapStateToProps({ login, paymentReducer }) {
	return {
		isAuthenticated: login?.isAuthenticated,
		userData: login?.data,
		permitData: paymentReducer?.data
	}
}
export default connect(mapStateToProps)(Header)
