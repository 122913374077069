import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
//import { CLIENT_SECRET, EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import { EMPTY_ARRAY, EMPTY_OBJECT, EXPIRY_MONTHS, EXPIRY_YEARS } from '../../constants/globalConstants' //, 

import { prorateList } from '../../redux/actions/paymentActions'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { Circles } from 'react-loader-spinner'
import { isLoggedIn, phoneMasker, restrictInputValues, validateCardNumber } from '../../utils/helper'
import moment from 'moment'
import { getCardDataList } from '../../redux/actions/cardDetailsAction'
import { getConfigDetails } from '../../services/config'
const cardnumbermask = createDefaultMaskGenerator('9999 9999 9999 9999')
//const expirymask = createDefaultMaskGenerator('99/99')

function Pass(props) {
    const { guidValue, resValue, setPage, idProof, rateList, dispatch, paymentLoader, formData, setFormData, formError } = props
    const [images, setImages] = useState(EMPTY_OBJECT)
    // const [formData, setFormData] = useState()
    const [facility, setFacility] = useState(EMPTY_OBJECT)
    const [prorateResponse, setProrateResponse] = useState(EMPTY_OBJECT)
    const [refractedVehicleList, setRefractedVehicleList] = useState(EMPTY_ARRAY)
    const [card, setCard] = useState()
    const [couponError, setCouponError] = useState('')
    const [coupon, setCoupon] = useState('')
    //const [expiry, setExpiry] = useState()
    //const [phone, setPhone] = useState('')
    // const [name, setName] = useState('')
    // const [email, setEmail] = useState('')
    //const [cvv, setCvv] = useState('')
    // const [expiryMonth, setExpiryMonth] = useState('')
    // const [expiryYear, setExpiryYear] = useState('')
    const [couponRemoveButton, setCouponRemoveButton] = useState()
    const [isCouponApplied, setIsCouponApplied] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isCardFormVisible, setCardFormVisible] = useState(false)
    const [cardList, setCardList] = useState(EMPTY_ARRAY)

    // console.log(expiryMonth,expiryYear)
    useEffect(() => {
        // setPhone('')
        localStorage.setItem('name', '')
        localStorage.setItem('email', '')
        localStorage.setItem('phone', '')
        localStorage.setItem('card_date_expiry_month', '')
        localStorage.setItem('card_date_expiry_year', '')
        localStorage.setItem('card_pan', '')
        localStorage.setItem('CardNumberLast4', '')


        const facility = JSON.parse(localStorage.getItem('facility'))
        const permits = JSON.parse(localStorage.getItem('permits'))
        const finalVehicleList = formData?.vehicleList?.filter((item) => (item?.license_plate === '' && item?.make_model === '') ? null : item)
        setRefractedVehicleList(finalVehicleList)

        const payLoadData = {
            facility_id: facility?.id,
            driving_license: formData?.license_number,
            permit_rate_id: permits?.id
        }
        dispatch(prorateList(payLoadData)).then((res) => {
            setIsLoading(false)
            setIsCouponApplied(false)
            formData['permit_rate'] = res?.data?.total_amount
            formData['coupon_applied'] = false
            setFormData({ ...formData })
            setProrateResponse(res?.data)
        })

        if (isLoggedIn()) {
            setCardFormVisible(false)
            dispatch(getCardDataList()).then((res) => {
                if (res?.data) {
                    setCardList(res?.data?.payments)
                    setIsLoading(false)
                }
            }).catch(() => {
                setIsLoading(false)
            })
        } else {
            setCardFormVisible(true)
        }
    }, [EMPTY_ARRAY])

    // const handleInputChange = (e) => {
    // 	const {name, value} = e.target

    // 	if(name == 'card_date_expiry_month'){
    // 		setExpiryMonth(value)
    // 	}
    // 	if(name == 'card_date_expiry_year'){
    // 		setExpiryYear(value)
    // 	}

    // 	localStorage.setItem([name], value.trim())

    // }

    const handleCardChange = (value) => {
        setCard(value?.trim())
        formData['card_pan'] = value?.trim()
        setFormData({ ...formData })
    }
    // const handleExpiryChange = (value) => {
    // 	setExpiry(value?.trim())
    //     formData['expiration_date'] = value?.trim()
    // 	setFormData({...formData})
    // }

    const handleCardSelectChange = (e) => {
        const { value } = e.target
        const selectedCard = cardList?.filter((card) => card?.token == value)

        setFormData({ ...formData, ['payment_profile_id']: selectedCard[0]?.token })

    }

    const showCardForm = () => {
        setCardFormVisible(true)
        setFormData({ ...formData, ['payment_profile_id']: '' })
    }

    const hideCardForm = () => {
        setCardFormVisible(false)
        setFormData({ ...formData, ['payment_profile_id']: '' })
    }

    useEffect(() => {
        setPage('pass')

        const facility = JSON.parse(localStorage.getItem('facility'))
        setFacility(facility)
        if (idProof?.front || idProof?.back) {
            const front = idProof?.front ? URL.createObjectURL(idProof?.front) : ''
            const back = idProof?.back ? URL.createObjectURL(idProof?.back) : ''

            setImages({ ...images, front: front, back: back })
        }


        if (rateList?.permit_rate?.length > 0) {
            const selRate = rateList?.permit_rate?.filter((item) => item?.id === formData?.permit_rate_id)
            formData['permit_rate'] = selRate[0]?.rate
            formData['permit_rate_id'] = selRate[0]?.id

            setFormData({ ...formData })
        }

        delete formData.name
        delete formData.card_pan
        delete formData.card_date_expiry_month
        delete formData.card_date_expiry_year
        delete formData.card_card_security_cvx_2
        delete formData.zip_code

    }, [EMPTY_ARRAY])

    const handleInputFieldsData = (e, i, perItem) => {
        const { name, value } = e.target
        if (name == 'permit_rate') {
            formData['permit_rate'] = perItem?.rate
            formData['permit_rate_id'] = perItem?.id
            setFormData({ ...formData })
            setFormData({ ...formData, ['permit_rate']: value })
            const facility = JSON.parse(localStorage.getItem('facility'))
            const proRatePayload = {
                facility_id: facility?.id,
                driving_license: formData?.license_number,
                permit_rate_id: perItem?.id
            }
            dispatch(prorateList(proRatePayload)).then((res) => {
                setIsCouponApplied(false)
                formData['permit_rate'] = perItem?.rate
                formData['permit_rate_id'] = perItem?.id
                formData['coupon_applied'] = false
                setFormData({ ...formData })
                setProrateResponse(res?.data)
            })
        } else if (name == 'promocode') {
            setCoupon(value)
        }
        setFormData({ ...formData, [name]: value })
    }
    // const handlePhoneChange = (value) => {
    // 	setPhone(value.trim())
    // 	localStorage.setItem('phone', value.trim())
    // }
    const handleConsentChange = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }
    const handleCouponRemove = () => {
        setCouponError('')
        setCoupon('')
        setCouponRemoveButton(false)
        const facility = JSON.parse(localStorage.getItem('facility'))
        const permits = JSON.parse(localStorage.getItem('permits'))

        const payLoadData = {
            facility_id: facility?.id,
            driving_license: formData?.license_number,
            permit_rate_id: permits?.id
        }
        dispatch(prorateList(payLoadData)).then((res) => {
            setCouponError('')
            setIsCouponApplied(false)
            setProrateResponse(res?.data)
            formData['permit_rate'] = res?.data?.total_amount
            formData['coupon_applied'] = false
            formData['promocode'] = ''
            setFormData({ ...formData })
        })
    }
    const handleCouponCode = () => {
        if (coupon?.trim()) {
            setCouponRemoveButton(true)
            setCouponError('')
            setIsLoading(true)
            setIsCouponApplied(true)
            setCoupon(coupon)
            const facility = JSON.parse(localStorage.getItem('facility'))
            const permits = JSON.parse(localStorage.getItem('permits'))
            const proRate = {
                facility_id: facility?.id,
                driving_license: formData?.license_number,
                permit_rate_id: permits?.id,
                promocode: coupon,
                client_id: getConfigDetails()?.CLIENT_SECRET,
                email: formData?.email
            }
            dispatch(prorateList(proRate)).then((res) => {
                if (res?.data) {
                    setIsLoading(false)
                    setIsCouponApplied(true)
                    formData['permit_rate'] = res?.data?.total_amount
                    formData['coupon_applied'] = true
                    setFormData({ ...formData })
                    setProrateResponse(res?.data)
                } else {
                    setIsLoading(false)
                    setCouponError(res?.errors?.message)
                    const facility = JSON.parse(localStorage.getItem('facility'))
                    const permits = JSON.parse(localStorage.getItem('permits'))

                    const payLoadData = {
                        facility_id: facility?.id,
                        driving_license: formData?.license_number,
                        permit_rate_id: permits?.id
                    }
                    dispatch(prorateList(payLoadData)).then((res) => {
                        setIsCouponApplied(false)
                        formData['permit_rate'] = res?.data?.total_amount
                        formData['coupon_applied'] = false
                        setFormData({ ...formData })
                        setProrateResponse(res?.data)
                    })

                }

            }).catch((error) => {
                setCouponError(error?.errors?.message)
                setCoupon('')
                setCouponRemoveButton(false)
                const facility = JSON.parse(localStorage.getItem('facility'))
                const permits = JSON.parse(localStorage.getItem('permits'))

                const payLoadData = {
                    facility_id: facility?.id,
                    driving_license: formData?.license_number,
                    permit_rate_id: permits?.id
                }
                dispatch(prorateList(payLoadData)).then((res) => {
                    setIsCouponApplied(false)
                    formData['permit_rate'] = res?.data?.total_amount
                    formData['coupon_applied'] = false
                    setFormData({ ...formData })
                    setProrateResponse(res?.data)
                })
                setIsLoading(false)
                setIsCouponApplied(false)
                formData['coupon_applied'] = false

            })
        } else {
            setCouponError('Please enter a valid promocode')
            setCoupon('')
            setCouponRemoveButton(false)
            const facility = JSON.parse(localStorage.getItem('facility'))
            const permits = JSON.parse(localStorage.getItem('permits'))

            const payLoadData = {
                facility_id: facility?.id,
                driving_license: formData?.license_number,
                permit_rate_id: permits?.id
            }
            dispatch(prorateList(payLoadData)).then((res) => {
                setIsCouponApplied(false)
                formData['permit_rate'] = res?.data?.total_amount
                formData['coupon_applied'] = false
                setFormData({ ...formData })
                setProrateResponse(res?.data)
            })
            setIsLoading(false)
            setIsCouponApplied(false)
            formData['coupon_applied'] = false

        }

    }

    return (
        <div>
            <h4 className="mb-4 text-center">Confirm Your Details</h4>
            <div className="card">
                <div className="row g-0">
                    <div className="col-sm-6 border-end">
                        <div className="card-header fw-bold">
                            Basic Information
                        </div>
                        <div className="card-body">
                            <p className="mb-1"><b>Name :</b>  {formData?.firstname} {formData?.lastname} </p>
                            <p className="mb-1"><b>Email :</b>  {formData?.email}</p>
                            <p className="mb-0"><b>Phone Number :</b> {phoneMasker(formData?.phone)}</p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="card-header">
                            <div className="d-flex">
                                <div className="fw-bold">Driver License Number : </div>
                                <p className="m-0 align-items-end ms-auto"><span className="badge badgeColor">{formData?.resident}</span> {formData?.license_number}</p>
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col text-center ">
                                    <b>Drivers License Proof (Front)</b>
                                    {images?.front ? <div className="border  p-1 text-center">
                                        <img height="46px" src={images?.front} />
                                    </div> : <div className="p-1 text-center">-</div>}
                                </div>
                                <div className="col text-center ">
                                    <b>Drivers License Proof (Back)</b>
                                    {images?.back ? <div className="border  p-1 text-center">
                                        <img height="46px" src={images?.back} />
                                    </div> : <div className="p-1 text-center">-</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg-primary p-2 bg-primary bg-opacity-10">
                <div className="row ">
                    <div className="col-12 d-flex">
                        <table className='w-100'>

                            {refractedVehicleList?.map((vehicle, i) => {
                                return (
                                    <tr className='row' key={i}>
                                        <td className='col'><b>License plate number :</b> {vehicle?.license_plate ? vehicle?.license_plate : '-'}</td>
                                        <td className='col'><b> Make / Model:</b> {(vehicle?.make && vehicle?.model) ? `${vehicle?.make}/${vehicle?.model}` : '-'}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
            </div>
            <div className="bg-white border p-3  my-2">
                <div className='row align-items-center' >
                    <div className='col-8'>
                        <h4 className="text-primary">{facility?.full_name}</h4>
                        <p className="m-0"><b>Entrance Address: </b>{`${facility?.geolocations?.address_1}${facility?.geolocations?.address_2 ? `, ${facility?.geolocations?.address_2}` : ''}${facility?.geolocations?.city ? `, ${facility?.geolocations?.city}` : ''}${facility?.geolocations?.state ? `, ${facility?.geolocations?.state}` : ''}${facility?.geolocations?.zip_code ? `, ${facility?.geolocations?.zip_code}` : ''}`}</p>
                        {/* <p className="m-0"><b>Entrance Address : </b>{`${facility?.geolocations?.address_1}${facility?.geolocations?.address_2 ? ', '+facility?.geolocations?.address_2 : ''}${facility?.geolocations?.city ? ', '+facility?.geolocations?.city : ''}${facility?.geolocations?.state ? ', '+facility?.geolocations?.state : ''}${facility?.geolocations?.zip_code}`}</p> */}
                        {/* <p className="m-0"><b>Entrance Address: </b> {`${facility?.geolocations?.address_1} ${facility?.geolocations?.address_2? ',' : ''} ${facility?.geolocations?.city? ',' : ''} ${facility?.geolocations?.state? ',' : ''} ${facility?.geolocations?.zip_code}`}</p> */}

                    </div>
                    <div className='col-4'>
                        {rateList?.permit_rate?.map((permitPricesItem, permitPricesIndex) => {
                            return (
                                <>
                                    <div className=' p-2 ' key={permitPricesIndex}>
                                        <input type="radio" className="btn-check" name="permit_rate" id={`btnradio${permitPricesIndex + 1}`} autoComplete="off" onClick={(e) => handleInputFieldsData(e, permitPricesIndex, permitPricesItem)} checked />
                                        {/* checked={(permitPricesItem?.id === formData?.permit_rate_id)} */}
                                        <label className=" position-relative w-100 text-end" htmlFor={`btnradio${permitPricesIndex + 1}`}>
                                            <h2 className="fw-bold mb-0 mainColor">{permitPricesItem?.rate ? `$${permitPricesItem?.rate}` : ''}</h2>
                                            <p className="mb-0 mainColor">{permitPricesItem?.name}</p>
                                            {/* <span>{permitPricesIndex + 1}</span> */}
                                        </label>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>

            </div>

            <div className="border mb-3 p-3">

                {/* {dynamic permit} */}



                <div className="block">
                    <div className="row">
                        <div className="col-sm-6">

                            <label htmlFor="promocode" className="form-label">Promo Code</label>

                            <div className="input-group position-relative">
                                <input
                                    autoComplete="off"
                                    className="form-control "
                                    placeholder="Enter Promo Code"
                                    name="promocode"
                                    value={coupon}
                                    onChange={handleInputFieldsData}
                                    type="text"
                                    required=""
                                />
                                <button type="button" className="btn btn-primary d-flex" onClick={(e) => handleCouponCode(e)}>Apply  {isLoading ? <span className=' ms-2'><Circles
                                    height="20"
                                    width="20"
                                    color="#ffffff"
                                    ariaLabel="circles-loading"
                                    visible={true}
                                /></span> : null}
                                </button>
                                {couponRemoveButton && <span className="btn floatBtnCross" id="basic-addon1" onClick={handleCouponRemove}><i className='fa fa-times text-danger'></i></span>}
                            </div>
                        </div>


                        <div className='errorBlock d-inline-block'>{couponError == 'loading' ? <span className=' ms-2'><Circles
                            height="20"
                            width="20"
                            color="var(--bs-primary)"
                            ariaLabel="circles-loading"
                            visible={true}
                        /></span> : <small className='text-danger'>{couponError}</small>}
                        </div>



                    </div>

                </div>
                <div className="card ">
                    <div className="card-header">
                        <b> Purchase Summary</b>
                    </div>
                    {paymentLoader && coupon ? <span className='m-5 d-flex justify-content-center'><Circles
                        height="40"
                        width="40"
                        color="var(--bs-primary)"
                        ariaLabel="circles-loading"
                        visible={true}
                    /></span> : <div className="card-body">
                        <div className="row align-items-center g-5">
                            <div className="col-12 col-sm-6">
                                <table className="table table-sm table-borderless">
                                    <tbody><tr>
                                        <th>
                                            Price
                                        </th>
                                        <td>:</td>
                                        <td className='text-end'>
                                            <span>{prorateResponse?.effective_pro_rate ? `$${prorateResponse?.effective_pro_rate} (Prorated price)` : ''}</span>
                                        </td>
                                    </tr>
                                        {(prorateResponse?.processing_fee && prorateResponse?.processing_fee > 0) && <tr>
                                            <th>
                                                Processing Fee
                                            </th>
                                            <td>:</td>
                                            <td className='text-end'>
                                                <span>{(prorateResponse?.processing_fee && prorateResponse?.processing_fee > 0) ? `+ $${prorateResponse?.processing_fee}` : ''}</span>
                                            </td>
                                        </tr>}
                                        <tr>
                                            <th>
                                                Start Date
                                            </th>
                                            <td>:</td>
                                            <td className='text-end'>
                                                <span>{prorateResponse?.start_date ? `${moment(prorateResponse?.start_date, 'MM-DD-YYYY').format('MM/DD/YYYY')}` : ''}</span>
                                            </td>
                                        </tr><tr>
                                            <th>
                                                End Date
                                            </th>
                                            <td>:</td>
                                            <td className='text-end'>
                                                <span>{prorateResponse?.end_date ? `${moment(prorateResponse?.end_date, 'MM-DD-YYYY').format('MM/DD/YYYY')}` : ''}</span>
                                            </td>
                                        </tr>
                                        {(isCouponApplied && prorateResponse?.promocode_discount_amount) ?
                                            <tr>
                                                <th>
                                                    Promo Code Discount
                                                </th>
                                                <td>:</td>
                                                <td className='text-end'>
                                                    <span className="text-danger">{prorateResponse?.promocode_discount_amount ? `- $${prorateResponse?.promocode_discount_amount}` : ''}</span>
                                                </td>
                                            </tr> : ''}


                                    </tbody></table>


                            </div>

                            <div className="col text-end">

                                <h3 className="fw-bold m-0">Total : {prorateResponse?.total_amount ? `$${prorateResponse?.total_amount}` : ''}</h3>

                                {/* <h3 className="fw-bold m-0">Total : {prorateResponse?.total_amount ? `$${prorateResponse?.total_amount}` : ''}</h3> */}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="mt-4" id="accountExpand">
                    <b className="mt-0" style={{ fontSize: 'larger' }}>Payment Details</b>
                    <hr className="my-1" />

                    {isLoggedIn() &&
                        <div className='row '>
                            {cardList?.length > 0 && <div className="col-sm-6">
                                <div className="mb-3">
                                    <select name="vehicle" id="vehicle" disabled={isCardFormVisible} className="form-select" onChange={handleCardSelectChange} >
                                        <option>Select Card</option>
                                        {cardList?.map((card, index) => {
                                            return (
                                                <option value={card?.token} selected={card?.token == formData?.payment_profile_id} key={index}>{`XXXX XXXX XXXX ${card?.card_last_four.slice(0, 4)} (${card?.expiry.slice(0, 2)}/${card?.expiry.slice(2 ,4)})`}</option>
                                            )
                                        })}
                                    </select>
                                    <span className="form-text text-danger h-40">{formError == 'pass' && (!formData?.payment_profile_id && !formData?.card_pan) ? 'Please select or add new card' : ''}</span>
                                </div>
                            </div>}

                            {cardList?.length > 0 && !isCardFormVisible ? <div className='col-sm-6 mb-3'>{cardList?.length > 0 && <span className='badge badge-primary text-dark fw-bold me-2'>OR</span>}<button className='btn btn-primary' type="button" onClick={showCardForm}><i className='fa fa-plus'></i> Add New Card</button></div> : cardList?.length > 0 && <div className='col-sm-6 mb-3'><span className='badge badge-primary text-dark fw-bold me-2'>OR</span>  <button className='btn btn-danger' type="button" onClick={hideCardForm}>Select Existing Card</button></div>}

                        </div>}

                    {(cardList?.length < 1 || isCardFormVisible) && <div>
                        <div className="row">
                            <div className="col-sm-6 ">
                                <div className="mb-3">
                                    <label htmlFor="Name on Card" className="form-label">Name on Card <span className='text-danger'> *</span></label>
                                    <input
                                        onChange={handleInputFieldsData}
                                        autoComplete="off"
                                        name='name'
                                        className="form-control "
                                        maxLength={20}
                                        minLength={1}
                                        placeholder="Name on Card"
                                        type="text"
                                        required="" />
                                    <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.name ? 'This field is required' : ''}</span>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="Card Number" className="form-label">Card Number <span className='text-danger'> *</span>
                                    </label>
                                    <MaskedInput
                                        maskGenerator={cardnumbermask}
                                        value={card}
                                        type="text"
                                        name="card_pan"
                                        id="card_pan"
                                        required={true}
                                        pattern="[0-9 ]{18,19}"
                                        maxLength='19'
                                        minLength='18'
                                        title="Card no. should be 15-16 digit numeric"
                                        className="form-control"
                                        onChange={handleCardChange}
                                        placeholder="Card Number"
                                    />
                                    <span className="form-text text-danger h-40">{formError == 'pass' ? !formData?.card_pan ? 'This field is required' : validateCardNumber(formData?.card_pan) ? '' : '' : ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4 col-6 mb-3">
                                <label htmlFor="Expiry Month" className="form-label">Expiry Month <span className='text-danger'> *</span></label>
                                <select
                                    name='card_date_expiry_month'
                                    id="card_date_expiry_month"
                                    required=""
                                    className="form-select"
                                    onChange={handleInputFieldsData}
                                >
                                    <option value="">Expiry Month</option>
                                    {EXPIRY_MONTHS.map((item, key) => <option key={key} value={item}>{item}</option>)}
                                </select>
                                <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.card_date_expiry_month ? 'This field is required' : ''}</span>
                            </div>

                            <div className="col-sm-4 col-6 mb-3">
                                <label htmlFor="Expiry Year" className="form-label">Expiry Year<span className='text-danger'> *</span></label>

                                <select
                                    name='card_date_expiry_year'
                                    id='expiryYear'
                                    required=""
                                    className="form-select"
                                    onChange={handleInputFieldsData}
                                >
                                    <option value="">Expiry Year</option>
                                    {EXPIRY_YEARS.map((item, key) => <option key={key} value={item.val}>{item.label}</option>)}
                                </select>
                                <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.card_date_expiry_year ? 'This field is required' : ''}</span>

                            </div>

                            <div className="col-sm-4">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">CVV <span className='text-danger'> *</span></label>
                                    <input
                                        onChange={handleInputFieldsData}
                                        autoComplete="off"
                                        className="form-control"
                                        id="standardCardCvv"
                                        maxLength="4"
                                        name="card_card_security_cvx_2"
                                        placeholder="CVV / Security Code"
                                        type="password"
                                        onKeyPress={(e) => restrictInputValues(e)}

                                        required=""
                                    />
                                    <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.card_card_security_cvx_2 ? 'This field is required' : ''}</span>
                                </div>
                            </div></div>
                        <div className="row">

                            <div className="col-sm-4">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Zip Code <span className='text-danger'> *</span></label>
                                    <input
                                        onChange={handleInputFieldsData}
                                        className="form-control ng-untouched ng-pristine ng-invalid"
                                        maxLength="6"
                                        minLength="5"
                                        name="zip_code"
                                        pattern="[0-9]{5,6}"
                                        placeholder="Billing Address Zip Code"
                                        type="text"
                                        required=""
                                    />
                                    <span className="form-text text-danger h-40">{formError == 'pass' && !formData?.zip_code ? 'This field is required' : ''}</span>
                                </div>
                            </div>
                        </div>


                        <input type='hidden' name='XXX_IPG_XXX' id='XXX_IPG_XXX' value='confirm' />
                        <input type='hidden' name='XXX_IPGTRXNO_XXX' id='XXX_IPGTRXNO_XXX' value={guidValue} />
                        <input type='hidden' name='XXX_IPGSESSION_XXX' id='XXX_IPGSESSION_XXX' value={resValue}></input>
                    </div>
                    }
                </div>
                <div className=' my-2' >
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="userConsent" name="userConsent" onClick={(e) => handleConsentChange(e)} checked={formData?.userConsent} />
                        <small>By clicking this button, you are providing your consent for auto renewal of the permit.</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

Pass.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    events: PropTypes.object,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: PropTypes.string,
    idProof: PropTypes.object,
    rateList: PropTypes.object,
    paymentLoader: PropTypes.bool,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    formError: PropTypes.string,
    guidValue: PropTypes.string,
    resValue: PropTypes.string,

}

Pass.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    events: EMPTY_OBJECT,
    loader: PropTypes.bool,
    getCard: PropTypes.func,
    passData: EMPTY_ARRAY,
    setPage: undefined,
    idProof: EMPTY_OBJECT,
    rateList: EMPTY_OBJECT,
    paymentLoader: false,
    formData: EMPTY_OBJECT,
    setFormData: noop,
    formError: undefined,
    guidValue: '',
    resValue: ''

}

function mapStateToProps({ paymentReducer, basicInfoReducer }) {
    return {
        data: paymentReducer,
        paymentLoader: paymentReducer?.proRateLoader,
        rateList: basicInfoReducer.rateList.data[0]
    }
}
export default connect(mapStateToProps)(Pass)