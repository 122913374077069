import React, { Component } from 'react'
import './carousel.css'

class CarouselItems extends Component {
  
  componentDidMount = () => {
  }

  render() {
    return (
      <div className="carousel hvh" >
        <div id="carouselExampleIndicators" className="carousel slide h-100" data-bs-ride="carousel">
          <div className="carousel-inner h-100">
          <section className="hero position-relative sidebar">
    
        
    <div className="container">
        <div className="row justify-content-md-end">
       
            <div className="col-sm-6 me-4">
            <div className="p-3 topBox text-white">
                        <h1 >We Make Parking Easy</h1>
                        <p>
                            Parking Offers the smooth and easy parking process, which helps you to
                            book your monthly parking hassle free and easy.
                        </p>
                        {/* {page == 'thankyou' ? <a href="" className="btn btn-primary">Buy Now</a>: <a href="#forms" className="btn btn-primary">Buy Now</a>} */}
                    </div>
                

            </div>
        </div>
    </div>
</section>
          </div>
        </div>
      </div>
    )
  }
}

export default CarouselItems