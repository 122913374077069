import React, { useEffect, useState } from 'react'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/globalConstants'
// import { useNavigate } from 'react-router-dom'
// useParams
// import URL from '../../../constants/urls'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import Receipt from '../Receipt/Receipt'
import { Circles } from 'react-loader-spinner'
import TicketModal from '../../../constants/TicketModal'



function TicketList(props) {

    const { loader, ticketList, notify } = props
    const [activeReceipt, setActiveReceipt] = useState(EMPTY_ARRAY)
    const [inactiveReceipt, setInActiveReceipt] = useState(EMPTY_ARRAY)
    const [ticketModal, setTicketModal] = useState(false)
    const [selItem, setSelectedItem] = useState(EMPTY_OBJECT)
    // const navigate = useNavigate()
    // let { partner } = useParams()

    const handleDetails = (item) => {
        setSelectedItem(item)
        setTicketModal(!ticketModal)
        // localStorage.setItem('paymentRecipt', JSON.stringify(item))
        // navigate(`${URL?.TICKET_VIEW}/${item?.ticket_number}`)
    }

    useEffect(() => {
        let active = []
        let inactive = []

        ticketList?.data?.map((val) => {
            if (val?.is_expired || val?.is_checkout == '1' || val?.is_closed == '1')
                inactive.push(val)
            else
                active.push(val)
        })
        setActiveReceipt(active)
        setInActiveReceipt(inactive)
    }, [ticketList])


    return (
        <div className='Pave'>
            {(loader && ticketList?.length < 1) ?
                <div className='d-flex align-items-center justify-content-center vh-70'><span className='ms-2 d-flex justify-content-center'><Circles
                    height="50"
                    width="50"
                    color="var(--bs-primary)"
                    ariaLabel="circles-loading"
                    visible={true}
                /></span></div> : (ticketList && ticketList?.data?.length) > 0 && <div>
                    {(activeReceipt && activeReceipt.length > 0) && <div>
                        <div className='bg-second h-100 pb-2 pt-1'><span className='small text-primary ms-2 mt-2'>Active Parking Sessions</span></div>
                        <div className='border-0'>
                            <div className='ms-2 me-2'>
                                {activeReceipt && activeReceipt?.map((value, index) => {
                                    return (
                                        <Receipt handleDetails={handleDetails} receipt={value} key={index} notify={notify} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>}
                    {(inactiveReceipt && inactiveReceipt.length > 0) && <div>
                        <div className='bg-second h-100 pb-2 pt-1'><span className='small text-primary ms-2 mt-2'>Past Parking Sessions</span></div>
                        <div className='border-0'>
                            <div className='ms-2 me-2'>
                                {inactiveReceipt && inactiveReceipt.map((value, index) => {
                                    return (
                                        <Receipt handleDetails={handleDetails} receipt={value} key={index} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>}
                </div>}
            <TicketModal setTicketModal={setTicketModal} ticketModal={ticketModal} selItem={selItem} />
        </div>
    )
}

TicketList.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    ticketList: PropTypes.array
}

TicketList.defaulProps = {
    dispatch: noop,
    loader: true,
    notify: noop,
    ticketList: EMPTY_ARRAY
}

function mapStateToProps({ ReceiptsReducer }) {
    return {
        loader: ReceiptsReducer?.loader
    }
}
export default connect(mapStateToProps)(TicketList)