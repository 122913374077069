import CONSTANTS from '../constants'
import { postRequest, } from '../../services'
import { APICONFIG } from '../config/apiConfig'


//PERMIT BOOKING
// export const bookPermit = (postData) => (dispatch) => {
// 	dispatch({ type: CONSTANTS.BOOKING_REQUEST })
// 	return postRequest(APICONFIG.getBookingUrl, postData).then((res) => {
// 		dispatch({ type: CONSTANTS.BOOKING_SUCCESS, data: res })
// 		return res
// 	}).catch((err) => {
// 		dispatch({ type: CONSTANTS.BOOKING_FAILURE })
// 		return err
// 	})
// }

//PERMIT BOOKING
export const bookPermit = (postData) => (dispatch) => {
	dispatch({ type: CONSTANTS.BOOKING_REQUEST })
	return postRequest(APICONFIG.getAreBookingUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.BOOKING_SUCCESS, data: res })
		return res
	}).catch((err) => {
		dispatch({ type: CONSTANTS.BOOKING_FAILURE })
		return err
	})
}

//Prorated API
// export const prorateList = (data) => async (dispatch) => {
// 	dispatch({ type: CONSTANTS.GET_PRO_RATE_LIST_REQUEST })
// 	try {
// 		const res = await postRequest(APICONFIG.getProRateListUrl, data)
// 		dispatch({ type: CONSTANTS.GET_PRO_RATE_LIST_SUCCESS, data: res })
// 		return res
// 	} catch (error) {
// 		dispatch({ type: CONSTANTS.GET_PRO_RATE_LIST_FAILURE , errorMessage: error})
// 		return error
// 	}
// }

//Prorated API
export const prorateList = (data) => async (dispatch) => {
	dispatch({ type: CONSTANTS.GET_PRO_RATE_LIST_REQUEST })
	try {
		const res = await postRequest(APICONFIG.getAreProRateListUrl, data)
		dispatch({ type: CONSTANTS.GET_PRO_RATE_LIST_SUCCESS, data: res })
		return res
	} catch (error) {
		dispatch({ type: CONSTANTS.GET_PRO_RATE_LIST_FAILURE, errorMessage: error })
		return error
	}
}

//PASS BOOKING
export const bookPass = (postData) => async (dispatch) => {
	dispatch({ type: CONSTANTS.PASS_BOOKING_REQUEST })
	return postRequest(APICONFIG.getArePassUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.PASS_BOOKING_SUCCESS, data: res })
		return res
	}).catch((err) => {
		dispatch({ type: CONSTANTS.PASS_BOOKING_FAILURE })
		return err
	})
}

//payment Permit Session API

export const  getPaymentInitials= (postData) => async (dispatch) => {
	dispatch({ type: CONSTANTS.PAYMENT_INITIAL_REQUEST })
	let endPointUrl
	if(postData?.type && postData?.type == 'booking'){
		endPointUrl = APICONFIG.getPaymentSessionUrl
	}else{
		endPointUrl = APICONFIG.getPaymentInitialUrl
	}

	return postRequest(endPointUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.PAYMENT_INITIAL_SUCCESS, data: res })
		return res
	}).catch((err) => {
		dispatch({ type: CONSTANTS.PAYMENT_INITIAL_FAILURE })
		return err
	})
}

// Calls the API to booking before payment
export const saveBookingBeforePayment = (postData) => async (dispatch) => {
	dispatch({ type: CONSTANTS.BEFORE_PAYMENT_INITIAL_REQUEST })
	return postRequest(APICONFIG.saveBookingBeforePaymentUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.BEFORE_PAYMENT_INITIAL_SUCCESS, data: res })
		return res
	}).catch((error) => {
		dispatch({ type: CONSTANTS.BEFORE_PAYMENT_INITIAL_FAILURE, errorMessage : error?.errors?.message})
		return error
	})
}

// Calls the API to booking before payment
export const saveBookingAfterPayment = (postData) => (dispatch) => {
	dispatch({ type: CONSTANTS.AFTER_PAYMENT_INITIAL_REQUEST })
	return postRequest(APICONFIG.saveBookingafterPaymentUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.AFTER_PAYMENT_INITIAL_SUCCESS, data: res })
		return res
	}).catch((error) => {
		dispatch({ type: CONSTANTS.AFTER_PAYMENT_INITIAL_FAILURE })
		return error
	})
}

//payment Pass Session API

export const  getPassPaymentInitials= (postData) => async (dispatch) => {
	dispatch({ type: CONSTANTS.PASS_PAYMENT_INITIAL_REQUEST })
	return postRequest(APICONFIG.getPassPaymentInitialUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.PASS_PAYMENT_INITIAL_SUCCESS, data: res })
		return res
	}).catch((err) => {
		dispatch({ type: CONSTANTS.PASS_PAYMENT_INITIAL_FAILURE })
		return err
	})
}

// Calls the API to Pass booking before payment
export const savePassBookingBeforePayment = (postData) => async (dispatch) => {
	dispatch({ type: CONSTANTS.BEFORE_PASS_PAYMENT_INITIAL_REQUEST })
	return postRequest(APICONFIG.savePassBookingBeforePaymentUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.BEFORE_PASS_PAYMENT_INITIAL_SUCCESS, data: res })
		return res
	}).catch((error) => {
		dispatch({ type: CONSTANTS.BEFORE_PASS_PAYMENT_INITIAL_FAILURE })
		return error
	})
}

// Calls the API to Pass booking after payment
export const savePassBookingAfterPayment = (postData) => (dispatch) => {
	dispatch({ type: CONSTANTS.AFTER_PASS_PAYMENT_INITIAL_REQUEST })
	return postRequest(APICONFIG.savePassBookingafterPaymentUrl, postData).then((res) => {
		dispatch({ type: CONSTANTS.AFTER_PASS_PAYMENT_INITIAL_SUCCESS, data: res })
		return res
	}).catch((error) => {
		dispatch({ type: CONSTANTS.AFTER_PASS_PAYMENT_INITIAL_FAILURE })
		return error
	})
}

export const permitTokenPayment = (payload) => (dispatch) => {
	dispatch({ type: CONSTANTS.PERMIT_TOKEN_PAYMENT_REQUEST })
	return postRequest(APICONFIG.permitTokenPaymentUrl, payload).then((response) => {
		dispatch({ type: CONSTANTS.PERMIT_TOKEN_PAYMENT_SUCCESS, data: response?.data?.Response })
		return response?.data?.Response
	}).catch((error) => {
		dispatch({ type: CONSTANTS.PERMIT_TOKEN_PAYMENT_FAILURE })
		return error
	})
}

export const passTokenPayment = (payload) => (dispatch) => {
	dispatch({ type: CONSTANTS.PASS_TOKEN_PAYMENT_REQUEST })
	return postRequest(APICONFIG.userPassTokenPaymentUrl, payload).then((response) => {
		dispatch({ type: CONSTANTS.PASS_TOKEN_PAYMENT_SUCCESS, data: response?.data?.Response })
		return response?.data?.Response
	}).catch((error) => {
		dispatch({ type: CONSTANTS.PASS_TOKEN_PAYMENT_FAILURE })
		return error
	})
}