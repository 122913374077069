import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT, vehicleArray } from '../../../constants/globalConstants'
import { DeleteVehicleList, getVehicleDataList, postVehicleList } from '../../../redux/actions/VehicleListAction'
import { getUserProfile } from '../../../redux/actions/login'
import VehicleModal from '../../../components/global/VehicleModal'
import { Circles } from 'react-loader-spinner'
import ConfermationModal from '../../../components/global/ConfermationModal'
import { renderNoResults } from '../../../components/global/NoDataFound'

function VehicleDetails(props) {
    const { dispatch, loader ,setVehicleCount} = props

    const [vehicleData, setVehicleData] = useState(vehicleArray)
    const [getVehicleList, setGetVehicleList] = useState(EMPTY_ARRAY)
    const [isModalOpen, setModal] = useState(false)
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false)

    // for confermation pop-up

    const [confermationModal, setConfermationModal] = useState(false)
    const [deleteItem, setDeleteItem] = useState(EMPTY_ARRAY)
    useEffect(() => {
        dispatch(getVehicleDataList()).then((res) => {
            
            setGetVehicleList(res?.data)
            setVehicleCount(res?.data?.length)
        })
    }, [EMPTY_ARRAY])

    // useEffect(() => {
    //     dispatch(getVehicleDataList()).then((res) => setGetVehicleList(res?.data))
    // }, [EMPTY_ARRAY])


    const handleSubmitVehicle = () => {
       // console.log('coming vehical dd')
       // e.preventDefault()
        const newPyalod = vehicleData?.filter((item) => (item?.license_plate === '') ? null : item)
        const finalPayload = {
            vehicleList: newPyalod
        }
        console.log('coming vehical length',vehicleData)
        if ((newPyalod?.length > 0 && newPyalod?.[0]?.license_plate != '' && newPyalod?.[0]?.license_plate?.length >= 3 && newPyalod?.[0]?.license_plate?.length <= 10 ) && !submitButtonDisable) {
           // console.log('coming vehical if')
        //if ((newPyalod?.length > 0 && newPyalod?.[0]?.license_plate != '' && newPyalod?.[0]?.license_plate?.length >= 3 && newPyalod?.[0]?.license_plate?.length <= 10 && newPyalod?.[0]?.make != '' && newPyalod?.[0]?.model != '' && newPyalod?.[0]?.color != '') && !submitButtonDisable) {
            setSubmitButtonDisable(true)
            dispatch(postVehicleList(finalPayload)).then(() => {
                vehicleArray?.map((item) => {
                    if (item) {
                        item['license_plate'] = ''
                        item['make'] = ''
                        item['model'] = ''
                        item['color'] = ''

                    }
                })
                setModal(false)
                setVehicleData(vehicleArray)
                dispatch(getVehicleDataList()).then((res) => {
                    setGetVehicleList(res?.data)
                    setVehicleCount(res?.data?.length)})
                dispatch(getUserProfile())
                setSubmitButtonDisable(false)
            })
        }
    }

    const handleVehicleDelete = (e, item) => {
        e.preventDefault()
        setDeleteItem(item)
        setConfermationModal(true)
    }

    // Delete Vehicle item
    const handleDelete = () => {
        if (deleteItem?.id) {
            dispatch(DeleteVehicleList(deleteItem?.id)).then(() => {
                dispatch(getUserProfile())
                return dispatch(getVehicleDataList()).then((res) => {
                    setGetVehicleList(res?.data)
                    setVehicleCount(res?.data?.length)
                })
            })
            setConfermationModal(false)
        }
    }

    const handleModalOpen = () => {
        setModal(true)
    }

    return (
        <div>
            <div className="d-flex justify-content-between mb-4">
                {/* <h4 className="fw-bold">Vehicle Details </h4> <button href='#' className="btn btn-success ms-auto" data-bs-toggle="modal" data-bs-target="#vehicleModal"><i className="fa fa-plus"></i> Add</button> */}
                <h4 className="fw-bold">Vehicle Details </h4>
                <button className="btn btn-primary ms-auto" onClick={handleModalOpen}><i className="fa fa-plus"></i> Add</button>
            </div>
            {loader ? <span className='ms-2 d-flex justify-content-center'><Circles
                height="50"
                width="50"
                color="var(--bs-primary)"
                ariaLabel="circles-loading"
                visible={true}
            /></span> : <div className="card mb-3">
                <div className="card-header">
                    <b>Vehicle List</b>
                </div>
                {/* <div className="card-body">
                    {getVehicleList?.length > 0 ?
                        <div className='row g-0 align-items-center'>
                            <div className="col-sm-4 mb-3">
                                <p className="mb-0 ms-3"><b>License Plate</b></p>
                            </div>
                            <div className="col-sm-2 mb-3 text-start">
                                <p className="mb-0"><b>Color</b></p>
                            </div>
                            <div className="col-sm-2 mb-3 text-start">
                                <p className="mb-0"><b>Make</b></p>
                            </div>
                            <div className="col-sm-2 mb-3 text-start">
                                <p className="mb-0"><b>Model</b></p>
                            </div>
                            <div className="col-sm-2 text-end mb-3">
                                <b>Action</b>
                            </div>

                            {getVehicleList?.length > 0 ? getVehicleList?.map((getListDataItem, getListDataIndex) => {
                                return <div key={getListDataIndex} className="row g-0 mb-1 align-items-center">
                                    <div className="col-sm-4">
                                        <p className="mb-0 ms-3">{getListDataItem?.license_plate_number}</p>
                                    </div>
                                    <div className="col-sm-2 text-start">
                                        <p className="mb-0">{getListDataItem?.color}</p>
                                    </div>
                                    <div className="col-sm-2 text-start">
                                        <p className="mb-0">{getListDataItem?.make}</p>
                                    </div>
                                    <div className="col-sm-2 text-start">
                                        <p className="mb-0">{getListDataItem?.model}</p>
                                    </div>
                                    <div className="col-sm-2 text-end">
                                        <button className="btn btn-outline-danger px-3 btn-sm" onClick={(e) => handleVehicleDelete(e, getListDataItem)}><i className="fa  fa-trash"></i></button>
                                    </div>
                                </div>
                            }) : renderNoResults()}
                        </div> : renderNoResults()}

                </div> */}

                <div className="card-body table-responsive">
                    {getVehicleList?.length > 0 ?
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col" className='tableLicensePlate'>License Plate</th>
                                    <th scope="col">Color</th>
                                    <th scope="col">Make</th>
                                    <th scope="col">Model</th>
                                    <th scope='col'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getVehicleList?.length > 0 ? getVehicleList?.map((getListDataItem, getListDataIndex) => {
                                    return <tr key={getListDataIndex}>
                                        <td>{getListDataItem?.license_plate_number}</td>
                                        <td>{getListDataItem?.color}</td>
                                        <td>{getListDataItem?.make}</td>
                                        <td>{getListDataItem?.model}</td>
                                        <td><button className="btn btn-outline-danger px-3 btn-sm" onClick={(e) => handleVehicleDelete(e, getListDataItem)}><i className="fa  fa-trash"></i></button></td>
                                    </tr>
                                }) : renderNoResults()}
                            </tbody>
                        </table> : renderNoResults()}
                </div>
            </div>}

            <VehicleModal isModalOpen={isModalOpen} setModal={setModal} modalName='Add Vehicle' submitButtonDisable={submitButtonDisable} vehicleData={vehicleData} setVehicleData={setVehicleData} handleSubmitVehicleData={handleSubmitVehicle} />
            <ConfermationModal setConfermationModal={setConfermationModal} confermationModal={confermationModal} confermationHeading='Confirm Delete' confermationMessage='Are you sure you want to delete?' buttonLable='Delete' handleDelete={handleDelete} />
        </div>
    )
}

VehicleDetails.propTypes = {
    dispatch: PropTypes.func,
    data: PropTypes.object,
    loader: PropTypes.bool,
    setUserData: PropTypes.func,
    setVehicleCount: PropTypes.func, 
    vehicleCount : PropTypes.number,
}

VehicleDetails.defaulProps = {
    dispatch: noop,
    data: EMPTY_OBJECT,
    loader: PropTypes.bool,
    setUserData: PropTypes.func,
    setVehicleCount: noop, 
    vehicleCount: 0,
}

function mapStateToProps({ vehicleListReducer }) {
    return {
        loader: vehicleListReducer?.loader,
    }
}
export default connect(mapStateToProps)(VehicleDetails)